/*
 * Organism: Sidebar
 */

.sidebar-wrapper {
  margin-top: 0;
  height: auto;
  position: sticky;
  z-index: 10;
  top: 48px;

  @media (--viewport-md-min) {
    margin-top: 0;
    z-index: 0;
    position: static;
  }

  /* Open sidebar */
  &--open {
    z-index: 300;

    .sidebar-trigger {
      > svg {
        transform: translate3d(0, -50%, 0) rotate(-90deg);
      }
    }

    .sidebar-content {
      opacity: 1;
      pointer-events: auto;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translate3d(0, 0, 0);
    }
  }
}

.sidebar {
  display: block;
  margin-right: var(--gutter-compensation);
  margin-left: var(--gutter-compensation);
  margin-bottom: 32px;
  box-shadow: var(--box-shadow);
  position: relative;

  @media (--viewport-md-min) {
    margin: 0;
    box-shadow: none;
  }

  &-row {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  &-container {
    @media screen and (--viewport-sm-max) {
      padding: 0 16px;
    }
  }

  &-trigger {
    position: sticky;
    display: block;
    background: var(--color-white);
    font-weight: 700;
    padding-bottom: 16px;

    > svg {
      position: absolute;
      top: 50%;
      right: 20px;
      transition: all 0.3s var(--easeInOutCubic);
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }

    @media (--viewport-md-min) {
      display: none;
    }
  }

  &-content {
    @media (--viewport-sm-max) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: var(--color-white);
      padding: 0 16px;
      transition: all 0.3s var(--transition);
      transform: translate3d(-100%, 0, 0);
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &::after {
        /* IE-compatible padding bottom of the scrollable area. */
        content: ' ';
        display: table;
        height: 120px;
      }
    }

    &-header {
      display: flex;
      background: white;
      padding: 16px;
      margin-left: -16px;
      margin-right: -16px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      position: sticky;
      top: 0;
      z-index: 1;
      font-weight: var(--heading-font-weight);

      @media (--viewport-md-min) {
        display: none;
      }

      &__close {
        background: none;
        border: 0;
        padding: 0;
        line-height: 1;
        cursor: pointer;
        margin-right: 8px;

        svg {
          width: 14px;
          height: 14px;
          min-width: 14px;
          min-height: 14px;
        }

        &:focus,
        &:active {
          outline: none;
        }

        @media (--viewport-md-min) {
          display: none;
        }
      }
    }
  }

  &__close-button {
    display: flex;
    justify-content: center;

    @media (--viewport-md-min) {
      display: none;
    }
  }
}
