/*
 * Organism: Hero
 */
.hero {
  position: relative;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;

  &-figure {
    position: relative;
    margin-top: 8px;
    margin-bottom: 16px;
    @media (--viewport-md-min) {
      margin-top: 0;
      margin-bottom: 16px;
    }

    &__image {
      max-width: 100%;
    }
    &__caption {
      @extend .image-caption;
    }
    &--caption-right {
      .hero-figure__caption {
        @extend .image-caption--right;
      }
    }
  }

  &-head {
    display: flex;
    text-align: left;
    flex-direction: column;

    &--subtitle-first {
      flex-direction: column-reverse;
    }

    &-title {
      font-size: 2rem; /* 32px */
      line-height: 1;

      @media (--viewport-md-min) {
        font-size: 3.375rem; /* 56px */
      }
    }

    &-subtitle {
      @extend h3;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: var(--color-eminence);
      font-size: 1rem; /* 16px */
      line-height: 1;
      @media (--viewport-md-min) {
        font-size: 1.875rem; /* 30px */
      }
    }

    &-countdown {
      .countdown-content {
        justify-content: space-between;
      }
      .countdown-content-timer {
        margin: 0 4px;
        &:first-of-type {
          margin-left: 0;
        }
        @media (--viewport-md-min) {
          height: 95px;
          width: 95px;
        }
      }
    }
  }

  .button {
    margin-right: 16px;
    margin-top: 16px;
  }

  &-content {
    text-align: left;
    margin-bottom: 40px;

    &__line {
      width: 105px;
      height: 4px;
      background: var(--color-scooter);
      border-radius: 3px;
      margin-top: 16px;
      margin-bottom: 32px;

      @media (--viewport-md-min) {
        margin-top: 24px;
        margin-bottom: 40px;
      }
    }

    &__text {
      position: relative;
    }

    &__footnote {
      margin-top: 32px;
      color: var(--color-deepblue);
      font-size: 0.875rem; /* 14px */

      @media (--viewport-md-min) {
        margin-top: 60px;
      }
    }
  }

  &-fullscreen-media {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      background-image: radial-gradient(54% 153% at 0 0, transparent 0, rgba(0, 0, 0, 0.53) 100%);
    }

    &__image {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &__video {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }

  &-video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 24px;
    background: rgba(0, 0, 0, 0.9);

    @media (--viewport-md-min) {
      padding: 100px;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
      height: 100%;

      iframe,
      #tencent-hero-overlay-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      margin: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      background: var(--color-white);
      color: var(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewport-md-min) {
        top: 40px;
        right: 40px;
      }

      .icon {
        margin: 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
      }
    }
  }

  &__play {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;

    @media (--viewport-md-min) {
      width: 112px;
      height: 112px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }

      svg {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 8px solid var(--color-white);
      background-image: radial-gradient(
        54% 145% at 0 0,
        rgba(0, 0, 0, 0) 54%,
        rgba(0, 0, 0, 0.33) 100%
      );
      border-radius: 100%;
      opacity: 0.23;
      transition: opacity 0.2s var(--transition);
    }

    svg {
      fill: var(--color-white);
      width: 24px;
      height: 24px;
      opacity: 0.75;
      transition: opacity 0.2s var(--transition);
      z-index: 4;

      @media (--viewport-md-min) {
        width: 40px;
        height: 40px;
      }
    }
    &-label {
      display: none;
    }
  }

  /* Move FAQ on top of hero */
  + .grid .container:first-child .row:first-child {
    .faq {
      margin-top: -60px;
    }
  }

  /* Variations */
  &--simple {
    margin-top: var(--header-height);

    @media (--viewport-md-min) {
      margin-top: var(--header-height-desktop);
    }

    & .hero-head-title {
      color: var(--color-hero-simple);
      text-align: center;
    }
  }

  &--fullscreen {
    overflow: hidden;

    @media (--viewport-md-min) {
      max-height: 750px;
      height: 90vh;
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      height: 100%;
    }

    .hero {
      &__inner {
        display: flex;
        flex-direction: column;
        padding-top: var(--header-height);
        width: 100%;

        @media (--viewport-md-min) {
          padding-top: var(--header-height-desktop);
          justify-content: center;
        }
      }

      &-head {
        justify-content: center;
        max-width: 300px;

        @media (--viewport-md-min) {
          max-width: 520px;
        }
      }

      &-content {
        max-width: 300px;

        @media (--viewport-md-min) {
          max-width: 600px;
        }

        &__caption {
          color: var(--color-white);
          position: absolute;
          bottom: 15px;
          font-style: italic;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          text-align: center;
          display: block;
          width: 100%;
          font-size: 0.75rem; /* 12px */

          @media (--viewport-md-min) {
            width: calc(100% - var(--container-spacing) * 2);
            left: var(--container-spacing);
            transform: translate3d(0, 0, 0);
            text-align: left;
          }

          &--right {
            @media (--viewport-md-min) {
              text-align: right;
            }
          }
        }
      }
    }
  }

  &--video {
    height: 80vh;
    max-height: 750px;

    @media (--viewport-md-min) {
      height: 90vh;
    }

    .hero {
      &-head {
        max-width: 220px;

        @media (--viewport-md-min) {
          max-width: 360px;
        }
      }

      &-content {
        width: 100%;
        max-width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: var(--container-spacing--mobile);
        padding-right: var(--container-spacing--mobile);

        @media (--viewport-md-min) {
          padding-left: var(--container-spacing);
          padding-right: var(--container-spacing);
        }

        &__buttons {
          .button {
            margin-left: 0;
            margin-right: 0;
            width: 80%;

            @media (--viewport-md-min) {
              margin-left: 20px;
              margin-right: 20px;
              width: auto;
            }
          }
        }

        &__caption {
          bottom: -30px;

          @media (--viewport-md-min) {
            bottom: 0;
          }
        }
      }
    }
  }
}
