/*
 * Organism: Sidebar trigger
 */

.sidebar-trigger {
  display: block;
  font-weight: var(--heading-weight);

  @media (--viewport-md-min) {
    display: none;
  }

  &__applied-filters {
    display: none;
    font-weight: var(--base-font-weight);
  }

  & button {
    width: 100%;
  }
}
