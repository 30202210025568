.story-stats-with-text-slide {
  display: flex;
  align-items: center;
  position: relative;

  &-content {
    color: var(--color-story-stats-with-text-slide-color);
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;

    &-item {
      margin-bottom: 32px;
      padding-top: 32px;
      border-top: 2px solid
        var(--color-story-stats-with-text-slide-border-color);

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }

      @media (--viewport-md-min) {
        padding-top: 72px;
        margin-bottom: 72px;
      }

      &__text {
        font-size: 32px;
        line-height: 1.2;

        @media (--viewport-md-min) {
          font-size: 72px;
        }

        span {
          font-size: 72px;
          font-weight: bold;
          line-height: 1;

          @media (--viewport-md-min) {
            font-size: 200px;
          }
        }
      }
    }
  }
}
