.signup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s;
  background-color: var(--color-white);

  &--expanded {
    transform: translateX(0);
  }

  &-inner {
    height: 100vh;
    overflow-y: auto;

    @media (--viewport-md-min) {
      display: flex;
    }

    & .icon {
      position: absolute;
      right: 24px;
      top: 24px;
      fill: var(--color-black);
      height: 24px;
      width: 24px;

      @media (--viewport-md-min) {
        fill: var(--color-white);
        height: 32px;
        width: 32px;
        right: 56px;
        top: 56px;
      }
    }

    &-image {
      display: none;

      @media (--viewport-md-min) {
        object-fit: cover;
        order: 1;
        height: auto;
        min-height: 100vh;
        width: 592px;
        margin-bottom: 0;
        display: block;
      }
    }

    &-content {
      @media (--viewport-md-min) {
        margin-left: 64px;
        margin-right: 88px;
        margin-top: 72px;
      }

      &-title {
        font-family: var(--heading-font-family);
        font-weight: var(--heading-font-weight);
        line-height: 2.5rem; /* 40px */
        font-size: 2rem; /* 32px */
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 24px;
        margin-top: 56px;

        @media (--viewport-md-min) {
          font-size: 3rem; /* 48px */
          line-height: 3.5rem; /* 56px */
          margin-bottom: 48px;
        }
      }

      &-form {
        & .form {
          flex-flow: column;
          display: flex;
          align-items: center;

          @media (--viewport-md-min) {
            flex-flow: row wrap;

            & .form-group {
              margin-right: 32px;

              @media (--viewport-md-min) {
                width: calc(50% - 32px);
                margin-bottom: 48px;
              }
            }
          }

          & .input__wrapper {
            & .form-group__message {
              padding-left: 0;
            }
          }
        }

        &__accept-terms {
          max-width: 560px;
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 16px;
          min-width: auto;

          @media (--viewport-md-min) {
            display: flex;
            justify-content: center;
            min-width: 100%;
            margin-bottom: 48px;
            margin-left: 0;
            margin-right: 0;
          }

          & a {
            text-decoration: none;
          }

          & .checkbox {
            margin-bottom: 0;
            justify-content: center;
          }
        }

        & .cta {
          width: 100%;

          & .button {
            @media (--viewport-md-min) {
              min-width: 416px;
            }
          }
        }
      }
    }
  }
}
