.b2b-contact-form {
  &__title {
    margin-top: 48px;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
  }

  &__subtitle {
    max-width: 720px;
    font-weight: 400;
    font-size: 1.125rem; /* 18px */
    line-height: 1.25rem; /* 20px */
    margin-bottom: 20px;

    @media (--viewport-md-min) {
      font-size: 1.25rem; /* 20px */
      line-height: 1.75rem; /* 28px */
      margin-bottom: 48px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    .checkbox {
      .checkbox__tick:before {
        border-color: inherit;
      }
    }

    .form {
      width: 100%;

      @media (--viewport-sm-min) {
        width: 560px;
      }

      button {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      .form-row {
        display: flex;

        .form-group {
          width: calc(50% - 16px);
          + .form-group {
            margin-left: 32px;
          }
        }
      }

      .select__wrapper {
        width: 100%;

        .icon.select__icon {
          top: 60px;
          transform: unset;
          fill: var(--color-text);
        }

        label {
          display: inline-block;
          margin-bottom: 12px;
        }

        select {
          color: var(--color-text);
          font-size: var(--input-font-size);
          background-color: var(--input-bg-color);
          border-width: var(--input-border-width);
          border-style: solid;
          border-radius: var(--input-border-radius);
          width: 100%;
          padding: 20px 16px;
          height: 100%;
          display: inline-block;
        }
      }


    }
  }
}

.contact-form-interests {
  .section-label {
    margin-bottom: 12px;
    font-weight: bold;
  }
  .form-group__message {
    display: none;
    &.b2b-signup-form-overlay-inner-content-form__interest-error.show {
      display: block;
    }
  }
  .form-group &__selection {
    color: var(--color-text);
    font-size: var(--input-font-size);
    position: relative;

    .icon.select__icon {
      fill: var(--color-text);
      transition: transform 0.3s;
    }

    &-field {
      background-color: var(--input-bg-color);
      border-width: var(--input-border-width);
      border-style: solid;
      border-radius: var(--input-border-radius);
      width: 100%;
      line-height: 1.5;
      padding: 16px 35px 16px 20px;
      cursor: pointer;

      &-value {
        display: inline-block;
        font-weight: 400;
        &[data-placeholder] {
          color: var(--color-input-placeholder);
        }
      }
    }
    &-options {
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 0;
      right: 0;
      background-color: var(--input-bg-color);
      backdrop-filter: blur(10px);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.10);
      overflow: auto;
      border-radius: var(--input-border-radius);
      padding: 0;
      border: none;
      max-height: 0;
      visibility: hidden;
      opacity: 0;
      transition: max-height 0.3s, visibility 0.3s 0.3s, opacity 0.3s;
      font-size: 14px;
      
      .checkbox {
        margin-bottom: 18px;
      }

      &-list {
        padding: 24px;
        display: grid;
      }

      &::-webkit-scrollbar {
        width: 24px;
      }
       
      &::-webkit-scrollbar-track {
        display: none;
      }
       
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        outline: none;
        border-radius: 20px;
        border: 9px solid transparent;
        box-shadow: inset var(--color-text) 10px 10px 0;
      }
    }
    &[data-active] {
      .contact-form-interests__selection-options {
        visibility: visible;
        max-height: 290px;
        opacity: 1;
        transition: max-height 0.3s, visibility 0.3s, opacity 0.3s;
      }
      .icon.select__icon {
        transform: translateY(-50%) rotateX(-180deg);
      }
    }
  }
}
