/* Settings */
@import '../_base/_normalize.css';
@import '../_base/_enerjet.variables.css';
@import '../_base/_enerjet.fonts.css';
@import '../_base/_grid.css';
@import '../_base/_base.css';
@import '../_base/_enerjet.typography.css';

/* Atoms */
@import-glob '../01-atoms/**/*.css';

/* Molecules */
@import-glob '../02-molecules/**/*.css';

/* Organisms */
@import-glob '../03-organisms/**/*.css';

/* Pages */
@import-glob '../04-pages/**/*.css';

/* Themes */
@import '../_base/_enerjet.themes.css';

/* Overwrites of Instalift for Perfetcha theme */
@import './themes/enerjet/enerjet.master-overwrites.css';

/* Temporary overwrites - need to slowly extract overwrites from enerjet.master-overwrites.css to this file */

/* Social media feed overwrites */
.social-media-feed {
  &-content {
    &__title {
      font-style: italic;
      padding-left: 32px;
      max-width: 200px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-blue);
        height: 100%;
        width: 8px;
      }

      @media (--viewport-md-min) {
        max-width: 100%;
      }
    }
  }
}

/* Interactive patient preview overwrites */
.interactive-patient-preview {
  &-content {
    h1.interactive-patient-preview-content__title,
    h2.interactive-patient-preview-content__title {
      max-width: 200px;
    }

    h2.interactive-patient-preview-content__title {
      padding-left: 32px;
      font-style: italic;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-primary);
        height: 100%;
        width: 8px;
      }

      @media (--viewport-md-min) {
        max-width: 100%;
      }
    }
  }
}

/* Clinic finder banner overwrites */
.clinic-finder-banner {
  &-media {
    height: 80vh;
    max-height: 750px;

    @media (--viewport-md-min) {
      height: 90vh;
    }
  }

  &-content {
    display: block;

    &__title {
      margin-top: 48px;
      font-style: italic;
      position: relative;
      padding-left: 32px;
      max-width: 200px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-text);
        height: 100%;
        width: 8px;
      }

      @media (--viewport-md-min) {
        max-width: 100%;
      }
    }

    &-form {
      background: var(--color-primary);
      color: var(--color-white);
      position: absolute;
      top: 50%;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
      z-index: 2;
      transform: translate3d(0, -50%, 0);
      left: 0;
      width: calc(100% - var(--container-spacing--mobile));
      padding: 64px 32px;

      @media (--viewport-sm-min) {
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        width: auto;
        padding: 64px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: 6px solid var(--color-primary);
        top: 24px;
        left: 24px;

        @media (--viewport-md-min) {
          top: 32px;
          left: 32px;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-primary);
        z-index: -1;
      }

      &__input {
        margin-right: 24px;
        padding: 6px 0;
        font-weight: 500;
        border-bottom: 3px solid currentColor;

        @media (--viewport-md-min) {
          width: 400px;
        }

        &::placeholder {
          color: color(var(--color-white) a(60%));
        }
      }

      &__submit {
        color: currentColor;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        right: 10px;

        @media (--viewport-md-min) {
          right: 40px;
        }
      }
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 48px;
      left: 0;
      padding-left: var(--container-spacing--mobile);
      padding-right: var(--container-spacing--mobile);

      @media (--viewport-md-min) {
        padding-left: var(--container-spacing);
        padding-right: var(--container-spacing);

        .button {
          width: auto;
        }
      }
    }
  }
}

/* Hero overwrites */
.hero {
  &--simple {
    padding-top: 48px;
    padding-bottom: 16px;

    @media (--viewport-md-min) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  &-content {
    &__buttons {
      .button {
        background: var(--color-white);
        color: var(--color-text);
        border: 4px solid var(--color-white);

        &:hover {
          background: var(--color-primary);
          color: var(--color-white);
          border-color: var(--color-white);
        }

        &--secondary {
          background: color(var(--color-black) a(25%));
          color: var(--color-white);
          border: 4px solid var(--color-white);
        }
      }
    }
  }

  .countdown-content-timer {
    @media (--viewport-md-min) {
      width: 65px;
      height: 65px;
    }
  }

  .countdown-content-timer__value {
    @media (--viewport-md-min) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}

/* How it works overwrites */
.how-it-works {
  &-content {
    &__title {
      font-style: italic;
      padding-left: 32px;
      max-width: 200px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: currentColor;
        height: 100%;
        width: 8px;
      }

      @media (--viewport-md-min) {
        max-width: 100%;
      }
    }
  }
}

/* Video slider overwrites */
.video-slider {
  &__title {
    font-style: italic;
    padding-left: 32px;
    max-width: 200px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: currentColor;
      height: 100%;
      width: 8px;
    }

    @media (--viewport-md-min) {
      max-width: 100%;
    }
  }

  &-item {
    &-media {
      @media (--viewport-md-min) {
        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          border: 6px solid var(--color-primary);
          top: 32px;
          left: 32px;
        }
      }
    }
  }
}

/* Content card listing overwrites */
.content-cards {
  &__title {
    font-style: italic;
    padding-left: 32px;
    max-width: 200px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: currentColor;
      height: 100%;
      width: 8px;
    }

    @media (--viewport-md-min) {
      max-width: 100%;
    }
  }
}

/* Profile card listing overwrites */
.profile-cards {
  &__header {
    & h2 {
      font-style: italic;
      padding-left: 32px;
      position: relative;
      color: var(--color-profile-card-listing-title);

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-profile-card-listing-title);
        height: 100%;
        width: 8px;
      }
    }
  }
}

.profile-card {
  &__outline-top {
    height: 6px;
    width: calc(100% - 54px);
    background-color: var(--color-white);
    position: absolute;
    top: 27px;
    left: 27px;
    right: 27px;
  }

  &__outline-right {
    height: calc(100% - 170px);
    width: 6px;
    background-color: var(--color-white);
    position: absolute;
    top: 27px;
    right: 27px;
  }

  &__outline-bottom {
    height: 6px;
    width: calc(100% - 500px);
    background-color: var(--color-white);
    position: absolute;
    left: 27px;
    bottom: 40px;

    @media (--viewport-sm-max) {
      width: calc(100% - 270px);
    }
  }

  &__outline-left {
    height: calc(100% - 68px);
    width: 6px;
    background-color: var(--color-white);
    position: absolute;
    top: 27px;
    left: 27px;
  }
}

/* Appetiser overwrites */
.appetiser {
  &-content {
    h2 {
      font-style: italic;
      padding-left: 32px;
      max-width: 200px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: currentColor;
        height: 100%;
        width: 8px;
      }

      @media (--viewport-md-min) {
        max-width: 100%;
      }
    }
  }
}

/* Ask a question overwrites */
.ask-a-question {
  &__title {
    font-style: italic;
    padding-left: 32px;
    max-width: 200px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: currentColor;
      height: 100%;
      width: 8px;
    }

    @media (--viewport-md-min) {
      max-width: 100%;
    }
  }
}

/* center the text in the campaign button */
.lf .clinic-finder-item {
  &__content-footer-links-open-campaign {
    padding-top: 0;
  }
}
