.explore-our-specialists {
    margin: 2rem 0;
}
.explore-our-specialists-title {
    margin-bottom: 2rem;
}
.specialist {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: currentColor;
    margin-bottom: 1rem;
    text-decoration: none;
}
.specialist:hover .specialist-name {
    text-decoration: underline;
}
.specialist-info {
    display: flex;
    flex-direction: column;
    padding: .8rem 0;
}
.specialist-portrait {
    width: 100%;
    max-height: 200px;
    background-color: var(--color-secondary);
}
.specialist-portrait img {
    object-fit: cover;
}
.specialist-field {
    text-transform: uppercase;
}
.specialist-experience {
    font-size: 1rem;
}