.countdown {
    display: flex;
    flex: 1;
    width: 100%;
    margin-bottom: 40px;

    @media (--viewport-md-min) {
        margin-bottom: 56px;
    }

    &-content {
        position: relative;
        display: flex;
        flex: 1;
        justify-content: space-between;
        width: 100%;

        @media (--viewport-sm-min) {
            justify-content: center;
        }

        &-timer {
            height: 60px;
            width: 60px;
            background-color: var(--color-primary);
            margin-left: 4px;
            margin-right: 4px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            color: var(--color-countdown-text);

            @media (--viewport-md-min) {
                height: 145px;
                width: 145px;
                margin-left: 16px;
                margin-right: 16px;
            }

            &__value {
                font-weight: 700;
                font-size: 1.5rem; /* 24px */
                line-height: 1.5rem;

                @media (--viewport-md-min) {
                    font-weight: 700;
                    font-size: 3rem; /* 48px */
                    line-height: 3rem;
                }
            }

            &__unit {
                font-size: 0.625rem; /* 10px */
                line-height: 0.875rem; /* 14px */

                @media (--viewport-md-min) {
                    font-size: 0.875rem; /* 14px */
                    line-height: 0.875rem;
                }
            }
        }
    }
}