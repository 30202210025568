.appointment-popup {
  font-size: 10px;
  position: fixed;
  display: flex;
  bottom: 50px;
  z-index: 4;
  width: 320px;
  left: 10px;
  background-color: #fff;
  border-radius: 16px;
  padding: 5px;
  transition: opacity 0.4s;
  opacity: 0;
  pointer-events: none;

  @media (--viewport-md-min) {
    bottom: 90px;
    padding: 10px;
    left: 10px;
  }

  &.show {
    opacity: 1;
    z-index: 250;
  }
}

.appointment-popup-icon {
  margin-right: 24px;

  .icon {
    display: block;
    fill: var(--color-secondary);
    stroke: var(--color-secondary);
    color: var(--color-secondary);
    width: 60px;
    height: 60px;
    stroke-width: 3px;
  }
}

.appointment-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-transform: uppercase;
}
