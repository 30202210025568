/*
 * Organism: Clinic finder - sticky CTA
 */

@-webkit-keyframes clinic-finder-sticky-cta-bg-slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@-moz-keyframes clinic-finder-sticky-cta-bg-slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes clinic-finder-sticky-cta-bg-slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.clinic-finder-sticky-cta {
  position: fixed;
  right: 0;
  z-index: 100;
  top: 100px;

  /* Move the sticky CTA up to accomodate ISI text, if the screen is high enough */
  @media (min-height: 500px) and (--viewport-md-min) {
    bottom: 340px;
    top: auto;
  }

  @media (min-height: 500px) and (--viewport-lg-min) {
    bottom: 320px;
    top: auto;
  }

  @media (min-height: 500px) and (--viewport-xl-min) {
    bottom: 300px;
    top: auto;
  }

  &--active {
    .clinic-finder-sticky-cta-trigger {
      /* hide 10px off screen edge - to ensure outline is also off-screen */
      transform: translate3d(calc(100% + 10px), 0, 0);
    }

    .clinic-finder-sticky-cta-popout {
      transform: translate3d(0, 0, 0);
    }
  }

  &-trigger {
    background: var(--color-clinic-finder-sticky-cta-trigger-background);
    color: var(--color-clinic-finder-sticky-cta-trigger-text);
    border: 0;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    cursor: pointer;
    transition: all 0.2s var(--transition);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.75rem; /* 12px */
    font-weight: bold;
    padding: 8px;
    background-size: 1000px 100px;
    animation: clinic-finder-sticky-cta-bg-slide 15s infinite linear forwards;
    box-shadow: none;
    width: 140px;

    @media (--viewport-md-min) {
      width: 165px;
      padding-left: 8px;
    }

    &__icon {
      margin-right: 8px;
      color: var(--color-clinic-finder-sticky-cta-trigger-icon);

      @media (--viewport-md-min) {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 3px;
          left: 3px;
          right: 3px;
          bottom: 3px;
          border: 1px solid currentColor;
          border-radius: 100%;
        }
      }

      .icon {
        display: block;
        flex-shrink: 0;
        fill: currentColor;
        background: white;
        border-radius: 100%;
        width: 42px;
        height: 42px;
        padding: 8px;

        @media (--viewport-md-min) {
          width: 72px;
          height: 72px;
          padding: 12px;
          position: relative;
        }
      }
    }

    &:hover {
      .clinic-finder-sticky-cta-trigger__icon {
        color: var(--color-clinic-finder-sticky-cta-trigger-icon-hover);
      }
    }
  }

  &-popout {
    position: absolute;
    background: var(--color-clinic-finder-sticky-cta-popout-background);
    color: var(--color-clinic-finder-sticky-cta-popout-text);
    top: 0;
    right: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.2s var(--transition);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    display: flex;
    align-items: center;
    width: 376px;
    max-width: 95vw;
    padding: 5px;
    box-shadow: none;

    @media (--viewport-sm-min) {
      padding: 16px;
      width: 675px;
    }

    &__close {
      margin: 0 8px;
      color: currentColor;

      .icon {
        cursor: pointer;
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
        margin: -8px;
        width: 48px;
        height: 48px;

        @media (--viewport-sm-min) {
          margin: -12px;
          width: 64px;
          height: 64px;
        }
      }
    }

    &-form {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      padding: 3px 3px 3px 10px;
      background: white;
      border-radius: 100px;
      margin: 0;

      @media (--viewport-sm-min) {
        padding: 5px 5px 5px 18px;
      }

      &__input {
        background: none;
        color: var(--color-clinic-finder-sticky-cta-input);
        border: none;
        border-radius: 0;
        outline: none;
        min-height: 40px;
        width: 100%;
        margin-right: 4px;
        margin-left: 2px;
        padding: 6px;
        border-bottom: 0;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--color-clinic-finder-sticky-cta-input-placeholder);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--color-clinic-finder-sticky-cta-input-placeholder);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--color-clinic-finder-sticky-cta-input-placeholder);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--color-clinic-finder-sticky-cta-input-placeholder);
        }

        @media (--viewport-sm-min) {
          min-height: 48px;
          margin-left: 6px;
        }
      }

      .icon {
        fill: none;
        stroke: var(--color-clinic-finder-sticky-cta-search-icon);
      }

      &-submit {
        border: 0;
        padding: 0;
        margin: 0;
        position: absolute;
        color: currentColor;
        flex-shrink: 0;
        @extend .button;
        right: 0;
        min-height: 38px;

        &.button {
          border-radius: 24px;
          background: var(--color-clinic-finder-sticky-cta-gradient-button);
          min-width: auto;

          &:hover {
            background: var(--color-clinic-finder-sticky-cta-gradient-button);
          }

          &--secondary {
            &:before {
              content: none;
            }
            &:after {
              content: none;
            }
          }
        }

        @media (--viewport-sm-min) {
          min-height: 48px;
        }

        &__desktop-label {
          display: none;
          @media (--viewport-sm-min) {
            display: inline;
          }
        }

        &__mobile-label {
          @media (--viewport-sm-min) {
            display: none;
          }
        }

        .icon {
          transform: rotate(0deg);
          width: 24px;
          height: 24px;
          fill: currentColor;
        }
      }
    }
  }
}

.whatsapp-clinic-sticky-cta {
  position: fixed;
  bottom: 12px;
  right: 15px;
  top: auto;
  z-index: 100;

  svg {
    width: 3rem;
    height: 3rem;
  }

  @media (--viewport-md-min) {
    bottom: 15px;
    right: 20px;
  }
}
