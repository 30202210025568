.meet-the-team-slider {
  margin-top: 88px;

  @media (--viewport-md-min) {
    margin-top: 104px;
  }

  &-content {
    margin-top: 48px;
    margin-bottom: 48px;
    width: calc(100% - 24px);
    min-height: 450px;

    @media (--viewport-md-min) {
      margin-top: 80px;
      margin-bottom: 40px;
      width: calc(100% - 40px);
      min-height: 550px;
    }

    & .flickity-viewport {
      overflow: visible;
    }

    &-item {
      width: calc(268px + 24px);
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      align-items: stretch;
      min-height: 100%;
      width: 268px;
      margin-right: 24px;

      @media (--viewport-md-min) {
        width: 416px;
        margin-right: 40px;
      }
    }
  }
}
