/*
 * Molecule: Clinic finder search field
 */

.clinic-finder-search-field {
  position: relative;
  width: 100%;

  @media (--viewport-md-min) {
    display: flex;
    width: 580px;
  }

  &__icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 14px;
    left: 20px;
    opacity: 0.5;
    stroke: var(--color-eminence);

    @media (--viewport-md-min) {
      width: 24px;
      height: 24px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__input {
    padding-left: 48px;
    margin-bottom: 16px;

    @media (--viewport-md-min) {
      padding-left: 56px;
      padding-right: 225px;
      margin-bottom: 0;
    }
  }

  &__submit {
    @media (--viewport-md-min) {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translate3d(0, -50%, 0);
    }
  }
}
