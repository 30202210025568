/*
 * Molecule: Grid element - Before After Slider
 */

/*! Flickity v2.2.1 https://flickity.metafizzy.co */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.5s var(--transition);
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.before-after-slider {
  opacity: 0;
  max-width: 120vh;
  margin: 40px calc(0 - var(--container-spacing--mobile)) 64px;
  width: calc(100% + (2 * var(--container-spacing--mobile)));
  transition: opacity 0.3s var(--transition);

  @media (--viewport-sm-min) {
    margin: 0 auto 16px;
    width: 100%;
  }

  &-item {
    width: 100%;
    margin-top: 40px;

    &__figure {
      position: relative;
      width: 100%;
      user-select: none;
      margin-top: 1rem;
      position: relative;
    }

    &__labels {
      position: relative;
    }

    &__label-before,
    &__label-after {
      position: absolute;
      z-index: 2;
      top: -1rem; /* line-height equivalent */
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;

      @media (--viewport-sm-min) {
        top: 0;
      }

      &:after {
        display: block;
        position: absolute;
        color: var(--color-before-after-label);
        font-weight: 700;
        top: calc(100% - 24px);
        font-size: 0.875rem; /* 14px */
        text-shadow: var(--color-before-after-label-shadow);

        @media (--viewport-ms-min) {
          font-size: 1rem; /* 16px */
        }

        @media (--viewport-sm-min) {
          transform: translate3d(0, -50%, 0);
        }
      }
    }

    &__label-before {
      &:after {
        content: attr(data-label-before);
        left: 24px;
      }
    }

    &__label-after {
      &:after {
        content: attr(data-label-after);
        right: 24px;
      }
    }

    &-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      z-index: 1;
    }

    &__before,
    &__after {
      display: block;
      pointer-events: none;
      user-select: none;
      margin: 0;
    }

    &__after {
      width: 100%;
    }

    &__before {
      max-width: none;
      height: 100%;
    }

    &-handle {
      cursor: ew-resize;
      z-index: 2;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      position: absolute;
      width: 56px;
      left: 164px;
      height: 100%;
      top: 0;

      &-knob {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 56px;
        height: 56px;

        &__left,
        &__right {
          position: absolute;
          left: 50%;
          top: 50%;
          display: block;
          height: 12px;
          width: 12px;
          line-height: 0;

          svg {
            vertical-align: middle;
            min-height: 12px;
            min-width: 12px;
            height: 12px;
            width: 12px;
            fill: var(--color-before-after-slider-handle);
          }
        }

        &__left {
          transform: translate3d(calc(-50% - 16px), -50%, 0);
        }
        &__right {
          transform: translate3d(calc(-50% + 16px), -50%, 0);
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        width: 6px;
        height: 100%;
        background: var(--color-before-after-slider-handle);
      }
    }

    &__caption {
      color: var(--color-before-after-caption);
      margin: 16px;
      font-size: 0.875rem; /* 14px */
      text-align: center;
    }
  }

  &-selector {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    &-item {
      display: block;
      position: relative;
      width: 56px;
      height: 56px;
      margin-right: 10px;
      border-radius: 100%;
      border: 3px solid var(--color-before-after-slider-selector-border);

      &:before {
        content: '';
        position: absolute;
        top: -7px;
        right: -7px;
        bottom: -7px;
        left: -7px;
        border-radius: 100px;
        border: 2px solid transparent;
      }
      &:focus {
        outline: none;
        &:not(:hover) {
          &:before {
            display: block;
            border-color: var(--color-before-after-slider-selector-border-focus);
          }
        }
      }

      @media (--viewport-md-min) {
        width: 80px;
        height: 80px;
        margin-right: 16px;
      }

      &--selected {
        border-color: var(--color-before-after-slider-selector-selected-border);
        position: relative;

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 100px;
          content: '';
          background: color(var(--color-before-after-slider-selector-selected-border) a(0.3));
        }
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
      }
    }
  }
}
