/*
 * Molecule: Grid element - List with Icons
 */

.list-with-icons {

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__image {
      margin-right: 16px;
      max-width: 90px;
      @media (--viewport-sm-min) {
        max-width: 100px;
        margin-right: 24px;
      }
      @media (--viewport-md-min) {
        max-width: 110px;
        margin-right: 40px;
      }
    }

    &__text {
      color: var(--color-deepblue);
      font-size: 1.125rem; /* 18px */
    }
  }
}
