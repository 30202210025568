/*
 * Molecule: Site selector
 */

.site-selector {
  position: absolute;
  top: 5px;
  left: var(--container-spacing--mobile);
  z-index: 1;

  @media (--viewport-md-min) {
    display: block;
    left: var(--container-spacing);
  }

  &--active {
    .site-selector-toggle__label,
    .site-selector-toggle__expand-arrow {
      color: var(--color-pink);
    }

    .site-selector-list {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &-toggle {
    display: flex;

    &__label {
      display: block;
      position: relative;
      font-size: 0.75rem; /* 12px */
      font-weight: 700;
      color: var(--color-white);
      cursor: pointer;
      transition: color 0.2s var(--transition);
      width: auto;
      user-select: none;
    }

    &__expand-arrow {
      content: '';
      border: solid transparent;
      width: 0;
      height: 0;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: currentColor;
      border-width: 4px;
      transform: translate3d(4px, 6px, 0);
      color: var(--color-white);
    }

    &:hover {
      .site-selector-toggle__label,
      .site-selector-toggle__expand-arrow {
        color: var(--color-pink);
      }
    }
  }

  &-list {
    margin: 0;
    padding: 0;
    list-style: none;
    background: color(var(--color-white) a(90%));
    box-shadow: 0 0 10px 10px color(var(--color-white) a(15%));
    z-index: 2;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translate3d(0, 12px, 0);
    transition: opacity 0.2s var(--transition);
    opacity: 0;
    pointer-events: none;

    &-item {
      cursor: pointer;
      position: relative;
      color: var(--color-text);

      &:last-child {
        .site-selector-list-item__link {
          border-bottom: 0;
        }
      }

      &:before {
        display: none;
      }

      &--active,
      &:hover,
      &:focus {
        color: var(--color-pink);
      }

      &__link {
        display: block;
        white-space: nowrap;
        text-decoration: none;
        position: relative;
        color: currentcolor;
        transition: color 0.2s var(--transition);
        padding: 16px 0;
        margin: 0 20px;
        border-bottom: 2px solid color(var(--color-pink) a(25%));
        font-size: 0.8125rem; /* 13px */
        font-weight: 500;

        &:hover {
          color: currentColor;
        }
      }
    }
  }
}

.arrow_box {
  position: relative;
  background: #fff;
  border: 4px solid #c2e1f5;
}
.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #c2e1f5;
  border-width: 10px;
  margin-left: -10px;
}
