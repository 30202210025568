.theme-branded {
  .three-images-with-hover-effect {
    @media (--viewport-md-min) {
      bottom: -100px;
      /* The theme-branded adds the necessary spacing */
      margin-bottom: 0;
    }
  }
}

.three-images-with-hover-effect {
  display: grid;
  position: relative;
  margin-bottom: 40px;

  &-svg {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  & {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media (--viewport-md-min) {
    & {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-item {
    position: relative;
    color: var(--color-white);

    @media (--viewport-sm-max) {
      height: 100vh;
      max-height: 60vh;
      width: calc(100% + 40px);
      margin-left: -20px;
    }

    @media (--viewport-md-min) {
      &:nth-child(2) {
        z-index: 2;
        width: calc(100% + 200px);
        margin-left: -100px;

        .three-images-with-hover-effect-item__image {
          clip-path: url('#three-images-with-hover-effect-paper-clip');
        }
      }

      &:hover {
        color: currentColor;

        .three-images-with-hover-effect-item__image {
          img.unclipped {
            opacity: 1;
          }

          &:after {
            opacity: 0;
          }
        }
      }
    }

    &--active {
      .three-images-with-hover-effect-item__image {
        img.unclipped {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }
      }
    }

    &__image {
      height: 100%;
      background-color: #00405c;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s var(--transition);

        &.clipped {
          mix-blend-mode: multiply;
          filter: grayscale(100%);
        }

        &.unclipped {
          opacity: 0;
          top: 0;
          left: 0;
          position: absolute;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #001428;
        mix-blend-mode: screen;
        z-index: 1;
        pointer-events: none;
        transition: all 0.3s var(--transition);
      }
    }

    &-content {
      position: absolute;
      width: 80%;
      left: 10%;
      z-index: 4;
      bottom: 50%;
      transform: translateY(50%);

      &__title {
        text-align: center;
        text-transform: uppercase;
        line-height: 1;

        @media (--viewport-md-min) {
          font-size: 40px;
        }

        @media (--viewport-lg-min) {
          font-size: 60px;
        }
      }
    }
  }
}
