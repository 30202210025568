.simpleAppetiserOverlayHidden {
  display: none;
}
/** **/
.simple-appetiser {
  position: relative;

  &-video-overlay {
    transition: 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 24px;
    background: rgba(0, 0, 0, 0.9);

    @media (--viewport-md-min) {
      padding: 100px;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
      height: 100%;

      iframe,
      #tencent-hero-overlay-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      margin: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      background: var(--color-white);
      color: var(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewport-md-min) {
        top: 40px;
        right: 40px;
      }

      .icon {
        margin: 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
      }
    }
  }

  &-media {
    position: relative;
    top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    &__image {
      width: 100%;
    }

    &__play {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate3d(-50%, -50%, 0);
      border: 4px solid var(--color-white);
      border-radius: 50%;
      opacity: 0.8;
      cursor: pointer;
      background: none;

      svg {
        fill: var(--color-white);
        position: relative;
        left: 2px;
      }

      @media (--viewport-md-min) {
        width: 112px;
        height: 112px;

        svg {
          width: 40px;
          height: 40px;
        }
      }

      &:hover {
        opacity: 1;
      }
    }

    &__video-container {
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__video {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    width: 100%;
    position: relative;

    &-body {
      padding-top: 80px;
      padding-bottom: 60px;

      &__icon {
        margin-bottom: 40px;
        svg {
          width: 83px;
          height: 83px;
        }
      }

      &__text {
        font-family: var(--heading-font-family);
        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 40px;
      }
    }

    &-background {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      min-width: 100%;
      z-index: -1;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
  }

  @media (--viewport-lg-min) {
    width: 100%;
    max-width: var(--container-width);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    &-media {
      top: unset;
      z-index: 2;
      height: auto;
      padding: 0;
      position: relative;
      right: -100px;
      margin-left: -100px;
      min-height: 351px;

      &__image {
        object-fit: cover;
        min-height: 500px;
      }
    }

    &-content {
      min-width: 680px;
      min-height: 660px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-body {
        padding-left: 200px;

        &__header {
          font-size: 2.75rem; /** 44px */
        }

        &__text {
          font-family: var(--base-font-family);
        }
      }
    }

    &--image-right {
      .simple-appetiser-media {
        order: 2;
        left: -100px;
        margin-right: -100px;
        margin-left: unset;
      }

      .simple-appetiser-content {
        &-body {
          padding-left: 100px;
          padding-right: 150px;
        }
      }
    }
  }
}
