.animations--enabled {
  .social-media-feed {
    &-content {
      &__title {
        opacity: 0;
      }
    }
  }
}

.theme-branded {
  .social-media-feed {
    &-content {
      &__cta {
        margin-bottom: 150px;
      }
    }
  }
}

.social-media-feed {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-social-media-feed-background);
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
  }

  &-content {
    position: relative;
    z-index: 1;

    &__title {
      margin-top: 48px;
      margin-bottom: 48px;
      position: relative;
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 40px;

      @media (--viewport-md-min) {
        margin-bottom: 64px;
      }
    }

    &-list {
      height: 260px;

      &.flickity-enabled:focus .flickity-viewport {
        outline: none;
      }

      @media (--viewport-md-min) {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          pointer-events: none;
          background-image: linear-gradient(
            90deg,
            color(var(--color-social-media-feed-background) a(80%)) 0%,
            color(var(--color-social-media-feed-background) a(20%)) 25%,
            color(var(--color-social-media-feed-background) a(0)) 50%,
            color(var(--color-social-media-feed-background) a(20%)) 75%,
            color(var(--color-social-media-feed-background) a(80%)) 100%
          );
        }
      }

      &__item {
        height: 260px;
        width: 260px;
        margin-right: 20px;

        @media (--viewport-md-min) {
          margin-right: 40px;
        }

        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    &-list-controls {
      width: 288px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 80px;
      margin-bottom: 72px;

      &__input {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 1px;
        background-color: var(--color-social-media-feed-slider);
        outline: none;
        border: 0;

        &:focus {
          outline: none;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 32px;
          height: 32px;
          background-color: var(--color-social-media-feed-slider);
          border: 0;
          cursor: pointer;
          border-radius: 100%;
        }

        &::-ms-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 32px;
          height: 32px;
          background-color: var(--color-social-media-feed-slider);
          border: 0;
          cursor: pointer;
          border-radius: 100%;
        }

        &::-moz-range-thumb {
          width: 32px;
          height: 32px;
          background-color: var(--color-social-media-feed-slider);
          border: 0;
          cursor: pointer;
          border-radius: 100%;
        }
      }
    }
  }
}
