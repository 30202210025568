/*
 * Organism: Footer
 */

.footer {
  background: var(--color-footer-background);
  color: var(--color-footer-text);
  padding-bottom: 32px;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__logos {
  }

  &__inner {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
  }

  &__navigation {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    flex-direction: column;
  }

  &-logo-col {
    text-align: center;
    margin-bottom: 16px;

    p {
      font-size: 0.875rem; /* 14px */
    }

    & > p:last-of-type {
      margin-bottom: 8px;
    }

    &__brand-logo {
      width: 100%;
      max-width: 285px;
    }
  }

  &-other-brands {
    border-top: 1px solid var(--color-footer-other-brands-border);
    border-bottom: 1px solid var(--color-footer-other-brands-border);
    padding: 24px 0;
    margin-bottom: 24px;
    text-align: center;

    &__label {
      display: block;
      font-weight: 700;
      font-size: 0.875rem; /* 14px */
      color: var(--color-footer-other-brands-color);
      margin-bottom: 24px;
    }

    &__items {
      img {
        margin: 12px 24px;

        @media (--viewport-md-min) {
          margin: 12px 48px;
        }
      }
    }
  }

  &__isi {
    @extend .rte-style-isi;

    padding: 32px 0 8px;
    @media (--viewport-md-min) {
      padding: 56px 0 16px;
    }
  }
}
