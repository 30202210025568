.error-500-page {
  padding-top: 56px;

  &-info {
    margin: 40px 0;
    text-align: left;
    padding-bottom: 72px;
  }

  &-image {
    margin: 40px 0;

    img {
      max-width: 100%;
      max-height: 80vh;
      object-fit: contain;
      float: right;
    }
  }

  @media (--viewport-md-min) {
    padding-top: 64px;

    &-info {
      margin: 72px 0 144px;
    }
    &-image {
      margin: 72px 0;
    }
  }
}
