.story--branded .story-video-background-slide {
  .maili-ripped-paper {
    display: block;
  }
}

.story-video-background-slide {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  .maili-ripped-paper {
    display: none;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 24px;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9);

    &--close {
      transition: opacity 0.2s var(--transition);
      opacity: 0;
    }

    @media (--viewport-md-min) {
      padding: 100px;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
      height: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      margin: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      background: var(--color-white);
      color: var(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewport-md-min) {
        top: 40px;
        right: 40px;
      }

      .icon {
        margin: 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
      }
    }
  }

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;

    &__play {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate3d(-50%, -50%, 0);
      cursor: pointer;

      @media (--viewport-md-min) {
        width: 112px;
        height: 112px;
      }

      &:hover {
        &:before {
          opacity: 1;
        }

        svg {
          opacity: 1;
        }
      }

      svg {
        fill: var(--color-white);
        width: 100%;
        height: 100%;
        opacity: 0.75;
        transition: opacity 0.2s var(--transition);
        z-index: 4;
      }
    }
  }

  /* no black bars for iframe video from vimeo */

  &-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    height: 100%;
    object-fit: cover;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;

    &__iframe {
      width: 200%;
      height: 200%;
      position: absolute;
      top: -50%;
      left: -50%;
    }
  }
}
