/*
 * Organism: Header
 */

:root {
  --header-height: 96px;
  --header-height-desktop: 93px;
  --header-landing-page-height: 69px;
  --header-landing-page-height-desktop: 78px;
}

#js-header-mount-point-sinclair {
  display: none;
}

.header {
  background: var(--site-background-color);
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  transition: transform 0.2s var(--transition), box-shadow 0.2s var(--transition);
  background: 0.2s var(--transition);
  transform: translate3d(0, 0, 0);

  > .container {
    position: relative;
  }

  @media (--viewport-md-min) {
    height: var(--header-height-desktop);
  }

  &--sticky-hidden {
    transform: translate3d(0, calc(-1.05 * var(--header-height)), 0);

    @media (--viewport-md-min) {
      transform: translate3d(0, calc(-1.05 * var(--header-height-desktop)), 0);
    }
  }

  &--shadow {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  }

  &__navigation-spacer {
    padding-top: 24px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    @media (--viewport-md-min) {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
    }
  }

  &--landing-page {
    background: var(--color-header-landing-page-background);
    height: var(--header-landing-page-height);

    @media (--viewport-md-min) {
      height: var(--header-landing-page-height-desktop);
    }

    .header__inner {
      padding-top: 20px;
    }

    .main-navigation {
      @media (--viewport-md-min) {
        transform: translate3d(0, 0, 0);

        .main-navigation-list-item {
          padding-top: 12px;
        }
      }
    }
  }

  &--sinclair-static {
    position: static;
    margin-bottom: 20px;
    background: var(--color-primary) !important;

    .navigation-list-item:hover .navigation-list-item__link {
      color: var(--color-white) !important;
    }

    .navigation-list-item:hover .navigation-list-item__icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(80%) hue-rotate(152deg)
        brightness(102%) contrast(101%) !important;
    }
  }

  + .header-spacer {
    display: block;

    height: var(--header-landing-page-height);

    @media (--viewport-md-min) {
      height: var(--header-landing-page-height-desktop);
    }
  }
}

.header-spacer {
  height: var(--header-height);

  @media (--viewport-md-min) {
    height: var(--header-height-desktop);
  }
}
