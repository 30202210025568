/*
 * Atom: Button
*/

.button,
a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 48px;
  padding: 8px 20px;
  font-weight: 600;
  font-family: var(--base-font-family);
  font-size: 0.8125rem; /* 13px */
  line-height: 1;
  text-decoration: none;
  border-radius: 24px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: color 0.2s var(--transition), border 0.2s var(--transition);

  @media (--viewport-md-min) {
    padding: 12px 40px;
    font-size: 1.125rem; /* 18px */
  }

  /* Default style */
  color: var(--color-white);
  background: var(--color-gradient-turple);
  border: 0;

  &:disabled {
    color: var(--color-disabled);
    border-color: var(--color-disabled);
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: inherit;
      color: inherit;
    }

    &:before {
      display: none;
    }
  }

  /* Pseudo element to do hover */
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    background: var(--color-deepblue);
    opacity: 0;
    transition: opacity 0.2s var(--transition);
  }

  &:active,
  &:focus {
    outline: none;

    &:before {
      opacity: 1;
    }
  }

  @media (--viewport-md-min) {
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  /* Modifiers */
  &--secondary {
    background: var(--color-gradient-pink);
  }

  &--chip {
    width: auto;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 0;
    padding-top: 0;
    min-height: 23px;
    border-width: 1px;
    font-weight: normal;
    font-size: 0.75rem; /* 12px */
    text-transform: capitalize;
  }

  &--ghost {
    color: var(--color-deepblue);
    background: transparent;
    border: 2px solid currentColor;

    &:before {
      display: none;
    }

    &:active,
    &:focus {
      color: var(--color-eminence);
      background: transparent;
    }

    @media (--viewport-md-min) {
      &:hover {
        color: var(--color-eminence);
        background: transparent;
      }
    }
  }
}
