.filters-panel {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 32px;
  margin-left: 0;
  padding-top: 0;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  background: var(--color-white);
  border-radius: 8px;

  &__title {
    font-size: 1.5rem; /* 24px */
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-heading {
    margin-top: 0;
    margin-bottom: 4px;

    &__title {
      font-weight: 700;
      line-height: 1.5;
      font-size: 1.125rem; /* 18px */
    }

    &__clear-button {
      text-decoration: none;
      float: right;
      color: var(--color-black);
    }
  }

  &-active-list {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;

    &-item {
      color: var(--color-black);
      padding: 6px 0;
      cursor: pointer;
      font-size: 1.125rem; /* 18px */
      line-height: 1.75rem; /* 28px */

      > .icon {
        fill: var(--color-secondary);
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        margin-right: 4px;
        vertical-align: middle;
        transform: translate3d(0, -2px, 0);
      }
    }
  }

  &-section {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--color-grey);

    &:last-child {
      border-bottom: none;
    }

    &:first-child {
      padding-top: 40px;
    }
  }

  &-filter-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-bottom: 0;

    &:not(:last-child) {
      border-bottom: 2px solid var(--color-white);
    }

    &-item {
      padding: 6px 0;

      &-checkbox {
        cursor: pointer;

        &--disabled {
          opacity: 0.6;
          cursor: disabled;
          pointer-events: none;
        }

        &__input {
          appearance: none;

          /* Internet explorer fallback: */
          opacity: 0.01;
          width: 1px;
        }

        &__indicator {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          margin-left: -4px;
          margin-bottom: 2px;
          margin-right: 6px;
          font-size: 0;
          width: 20px;
          height: 20px;
          background: white;
          border: 1px solid var(--color-black);
          border-radius: 3px;
          transition: 0.2s background var(--transition), 0.2s border-color var(--transition);

          > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            display: inline-block;
            width: 20px;
            height: 16px;
            min-width: 20px;
            min-height: 16px;
            fill: white;
            transition: 0.2s opacity var(--transition);
            opacity: 0;
          }
        }

        /* Checkbox checked state - change indicator */
        .filters-panel-filter-list-item-checkbox__input:checked
          + .filters-panel-filter-list-item-checkbox__indicator {
          > svg {
            opacity: 1;
          }
        }
      }
    }

    &-show-more {
      padding: 5px 0;
      color: var(--color-secondary);
      font-size: 0.875rem; /* 14px */
      cursor: pointer;

      > .icon {
        fill: var(--color-secondary);
        width: 16px;
        height: 16px;
        margin-right: 4px;
        vertical-align: text-top;
      }
    }
  }
}
