/*
 * Organism: Breadcrumb
 */

.breadcrumb {
  margin: 40px 0;
  display: none;

  @media (--viewport-md-min) {
    display: block;
  }

  &-list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-item {
    display: none;

    &:before {
      display: none;
    }

    /* This makes sure you only ever see 2 pages in the breadcrumb */
    &:nth-last-child(-n + 2) {
      display: flex;
      align-items: center;
    }

    /* Show all breadcrumb links on sm and larger */
    @media (--viewport-sm-min) {
      display: flex;
      align-items: center;
    }

    &__link,
    &__page {
      display: flex;
      align-items: center;
      font-size: 0.875rem; /* 14px */
      line-height: 1;
    }

    &__link {
      text-decoration: none;
      color: currentColor;
      transition: color 0.2s var(--transition);

      @media (--viewport-md-min) {
        &:hover {
          color: var(--color-froly);
        }
      }
    }

    &__page {
      color: var(--color-eminence);
    }

    ~ .breadcrumb-item {
      &:before {
        content: '';
        display: block;
        margin: 0 10px;
        height: 20px;
        background: color(var(--color-deepblue) a(20%));
        border-radius: 25%;
        width: 1px;
        transform: rotate(30deg);
      }
    }
  }
}
