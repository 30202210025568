/*
 * Molecule: Grid element - Image
 */

.image {
  position: relative;
}

/* Defined with no nesting for use with @extend */
.image-caption {
  color: var(--color-deepblue);
  margin: 0; /* reset figcaption */
  text-align: left;
  font-size: 0.625rem; /* 10px */
  line-height: 1.25em;
  position: absolute;
  top: 93%;
  left: 50%;
  width: 50%;
  max-width: 210px;
  transform: translate3d(-100%, -50%, 0);

  @media (--viewport-md-min) {
    width: 45%;
    font-size: 0.6875rem; /* 11px */
  }

  /* background-color shadow: visible only if the text overlays the image */
  text-shadow: 0 0 6px var(--site-background-color),
    0 0 6px var(--site-background-color), 0 0 6px var(--site-background-color);
}

/* Defined with no nesting for use with @extend */
.image-caption--right {
  transform: translate3d(0, -50%, 0);
}
