/*
 * Atom: Lists
 */

.rte,
.appetiser {
  ul,
  ol {
    margin: 0 0 24px;
    padding: 0;
    list-style: none;

    @media (--viewport-sm-min) {
      margin-bottom: 32px;
    }

    li {
      margin-bottom: 8px;

      @media (--viewport-md-min) {
        font-size: 1.125rem; /* 18px */
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 24px;
      line-height: 2;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        background: var(--color-list-dot);
        border-radius: 100%;
        width: 11px;
        height: 11px;
      }
    }
  }

  ol {
    counter-reset: li;
    list-style: decimal;

    li {
      counter-increment: li;
      line-height: 2;
      position: relative;
      padding-left: 24px;

      &:before {
        /* it was adding unnecessary numbering to rich text editor */
        /* content: counter(li) '.'; */
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 1rem; /* 16px */
        font-weight: bold;
        color: var(--color-list-dot);
      }
    }
  }
}
