/*
 * Molecule: Grid element - Video slider
 */

:root {
  --before-after-slider-handle-color: var(--color-eminence);
  --before-after-slider-radius: 10px;
}

.video-slider {
  width: 100%;
  margin-bottom: 32px;
  &__title {
    margin-top: 48px;
    margin-bottom: 48px;
    position: relative;
    z-index: 2;
  }

  &__cta {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: var(--container-spacing--mobile);
    padding-right: var(--container-spacing--mobile);

    @media (--viewport-md-min) {
      margin-bottom: 48px;
      padding-left: var(--container-spacing);
      padding-right: var(--container-spacing);
    }
  }

  &-item {
    position: relative;
    width: 100%;

    @media (--viewport-md-min) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 0;
    }

    /* if only the media is present and no content, center the media */
    > div:first-child:nth-last-child(1) {
      margin-left: auto;
      margin-right: auto;
    }

    &-media {
      position: relative;
      margin-bottom: 24px;
      margin-top: 24px;

      @media (--viewport-md-min) {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
        margin-bottom: 32px;
        margin-top: 0;
      }
    }

    &-overlay {
      position: relative;
      z-index: 1;
      transition: opacity 0.2s var(--transition), z-index 0.6s;
      height: 0;
      padding-bottom: 56%; /* 16:9 aspect ratio for the player container */

      &--hide {
        opacity: 0;
        pointer-events: none;
        z-index: 0;
      }

      &__thumbnail {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        object-fit: cover;
        cursor: pointer;
      }

      &__play {
        color: var(--color-white);
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        cursor: pointer;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (--viewport-md-min) {
          width: 112px;
          height: 112px;
        }

        &:hover {
          &:before {
            opacity: 1;
          }

          svg {
            opacity: 1;
          }
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 8px solid var(--color-white);
          background-image: radial-gradient(
            54% 145% at 0 0,
            rgba(0, 0, 0, 0) 54%,
            rgba(0, 0, 0, 0.33) 100%
          );
          border-radius: 100%;
          opacity: 0.23;
          transition: opacity 0.2s var(--transition);
        }

        svg {
          fill: var(--color-white);
          width: 24px;
          height: 24px;
          opacity: 0.75;
          transition: opacity 0.2s var(--transition);
          z-index: 4;

          @media (--viewport-md-min) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (--viewport-md-min) {
        margin-left: 8.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
      }

      &__title-mobile {
        margin-bottom: 20px;
        max-width: 85%;

        @media (--viewport-md-min) {
          display: none;
        }
      }

      &__title-desktop {
        display: none;

        @media (--viewport-md-min) {
          display: block;
          margin-bottom: 20px;
          max-width: 75%;
        }
      }

      &__caption {
        display: block;
        font-weight: 300;

        @media (--viewport-md-min) {
          font-size: 1.125rem; /* 18px */
          line-height: 1.5625rem; /* 25px */
          max-width: 75%;
        }
      }
    }

    &__embed {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      iframe {
        width: 100%;
        height: 100%;
      }

      /** set the tencent player to 100% of it's parent container */
      & .tencent-player {
        height: 100%;
      }
    }
  }

  &-text-item {
    width: 100%;
    text-align: center;

    &__title {
      color: var(--color-deepblue);
      text-transform: uppercase;
      font-size: 1.375rem; /* 22px */
      letter-spacing: 0.08em;
      font-weight: bold;

      margin: 24px 0 16px;

      @media (--viewport-md-min) {
        font-size: 1.5rem; /* 24px */
        margin: 32px 0 24px;
      }
    }

    &__caption {
      margin-bottom: 40px;
    }
  }

  &-selector {
    display: flex;
    height: 24px;
    width: 240px;
    justify-content: space-between;
    align-items: center;
    margin: 24px auto 0;
    background-image: none !important;

    @media (--viewport-md-min) {
      margin-top: 32px;
    }

    &-progress {
      position: absolute;
      width: inherit;
      height: 2px;
      background: var(--color-video-slider-controls);
    }

    &-item {
      display: block;
      position: relative;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: var(--color-video-slider-controls);
      transition: all 0.3s var(--transition);

      &:focus {
        outline: none;
      }

      &--selected {
        position: relative;
        transform: scale(2);
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
      }
    }
  }
}
