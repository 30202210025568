/*
 * Molecule: Grid element - Quote
 */

.quote {
  position: relative;

  &-background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;

    &__image {
      fill: var(--color-primary);
      position: absolute;
      width: 161px;
      height: 126px;
      right: -32px;
      bottom: -16px;
      @media (--viewport-md-min) {
        width: 231px;
        height: 182px;
        right: -24px;
        bottom: -24px;
      }
    }

    &__lines {
      position: absolute;
      top: 55%;
      left: 55%;
      transform: translate3d(-50%, -50%, 0) scale(0.45);
      stroke: var(--color-scooter);

      @media (--viewport-sm-max) {
        path:nth-child(2) {
          transform: translate3d(0, 6%, 0);
        }
        path:last-child {
          transform: translate3d(0, 12%, 0);
        }
      }

      @media (--viewport-sm-min) {
        transform: translate3d(-50%, -50%, 0) scale(0.6);
      }

      @media (--viewport-md-min) {
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  &--gradient-background .quote {
    &-background {
      background: var(--color-gradient-turple-reverse);

      transform: translate3d(-50%, -50%, 0) rotate(180deg);
      &__lines {
        top: 50%;
      }
    }
    &-content {
      color: var(--color-white);
    }
  }

  &-content {
    position: relative;
    z-index: 1;
    color: var(--color-eminence);
    line-height: 1.25;
    text-align: center;
    max-width: 875px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 64px;
    padding-bottom: 64px;

    @media (--viewport-md-min) {
      padding-top: 128px;
      padding-bottom: 128px;
    }

    &__text {
      font-size: 1.75rem; /* 28px */
      line-height: 2.25rem; /* 36px */
      font-weight: 300;
      display: block;

      @media (--viewport-sm-min) {
        font-size: 2.1875rem; /* 35px */
        line-height: 2.8125rem; /* 45px */
      }

      &:only-child {
        margin-bottom: 64px;
      }

      &:before,
      &:after {
        content: '”';
      }
    }

    &__author {
      display: block;
      text-align: center;
      font-size: 1.375rem; /* 22px */
      font-weight: bold;
      margin-top: 40px;
    }
  }
}
