/*
 * Organism: ISI - Important Safety Information
 */

:root {
  --isi-height: 240px;
  --isi-height-desktop: 240px;
}
.isi {
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  width: 100%;

  @extend .rte-style-isi;

  .container {
    position: relative;
  }

  &__preview {
    background: var(--color-isi-preview-background);
    padding: 10px 0 0;
    display: flex;

    @media (--viewport-md-min) {
      padding-top: 12px;
    }
  }

  &__full {
    background: var(--color-isi-full-background);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    transition: opacity 0.2s var(--transition);
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
    max-height: 100%;
    display: flex;

    @media (--viewport-md-min) {
      overflow: visible;
      max-height: auto;
    }
  }

  &--active {
    z-index: 400; /* Move above menu */
    .isi__full {
      pointer-events: auto;
      opacity: 1;
    }

    .isi__preview {
      pointer-events: none;
      opacity: 0;
    }
  }

  &-toggle {
    &__trigger {
      background: none;
      color: var(--color-isi-toggle-text);
      font-family: var(--base-font-family);
      border: 0;
      padding: 0;
      margin: 0;
      position: absolute;
      right: var(--container-spacing--mobile);
      font-size: 0.75rem; /* 12px */
      cursor: pointer;

      @media (--viewport-md-min) {
        right: var(--container-spacing);
        font-size: 0.875rem; /* 14px */
      }

      svg {
        position: relative;
        top: 1px;
        margin-left: 4px;
        stroke: var(--color-isi-toggle-icon);
      }
    }

    @media (--viewport-sm-max) {
      & + p {
        margin-top: 4px;
      }
    }
  }
}

/* The height is the same as the ISI height, to allow for the ISI to be displayed at the bottom, after the footer */
.isi-spacer {
  background: var(--color-isi-spacer-background);
}

.rte-style-isi {
  color: var(--color-isi-rte-text);

  strong {
    color: var(--color-isi-rte-bold);
    text-transform: uppercase;
  }

  p {
    font-size: 0.75rem; /* 12px */
    line-height: 1.15em;
    margin-bottom: 10px;

    @media (--viewport-md-min) {
      font-size: 0.875rem; /* 14px */
      max-width: calc(100% - 100px);
    }
  }

  a {
    color: var(--color-isi-rte-link);

    &:hover {
      color: var(--color-isi-rte-link-hover);
    }
  }
}
