.related-events {
  background-color: var(--color-background-02);
  display: block;
  padding-top: 67px;

  &__card {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .event-card {
    width: 100%;

    &-content-bg-media__image {
      max-width: 100%;
      max-height: 335px;

      width: 100%;
      height: 100%;
    }
  }

  &__header {
    padding-bottom: 28px;
  }

  .related-events__cta {
    display: none;
  }

  @media (--viewport-md-min) {
    padding-bottom: 60px;

    .related-events__cta {
      display: revert;
    }

    &__column {
      display: flex;
    }

    &__column:first-child {
      justify-content: flex-start;
      padding-left: 0;
    }

    &__column:last-child {
      justify-content: flex-end;
      padding-right: 0;
    }

    &__card {
      max-width: 502px;
    }

    .event-card {
      max-width: 100%;

      &-content-bg-media__image {
        width: 100%;
        max-height: 331px;
      }
    }
  }

  @media (--viewport-xl-min) {
    &__card {
      max-width: 803px;
    }

    .event-card-content-bg-media__image {
      max-height: 445px;
    }

    .event-card-content-header-info {
      font-size: 1.25rem; /** 20px **/
    }
  }
}
