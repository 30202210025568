.facial-area-item {
  margin-right: 24px;

  @media (--viewport-md-min) {
    margin-right: 40px;
  }

  &-content {
    &-icon-wrapper {
      position: relative;
      height: 90px;
      width: 90px;

      & img {
        width: 100%;
        height: 100%;
        padding: 8px 16px 8px 16px;
        background: var(--color-white);
        border-radius: 12px;
        box-shadow: 2px 6px 15px 0 rgb(4 43 45 / 18%);
        position: relative;
      }
    }

    &-text {
      letter-spacing: 0.6px;
      line-height: 2.25rem; /* 36px */
      text-align: center;

      @media (--viewport-md-min) {
        font-size: 0.75rem; /* 12px */
      }
    }
  }

  &--selected {
    & .facial-area-item-content {
      &-icon-wrapper {
        &:before {
          content: '';
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          position: absolute;
          background: linear-gradient(216deg, #f1be9a 0%, #ffc6d4 49%, #b0afc4 100%);
          border-radius: 12px;
          display: block;
          z-index: 0;
          transform: translate(-2px, -2px);
        }
      }
    }
  }

  &--large {
    @media (--viewport-md-min) {
      margin-bottom: 8px;
      & .facial-area-item-content {
        &-icon-wrapper {
          height: 130px;
          width: 130px;
          margin-bottom: 8px;
        }
        &-text {
          font-size: 1.25rem; /* 20px */
        }
      }
    }
  }
}
