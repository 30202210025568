/*
 * Page: Clinic finder page
 */

.clinic-finder-page-heading {
  margin-top: 16px;

  @media (--viewport-sm-min) {
    margin-top: 32px;
  }
}

.clinic-finder-embed {
  width: 100%;
}
