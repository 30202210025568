/*
 * Molecule: Grid element - Appetiser
 */

.appetiser {
  &-wrapper {
    position: relative;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @media (--viewport-xl-min) {
      margin-left: calc(50% - calc(50% + 90px));
      margin-right: calc(50% - calc(50% + 90px));
    }

    @media (--viewport-sm-max) {
      display: flex;
      flex-direction: column;

      .appetiser-content-row {
        order: 1;
      }
      .appetiser__image {
        order: 0;
      }
    }
  }

  &__subtitle {
    padding-bottom: 20px;
    padding-top: 40px;
  }

  &-content {
    position: relative;
    margin-top: 64px;
    margin-bottom: 64px;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    flex-flow: column;

    @media (--viewport-sm-min) {
      margin-top: 96px;
      margin-bottom: 96px;
      padding-right: 64px;
      padding-left: 64px;
    }

    & .button {
      align-self: center;

      @media (--viewport-sm-min) {
        align-self: start;
      }
    }

    h2 {
      margin-top: 0;
      position: relative;
      z-index: 2;
    }

    &__icon {
      margin-bottom: 40px;
      max-width: fit-content;
      max-height: fit-content;
    }

    &__disclaimer {
      font-size: 0.875rem;
      line-height: 1.25;
      margin-top: 16px;

      @media (--viewport-md-min) {
        margin-top: 32px;
      }

      p {
        font-size: 1em;
      }
    }
  }

  &__image {
    height: 100%;
    display: flex;
    align-items: flex-end;

    @media (--viewport-sm-min) {
      margin-top: 0;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 50%;
      width: 50%;
    }

    img {
      max-height: 100%;
      object-fit: cover;
      height: auto;

      @media (--viewport-sm-min) {
        width: 100%;
        height: 100%;
      }
    }
  }

  /* Modifiers */
  &--image-left {
    .appetiser__image {
      @media (--viewport-sm-min) {
        left: 0;
      }
    }
  }
}
