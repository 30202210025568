.meet-the-team-list {
  position: relative;
  margin-bottom: 184px;

  & h2 {
    margin-bottom: 48px;

    @media (--viewport-md-min) {
      margin-bottom: 80px;
    }
  }

  .cta {
    margin-top: 56px;

    @media (--viewport-md-min) {
      margin-top: 104px;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    margin: -20px;

    &-item {
      padding: 20px;
      width: 100%;

      @media (--viewport-ms-min) {
        width: 50%;
      }

      @media (--viewport-lg-min) {
        width: 33.33333333%;
      }

      & .meet-the-team-card {
        width: 100%;
        height: 100%;
      }
    }
  }
}
