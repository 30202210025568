/*
 * Molecule: Form
 */

.form {
  &--validate {
    .form-field--error {
      &.input,
      &.textarea,
      &.select,
      &[type="checkbox"] + .checkbox__tick:before,
      &[type="radio"] + .radio__button:before {
        background-color: color(var(--color-warning) l(92%));
        border-color: var(--color-warning);
      }
    }
  }

  &-group {
    margin-bottom: 24px;
    max-width: 560px;

    &--full {
      max-width: unset;
      width: 100%;
    }

    &__label {
      display: block;
      padding-left: 16px;
    }

    &__message {
      margin-top: 8px;
      font-size: 0.8125rem; /* 13px */
      padding-left: 16px;
      color: var(--color-danger);
    }
  }
}
