.contact-details-screen {
  margin-top: 106px;

  &-content {
    background: linear-gradient(
      216.75deg,
      rgba(255, 239, 228, 0.98) 0%,
      #ffe3ea 46.88%,
      #b0afc4 100%
    );
    padding-top: 40px;
    padding-right: 18px;
    padding-bottom: 30px;
    padding-left: 18px;

    @media (--viewport-md-min) {
      padding-left: 88px;
      padding-right: 88px;
      padding-top: 64px;
      padding-bottom: 128px;
    }

    &-title {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1px;
      width: 250px;
      margin-bottom: 32px;

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3rem; /* 48px */
        width: auto;
      }
    }

    &-text {
      margin-bottom: 40px;

      @media (--viewport-md-min) {
        max-width: 500px;
        font-size: 1.25rem; /* 20px */
        line-height: 2.1875rem; /* 35px */
        margin-bottom: 72px;
      }
    }

    &-form {
      margin-bottom: 24px;

      @media (--viewport-md-min) {
        max-width: 535px;
        margin-bottom: 48px;
      }

      &-field {
        margin-bottom: 24px;

        &__label {
          margin-bottom: 8px;
        }

        & .input {
          background-color: rgba(255, 255, 255, 0.6);

          &::placeholder {
            font-size: 1rem; /* 16px */
          }
        }

        &__error {
          margin-top: 4px;
          color: var(--color-visualiser-error-color);
        }
      }
    }
  }

  .checkbox {
    display: inline;
    
    a {
      color: white;
    }
  }
}
