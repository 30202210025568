.meet-the-team-card {
  display: flex;
  width: 100%;

  @media (--viewport-md-min) {
    margin-left: 0;
    margin-right: 0;
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    padding: 16px 48px 24px 48px;
    background-color: var(--color-meet-the-team-card-background);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    align-items: center;

    @media (--viewport-md-min) {
      padding: 24px 56px 48px 56px;
    }

    &__image {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 100%;
      border: 2px solid var(--color-meet-the-team-card-image-border);
      padding: 8px;
      margin-bottom: 8px;

      @media (--viewport-md-min) {
        width: 250px;
        height: 250px;
        padding: 14px;
        margin-bottom: 24px;
      }
    }

    &__title {
      color: var(--color-meet-the-team-card-title);
      margin-bottom: 16px;
      font-weight: var(--heading-font-weight);
      margin-bottom: 16px;
      font-size: 1.5rem; /* 24px */
      line-height: 1.875rem; /* 30px */

      @media (--viewport-md-min) {
        margin-bottom: 8px;
      }
    }

    &__description {
      margin-bottom: 32px;
      color: var(--color-meet-the-team-card-description);
      font-size: 0.875rem;

      @media (--viewport-md-min) {
        font-size: 1rem;
        margin-bottom: 24px;
      }
    }

    &__link {
      font-weight: 700;
      font-size: 0.875rem;
      text-decoration: none;
      margin-bottom: 8px;

      .icon {
        min-width: 8px;
        min-height: 12px;
        width: 8px;
        height: 12px;
        margin-left: 8px;
      }
    }
  }
}
