.quote-slider {
    position: relative;
}

.quote-slider-content-slides-controls {
    @extend .content-slider__button-row;
    width: 100%;
    padding-bottom: 0;
    position: absolute;
    bottom: 22px;
    left: 0;

    @media (--viewport-md-min) {
      bottom: 46px;
    }

    &-progress {
      position: absolute;
      height: 2px;
      width: 240px;
      background: var(--color-primary);
      top: 50%;
      transform: translateY(-50%);
    }

    &-buttons {
      @extend .content-slider__btn-group;
      background-image: none;

      &__button {
        @extend .content-slider__btn;
        background-color: var(--color-primary);
        z-index: 5;

        &--is-selected {
          @extend .content-slider__btn--is-selected;
        }
      }
    }
  }
