.story--branded .story-image-with-bold-text-slide {
  .maili-ripped-paper {
    display: block;
  }

  &-content {
    & h2 {
      font-family: 'Druk';
      font-style: italic;
      text-transform: uppercase;
      line-height: 1;
      position: relative;
      font-weight: 900;
      font-size: 52px;
      margin-bottom: 40px;
      letter-spacing: -0.08ch;

      @media (--viewport-sm-min) {
        font-size: 72px;
      }

      @media (--viewport-md-min) {
        font-size: 140px;
        margin-bottom: 72px;
      }

      &:after {
        display: none;
      }

      span:first-child {
        transform: translateX(-0.5em);
        line-height: 1;
        position: absolute;
        top: -0.74em;
      }

      span:last-child {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px;
      }
    }

    &__text {
      font-weight: bold;

      p {
        @media (--viewport-md-min) {
          font-size: 2rem; /* 32px */
        }
      }
    }
  }
}

.story-image-with-bold-text-slide {
  display: flex;
  align-items: center;
  position: relative;

  .maili-ripped-paper {
    display: none;
  }

  &-content {
    color: var(--color-story-image-with-bold-text-slide-text);
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    bottom: 20vh;
    z-index: 3;
    width: 100%;

    @media (--viewport-sm-min) {
      max-width: 80%;
    }

    & h2 {
      margin-top: 120px;

      @media (--viewport-md-min) {
        margin-top: 0;
      }

      &:after {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 40px;

        @media (--viewport-md-min) {
          margin-top: 72px;
          margin-bottom: 72px;
        }
      }
    }

    &__text {
      font-size: 1.125rem; /* 18px */

      p {
        @media (--viewport-md-min) {
          font-size: 2rem; /* 24px */
        }
      }
    }
  }

  &-background {
    position: relative;
    left: 50%;
    width: var(--site-max-width);
    max-width: 150vw;
    transform: translate3d(-50%, 0, 0);
    object-fit: cover;
  }
}
