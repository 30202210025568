.story--branded .story-text-slide {
  &-content {
    color: var(--color-white);

    & h2 {
      font-family: 'Druk';
      font-style: italic;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 900;
      font-size: 72px;
      margin-bottom: 40px;
      padding-top: 1em;
      letter-spacing: -0.08ch;

      @media (--viewport-md-min) {
        font-size: 140px;
        margin-bottom: 72px;
      }

      &:after {
        display: none;
      }

      span:first-child {
        transform: translate3d(-0.5em, -1em, 0);
        line-height: 1;
        position: absolute;
      }

      span:last-child {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px;
      }
    }

    &__text {
      font-weight: bold;

      p {
        @media (--viewport-md-min) {
          font-size: 2rem; /* 32px */
        }
      }
    }
  }

  &-background {
    display: none;
  }
}

.story-text-slide {
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1920px;
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-story-text-slide-bg);
    z-index: -1;
  }

  &-content {
    color: var(--color-story-text-slide-text);
    text-align: center;

    & h2 {
      margin-top: 120px;
      position: relative;
      z-index: 1;

      @media (--viewport-md-min) {
        margin-top: 0;
      }

      &:after {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 40px;

        @media (--viewport-md-min) {
          margin-top: 72px;
          margin-bottom: 72px;
        }
      }
    }

    &__text {
      position: relative;
      z-index: 1;
      margin-bottom: 150px;
      font-size: 1.125rem; /* 18px */

      p {
        @media (--viewport-md-min) {
          font-size: 1.5rem; /* 24px */
        }
      }
    }
  }

  &-background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    mix-blend-mode: multiply;
    object-fit: cover;
  }
}
