/*
 * Molecule: Grid element - Content card listing
 */
.content-cards {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-content-card-listing-background);
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
  }

  &__title {
    margin-top: 48px;
    margin-bottom: 48px;
    position: relative;
    z-index: 2;
  }

  &__subtitle {
    @media (--viewport-md-min) {
      max-width: 832px;
      font-size: 1.375rem; /* 22px */
      line-height: 1.75rem; /* 28px */
      letter-spacing: 0.015em;
    }
  }

  &__list {
    position: relative;

    @media (--viewport-md-min) {
      margin-left: var(--half-gutter-compensation);
      margin-right: var(--half-gutter-compensation);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .flickity-viewport {
      overflow: visible;
    }
  }

  &__cta {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: var(--container-spacing--mobile);
    padding-right: var(--container-spacing--mobile);

    @media (--viewport-md-min) {
      margin-bottom: 48px;
      padding-left: var(--container-spacing);
      padding-right: var(--container-spacing);
    }
  }
}
