/*
 * Atom: Checkbox
 */

.checkbox {
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    .checkbox__tick:before {
      border-color: var(--color-link);
    }
  }

  [type='checkbox'] {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus + .checkbox__tick:before {
      outline: #5d9dd5 solid 1px;
      box-shadow: 0 0 8px #5e9ed6;
    }

    &:checked + .checkbox__tick:before {
      border-color: var(--color-border);
    }

    &:disabled + .checkbox__tick:before {
      background: var(--color-disabled);
      border-color: var(--color-disabled);
    }

    &:checked + .checkbox__tick:after {
      opacity: 1;
    }
  }

  &__tick {
    position: relative;
    margin-right: 8px;

    &:before {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      border: 1px var(--color-border) solid;
      border-radius: 3px;
      transition: border 0.2s var(--transition);
    }

    &:after {
      content: '';
      display: block;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 38 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='%23837872' fill-rule='evenodd'%3E%3Cpath d='M22.4888669,4.51113305 C23.1703777,5.19264379 23.1703777,6.29759101 22.4888669,6.97910175 L10.1490235,19.3189452 C9.88690397,19.5810647 9.56214592,19.742369 9.22293283,19.8028582 L9.01805583,19.8270538 L8.81202243,19.8270538 C8.40034111,19.8028582 7.9955982,19.6334886 7.68105479,19.3189452 L1.51113305,13.1490235 C0.829622316,12.4675127 0.829622316,11.3625655 1.51113305,10.6810548 C2.19264379,9.99954405 3.29759101,9.99954405 3.97910175,10.6810548 L8.9141074,15.6155014 L20.0208983,4.51113305 C20.702409,3.82962232 21.8073562,3.82962232 22.4888669,4.51113305 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 50% top 50%;
      background-size: 20px;
      position: absolute;
      top: 50%;
      left: calc(50% + 4px);
      transform: translate3d(-50%, -50%, 0);
      height: 16px;
      width: 20px;
      opacity: 0;
      transition: opacity 0.2s var(--transition);
    }
  }
}
