.profile-cards {
  display: flex;
  flex-flow: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-top: 52px;

    & .select__wrapper {
      @media (--viewport-sm-max) {
        width: 185px;
      }
    }
  }

  @media (--viewport-sm-max) {
    padding: 0;
    display: flex;
    flex-flow: column;
  }

  &__list {
    margin-top: 27px;
    gap: 24px;
    height: 450px;

    @media (--viewport-sm-max) {
      height: 501px;
    }
  }

  &__cta {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 65px;

    @media (--viewport-sm-max) {
      margin-bottom: 56px;
    }
  }

  &--hidden {
    visibility: hidden;
  }

  .slider-nav {
    animation: profileFadeIn 2s ease;
  }
}

@keyframes profileFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
