/*
 * Atom: Radio
 */

.radio {
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    .radio__button:before {
      border-color: var(--color-border);
    }
  }

  [type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus + .radio__button {
      outline: #5d9dd5 solid 1px;
      box-shadow: 0 0 8px #5e9ed6;
    }

    &:checked + .radio__button:after {
      opacity: 1;
    }

    &:checked + .radio__button:before {
      border-color: var(--color-border);
    }

    &:disabled + .radio__button {
      cursor: not-allowed;

      &:before {
        border-color: var(--color-disabled) !important;
        background: var(--color-disabled) !important;
      }
    }
  }

  &__button {
    position: relative;
    margin-right: 8px;

    &:before {
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      border: 1px solid var(--color-border);
      transition: border 0.2s var(--transition);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      height: 8px;
      width: 8px;
      opacity: 0;
      border-radius: 50%;
      background: var(--color-border);
      transition: opacity 0.2s var(--transition);
    }
  }
}
