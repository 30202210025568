/*
 * Molecule: Copyright
 */

.copyright {
  display: flex;
  font-size: 0.875rem; /* 14px */
  width: 80%;

  @media (--viewport-md-min) {
    width: 100%;
    justify-content: center;
  }

  & .icon--CEmark {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    margin-right: 8px;
    color: var(--color-copyright-ce-icon);
  }
}
