.b2b-signup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s;
  background-color: var(--color-white);

  .select__wrapper {
    width: 100%;

    select {
      width: 100%;
      padding: 22px 16px 17px;
      border: 1px solid #949494;
      height: 100%;
      display: inline-block;
      background-color: #f4faff;
      font-size: 16px;
      color: black;
    }

    .icon {
      top: 58px;
      right: 22px;
      transform: unset;
      fill: #012169;
    }
  }

  &--expanded {
    transform: translateX(0);
  }

  &-inner {
    height: 100vh;
    overflow-y: auto;

    @media (--viewport-md-min) {
      display: flex;
    }

    & .icon.icon--close-icon-bold {
      position: absolute;
      right: 24px;
      top: 24px;
      fill: var(--color-black);
      height: 24px;
      width: 24px;

      @media (--viewport-md-min) {
        fill: var(--color-white);
        height: 32px;
        width: 32px;
        right: 56px;
        top: 56px;
      }
    }

    &-image {
      display: none;

      @media (--viewport-md-min) {
        object-fit: cover;
        order: 1;
        height: auto;
        min-height: 100vh;
        width: 592px;
        margin-bottom: 0;
        display: block;
      }
    }

    &-content {
      @media (--viewport-md-min) {
        margin-left: 64px;
        margin-right: 88px;
        margin-top: 72px;
      }

      &-title {
        font-family: var(--heading-font-family);
        font-weight: var(--heading-font-weight);
        line-height: 2.5rem; /* 40px */
        font-size: 2rem; /* 32px */
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 24px;
        margin-top: 56px;

        @media (--viewport-md-min) {
          font-size: 3rem; /* 48px */
          line-height: 3.5rem; /* 56px */
          margin-bottom: 48px;
        }
      }

      &-form {
        @media (--viewport-sm-max) {
          padding-left: 40px;
          padding-right: 40px;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        & .form {
          flex-flow: column;
          display: flex;
          align-items: center;

          .form-group {
            width: 100%;
            max-width: unset;
          }

          @media (--viewport-md-min) {
            width: 100%;
            flex-flow: row wrap;

            & .form-group {
              margin-right: 32px;

              @media (--viewport-md-min) {
                width: calc(50% - 32px);
                margin-bottom: 48px;
              }

              &--full {
                width: 100% !important;
              }
            }
          }

          & .input__wrapper {
            position: relative;

            & .form-group__message {
              padding-left: 0;
              position: absolute;
            }
          }
        }

        &__accept-terms,
        &-interests {
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-width: 660px;
          width: 100%;
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 16px;
          min-width: auto;
          align-items: flex-start;
          margin-bottom: 20px;

          @media (--viewport-md-min) {
            display: flex;
            justify-content: center;
            min-width: 100%;
            margin-bottom: 48px;
            margin-left: 0;
            margin-right: 0;
          }

          & a {
            text-decoration: none;
          }
        }

        .checkbox {
          margin-bottom: 0;
          justify-content: center;
        }

        & .cta {
          width: 100%;

          & .button {
            @media (--viewport-md-min) {
              min-width: 416px;
            }
          }
        }
      }
    }
  }
}
