/**
 * Organism: Grid element - Image slideshow
 * TODO: Use CSS variables, clean up code
 */

.image-slideshow-wrapper {
  outline: none;
  position: relative;
  cursor: grab;
  width: 100%;

  .flickity-viewport {
    overflow: hidden;
  }
}

.lf .image-slideshow {
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;

  .flickity-button {
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);

    @media (--viewport-md-min) {
      box-shadow: none;
    }

    &:hover {
      @media (--viewport-md-min) {
        background: none;
      }
    }

    &.next {
      margin-right: 0;

      @media (--viewport-md-min) {
        margin-right: -7px;
      }
    }

    &.previous {
      margin-left: 0;

      @media (--viewport-md-min) {
        margin-left: -7px;
      }
    }
  }

  .flickity-prev-next-button {
    width: 22px;
    height: 22px;

    @media (--viewport-md-min) {
      width: 15px;
      height: 15px;
    }

    .flickity-button-icon {
      fill: var(--color-black);
      left: 20%;
      top: 30%;
      width: 60%;
      height: 40%;

      @media (--viewport-md-min) {
        left: 20%;
        top: 20%;
        width: 60%;
        height: 60%;
      }
    }
  }
}

.image-slideshow-slide {
  width: 100%;
  height: 100vh;
  max-height: 50vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  position: relative;
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__content {
    width: 90%;
    max-width: 800px;
    position: absolute;
    left: 50%;
    bottom: 60px;
    color: white;
    text-align: center;
    z-index: 2;
    opacity: 0;
    transform: translateY(20px) translateX(-50%);
    transition: all 0.7s 0.3s;
    will-change: transform;
  }

  &.is-selected {
    .image-slideshow-slide__content {
      opacity: 1;
      transform: translateY(0) translateX(-50%);
    }
  }
}

.image-slideshow-navigation {
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 1;
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;

  &__dot {
    width: 8px;
    height: 8px;
    background-color: white;
    opacity: 0.5;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.5s 0.1s;
    margin-right: 4px;

    &.is-selected {
      opacity: 1;

      &::before {
        opacity: 0.3;
      }
    }
  }
}
