.slider-card {
    &-content {
      &-media {
        overflow: hidden;
        
        &__image {
            object-fit: cover;
            width: 100%;
            height: 200px;
        }
      }
  
      &-footer {
        margin-top: 13px;
  
        &__tag {
          font-size: 0.625rem; /** 10px **/
          color: var(--color-midnight-blue);
          margin-bottom: 10px;
        }

        &__logo {
          margin-top: 30px;
          margin-bottom: 20px;
          display: block;
          width: 80%;
        }
  
        &__title {
          font-size: 1.125rem; /** 18px **/
          color: var(--color-midnight-blue);
          font-weight: 600;
          line-height: 1.5rem;
          margin-bottom: 20px;
        }
  
        &__description {
          font-size: 1rem;
          color: var(--color-midnight-blue);
          font-weight: 400;
        }
      }
    }
  
    @media (--viewport-md-min) {
      &-content {
        &-media {
          &__image {
              object-fit: cover;
              width: 100%;
              height: 400px;
          }
        }
  
        &-footer {
          &__logo {
            width: 60%;
          }
          &__tag {
            font-size: 0.875rem; /** 14px **/
          }
  
          &__title {
            font-size: 1.125rem; /** 18px **/
          }
  
          &__description {
            font-size: 0.875rem; /** 14px **/
          }
        }
      }
    }
  }