/*
 * Molecule: Grid element - Clinic finder banner for instalift
 */

.clinic-finder-banner-instalift {
  position: relative;
  height: 600px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 100%;
    width: var(--site-max-width);
    background: url(/dist/instalift/images/map.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    transform: translate3d(-50%, 0, 0);
  }

  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    &__icon {
      margin-bottom: 24px;

      path {
        stroke: var(--color-eminence);
      }
    }

    &__title {
      @extend h2;
      color: var(--color-eminence);
      margin-bottom: 24px;

      &:after {
        display: none;
      }
    }

    &__description {
      max-width: 520px;
      font-size: 1.125rem; /* 18px */
      font-weight: bold;
      color: var(--color-black);
      margin-bottom: 24px;

      @media (--viewport-md-min) {
        margin-bottom: 40px;
      }
    }
  }
}
