/*
 * Organism: prev-next-buttons
 */

.prev-next-buttons {
  padding: 64px 0;
  background-color: var(--color-prev-next-buttons-background);
  color: var(--color-prev-next-buttons-foreground, inherit);

  @media (--viewport-md-min) {
    padding: 48px 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justift-content: center;
    flex-direction: column;

    @media (--viewport-md-min) {
      padding: 68px;
      align-items: center;
      justift-content: center;
      flex-direction: row;
    }
  }

  &__button {
    color: inherit;
    display: flex;
    align-items: center;
    justift-content: center;
    flex-direction: column;

    @media (--viewport-md-min) {
      flex-direction: row;
      justify-content: flex-start;
      width: 50%;

      &:last-child {
        justify-content: flex-end;

        .prev-next-buttons__button-text {
          > b,
          > span {
            text-align: right;
          }
        }
      }
    }

    @media (--viewport-sm-max) {
      &:not(:last-child) {
        margin-bottom: 58px;
      }
    }
  }

  &__button-text {
    padding: 20px;
    text-align: center;

    @media (--viewport-md-min) {
      max-width: 80%;
      flex-direction: row;
      text-align: left;
    }

    > span {
      display: block;
      padding-bottom: 32px;
      font-weight: 500;
      font-size: 0.875rem;
      text-transform: uppercase;
      text-align: center;

      @media (--viewport-md-min) {
        padding-bottom: 20px;
        font-size: 1.125rem;
        text-align: left;
      }
    }

    > b {
      display: block;
      text-align: left;
      font-size: 1.25rem;
      font-weight: 500;
      font-family: var(--heading-font-family);

      @media (--viewport-md-min) {
        margin-bottom: 32px;
      }
    }
  }

  &__icon {
    margin-top: 40px;

    svg {
      transition: none;
      width: auto;
      max-width: 42px;
      height: 20px;
      max-height: 20px;

      /* Leaving this for further reference, in case an icon needs to be recoloured without the ability to set color directly. */
      /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
      /* filter: brightness(0) saturate(100%) invert(7%) sepia(83%) saturate(4700%) hue-rotate(223deg) brightness(91%) contrast(101%); */

      /* This icon can be coloured using stroke */
      stroke: currentColor;
    }

    &--previous svg {
      transform: rotate(180deg);
    }

    &--next svg {
      transform: rotate(0deg);
    }

    @media (--viewport-sm-max) {
      order: 2;
    }

    @media (--viewport-md-min) {
      margin-top: 24px;
    }
  }
}
