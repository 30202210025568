
.blog-post-card {
    display: flex;
    flex: 1;
    position: relative;
    height: 100%;
    /* subtract the amount of pixels that should be the distance between the card items */
    width: calc(100% - 24px);
    overflow: hidden;
    text-decoration: none;
    color: inherit;

    box-shadow: var(--blog-post-card-shadow); 
    border-radius: var(--blog-post-card-border-radius);
    background: var(--color-blog-post-card-background);
  
    &:hover {
        color: inherit;
    }

    @media (--viewport-md-min) {
      width: 100%;
    }
  
    &-content {
      height: 100%;
      width: 100%;
      font-weight: 500;
      position: relative;

      &__media {

      }

      &__main {
        padding: var(--blog-post-card-content-padding--mobile);

        @media (--viewport-md-min) {
          padding: var(--blog-post-card-content-padding);
        }
      }

      &__image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          vertical-align: bottom;

          @media (--viewport-lg-min) {
              height: 360px;
          }

          @media (--viewport-xl-min) {
              height: 440px;
          }
      }
  
      &__tag {
        display: inline-flex;
        transform: none;
        color: var(--color-blog-post-card-tag);
        font-weight: inherit;
        text-transform: uppercase;
        font-size: 0.875rem; /** 14px **/
        line-height: 1.0625rem; /* 17px */
        padding: 12px 0px;
      }
  
      &__date {
        float: right;
        text-transform: uppercase;
        font-size: 0.875rem; /** 14px **/
        padding: 12px 0px;
        letter-spacing: 0.05em;
        margin: 0;
      }
  
      &__title {
        border-top: 1px solid #012169;
        padding-top: 20px;
        padding-bottom: 16px;
        font-size: 1.25rem;
        font-weight: 700;
        font-family: var(--heading-font-family);
      }
  
      &__description {
        font-family: var(--base-font-family);
        margin-bottom: 24px;
        font-size: 1rem;
        line-height: 1.3;
        font-weight: 400;
  
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
  
      &__text {
        padding-left: 16px;
        font-weight: inherit;
        font-size: 0.875rem;
      }
  
       &__cta {
          display: inline-flex;
          align-items: center;
          font-family: var(--base-font-family);
          color: var(--blog-post-card-cta-color);
          font-weight: 600;
          font-size: 0.875rem; /** 14px **/
          text-transform: uppercase;
          letter-spacing: 2px;
          padding-bottom: 5px;

          .icon {
            stroke: currentColor;
          }

          .icon:last-child {
            margin-left: 16px;
          }

          .icon:first-child {
            display: none; /* enabled by theme */
            transform: rotate(-90deg);
            width: 16px;
            min-width: 16px;
            height: 16px;
            min-height: 16px;
            margin-left: 0;
            margin-right: 8px;
            margin-top: -3px;
          }

          span {
            display: inline-block;
          }
  
          &:hover span {
            border-bottom: 1px solid var(--color-highlight);
          }
        }
    }
  }
  