.story-transition-arrow {
  display: flex;
  justify-content: center;

  &-content {
    display: flex;
    align-items: center;
    flex-flow: column;
    position: absolute;
    z-index: 3;

    &__line {
      height: 0;
      width: 2px;
      background-color: var(--color-story-transition-arrow-line);
    }

    &__arrow {
      transform: translateY(-15px);
    }
  }
}
