.maili-treatment-planner-wrapper {
  min-height: calc(100vh - 100px); /* 100px = ISI height */

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s var(--transition);
  }
  /* .fade-leave-active below version 2.1.8 */
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  /* Override heading font family to base font. Client requested via SIPDEV-2785 */
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--base-font-family);
  }
}

.treatment-planner {
  min-height: calc(100vh - 100px); /* 100px = ISI height */
}

.questionnaire-content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 100px); /* 100px = ISI height */

  @media (--viewport-lg-min) {
    justify-content: center;
  }

  &-image {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100vh;
    /* Mobile viewport bug fix */
    height: -webkit-fill-available;
    z-index: -1;

    &--desktop {
      display: none;

      @media (--viewport-lg-min) {
        display: block;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        filter: brightness(0.8);
      }
    }

    &--mobile {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      filter: brightness(0.8);

      @media (--viewport-lg-min) {
        display: none;
      }
    }
  }

  &-inner {
    position: relative;
    max-width: 600px;
    text-align: center;
    color: white;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (--viewport-md-min) {
      margin-bottom: 140px;
    }

    @media (--viewport-lg-min) {
      justify-content: center;
      margin-bottom: 200px;
    }

    &__title {
      position: relative;
      margin-bottom: 32px;

      @media (--viewport-lg-min) {
        margin-bottom: 64px;
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &__description {
      font-weight: bold;
      margin-bottom: 32px;
      position: relative;

      @media (--viewport-lg-min) {
        margin-bottom: 64px;
        font-size: 1.3125rem;
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &__disclaimer {
      position: relative;
      margin-bottom: 32px;

      @media (--viewport-lg-min) {
        margin-bottom: 64px;
      }

      span {
        position: relative;
        z-index: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: left;
      }

      @media (--viewport-lg-min) {
        text-align: center;
      }

      svg {
        margin-right: 8px;
        min-width: 17px;
        min-height: 17px;
      }
    }

    &__content {
      margin-bottom: 32px;

      @media (--viewport-lg-min) {
        margin-bottom: 64px;
      }
    }

    &__navigation {
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media (--viewport-lg-min) {
        flex-direction: row;
      }

      .button {
        background: color(var(--color-midnight-blue) a(0.64));

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:hover:disabled {
          background: color(var(--color-midnight-blue) a(0.64));
        }

        &.button--secondary {
          color: white;
          background: none;
          align-items: center;
          order: 2;

          @media (--viewport-lg-min) {
            order: 0;

            &:hover {
              background: color(var(--color-midnight-blue) a(0.64));
            }
          }

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.questionnaire-progress {
  position: relative;
  width: 225px;
  height: 18px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (--viewport-md-min) {
    margin-top: 72px;
    margin-bottom: 80px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--color-sand);
  }

  &-dot {
    position: relative;
    z-index: 1;
    top: -5px;

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: var(--color-sand);
      border-radius: 50%;
    }

    &__label {
      position: absolute;
      white-space: nowrap;
      transform: translateX(calc(-50% + 6px));
      top: 16px;
      font-weight: bold;
      opacity: 0;
      transition: opacity 0.2s var(--transition);
      color: white;
    }

    &--active {
      top: -8px;

      &::before {
        background: var(--color-dark-blue);
        width: 18px;
        height: 18px;
      }

      .questionnaire-progress-dot__label {
        opacity: 1;
        transform: translateX(calc(-50% + 9px));
      }
    }
  }
}

.questionnaire-selection {
  .button {
    margin-bottom: 6px;
    width: 100%;
    max-width: 320px;

    @media (--viewport-md-min) {
      margin-bottom: 24px;
    }
  }
}

.button.button--questionnaire {
  color: var(--color-text);
  background: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
  transition: all 0.2s var(--transition);

  &.button--active {
    color: var(--color-text);
    background: var(--color-lavender);
  }

  /* hover but not button--active */
  &:hover:not(.button--active) {
    background: rgba(255, 255, 255, 0.9);
  }

  @media (--viewport-lg-min) {
    &:hover {
      background: var(--color-lavender);
    }
  }

  &.button--secondary {
    color: white;
    background: none;

    @media (--viewport-lg-min) {
      &:hover {
        background: color(var(--color-midnight-blue) a(0.64));
      }
    }
  }
}

.questionnaire-slider {
  position: relative;
  width: 300px;
  height: 18px;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  left: 20px;

  @media (--viewport-ms-min) {
    width: 400px;
  }

  @media (--viewport-sm-min) {
    width: 600px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 8px;
    background: var(--color-sand);
  }

  &-dot {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: -16px;
    left: -20px;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 20%;
      left: 20%;
      width: 24px;
      height: 24px;
      background: var(--color-sand);
      border-radius: 50%;
      transition: all 0.2s var(--transition);
      box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.16);
      -webkit-transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
    }

    &:hover:not(.questionnaire-slider-dot--active) {
      &::before {
        transform: scale(1.2);
      }
      .questionnaire-slider-dot__label {
        opacity: 1;
      }
    }

    &__label {
      position: absolute;
      white-space: nowrap;
      transform: translateX(-50%);
      top: 100%;
      font-weight: bold;
      font-size: 1.125rem;
      opacity: 0;
      transition: opacity 0.2s var(--transition), font-size 0.2s var(--transition);
      color: white;
    }

    &--active {
      &::before {
        top: 0;
        left: 0;
        background: var(--color-dark-blue);
        width: 40px;
        height: 40px;
      }

      .questionnaire-slider-dot__label {
        opacity: 1;
        font-size: 1.3125rem;
      }
    }
  }
}

.mtp-results {
  &-header {
    color: var(--color-white);
    background: var(--color-midnight-blue);

    &-mobile {
      position: relative;
      height: 408px;
      clip-path: inset(0);
      left: -20px;
      width: calc(100% + 40px);
      padding: 0 20px;

      @media (--viewport-lg-min) {
        display: none;
      }

      .mtp-results-header__logo,
      .mtp-results-header__title {
        display: block;
      }

      &__inner {
        position: relative;
        z-index: 1;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    &__logo {
      width: 217px;
      height: 36px;
      margin-bottom: 56px;
      display: none;

      @media (--viewport-lg-min) {
        margin-top: 40px;
        display: block;
      }
    }

    &__title {
      font-size: 1.875rem;
      line-height: 2.1875rem;
      margin-bottom: 32px;
      max-width: 80%;
      display: none;

      @media (--viewport-lg-min) {
        font-size: 3rem;
        line-height: 2.8125rem;
        max-width: 65%;
        display: block;
      }
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;

      .button {
        @media (--viewport-md-max) {
          display: flex;
          width: fit-content;
          margin: auto;
        }
      }
    }

    &-treatment-areas {
      transition: background 0.2s var(--transition);

      @media (--viewport-md-max) {
        & {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 8px;
          padding: 20px 0;

          &--fixed {
            background: var(--color-midnight-blue);
            padding: 20px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 100;
            max-width: 100%;
          }
        }
      }

      @media (--viewport-lg-min) {
        max-width: 500px;
        height: 34px;
        margin-bottom: 24px;

        &--fixed {
          background: var(--color-midnight-blue);
          padding: 10px var(--container-spacing);
          height: 54px;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 100;
          max-width: var(--container-width);

          .mtp-results-header-treatment-areas__arrow {
            display: none;
          }
        }
      }

      &__arrow {
        display: none;

        @media (--viewport-lg-min) {
          display: block;
          position: absolute;
          z-index: 2;
          height: 34px;
          width: 60px;
          display: flex;
          align-items: center;
          user-select: none;

          &--left {
            left: 0;
            background: linear-gradient(to left, transparent, #0f1f2b);
          }

          &--right {
            right: 0;
            justify-content: flex-end;
            background: linear-gradient(to right, transparent, #0f1f2b);
          }

          &--disabled {
            opacity: 0;
            pointer-events: none;
          }
        }
      }

      &-item {
        padding: 5px 0;
        height: 34px;
        background: color(var(--color-dark-blue) a(0.4));
        text-align: center;
        cursor: pointer;
        transition: background 0.2s var(--transition);

        @media (--viewport-lg-min) {
          width: 150px;
          margin-right: 4px;

          &:hover {
            background: color(var(--color-dark-blue) a(0.5));
          }
        }

        &__label {
          text-transform: uppercase;
          user-select: none;
        }

        &--active {
          background: color(var(--color-dark-blue) a(0.8));

          .mtp-results-header-treatment-areas-item__label {
            font-weight: bold;
          }
        }
      }
    }

    &-treatment-area {
      @media (--viewport-lg-min) {
        max-width: 500px;
      }

      &__title {
        font-size: 1.875rem;
        font-style: italic;

        strong {
          font-style: normal;
        }
      }

      &__text {
        margin-bottom: 32px;
      }

      &-video {
        position: relative;
        cursor: pointer;
        margin-bottom: 32px;

        @media (--viewport-lg-min) {
          width: 403px;
        }

        &:hover {
          .mtp-results-header-treatment-area-video__icon {
            opacity: 1;
          }
        }

        &__image {
          width: 100%;

          &--hidden {
            display: none;
          }
        }

        &__iframe-wrapper {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;

          &--hidden {
            display: none;
          }

          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }

        &__icon {
          position: absolute;
          z-index: 1;
          opacity: 0.5;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: opacity 0.2s var(--transition);

          &--hidden {
            display: none;
          }
        }
      }
    }

    &__image-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      display: none;

      @media (--viewport-lg-min) {
        display: block;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;

      &--desktop {
        display: none;

        @media (--viewport-lg-min) {
          height: 100%;
          display: block;
          width: calc(100% - 20px);
        }
      }

      &--mobile {
        left: -20px;
        width: calc(100% + 40px);
        height: 100vh;

        @media (--viewport-sm-min) {
          display: none;
        }
      }

      &--tablet {
        display: none;

        @media (--viewport-sm-min) {
          left: -20px;
          width: calc(100% + 40px);
          height: 100vh;
          max-height: 408px;
          display: block;
        }

        @media (--viewport-lg-min) {
          display: none;
        }
      }
    }
  }

  &-products {
    background: var(--color-midnight-blue);
    color: var(--color-white);
    padding: 36px 0;

    @media (--viewport-lg-min) {
      padding: 72px 0;
    }

    &__title {
      @media (--viewport-lg-min) {
        font-size: 3rem;
        margin-bottom: 32px;
      }

      &:after {
        display: none;
      }
    }

    &-selector {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
      margin-bottom: 24px;

      @media (--viewport-lg-min) {
        & {
          margin-bottom: 56px;
          border-radius: 4px;
          padding: 16px;
          background: color(var(--color-dark-blue) a(0.32));
          grid-gap: 16px;
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &-item {
        background: color(var(--color-dark-blue) a(0.64));
        text-align: center;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        cursor: pointer;

        &__label {
          font-size: 1.125rem;
          text-transform: uppercase;
        }

        &--active {
          background: var(--color-dark-blue);

          .mtp-results-products-selector-item__label {
            font-weight: bold;
          }
        }

        &:hover:not(.mtp-results-products-selector-item--active) {
          background: color(var(--color-dark-blue) a(0.48));
        }
      }
    }

    &-current {
      @media (--viewport-lg-min) {
        padding: 144px 0;
        background: var(--color-dark-blue);

        &__wrapper {
          display: flex;
          width: 80%;
          margin: 0 auto;
          justify-content: space-between;
        }
      }

      &__title {
        display: block;
        font-size: 1.875rem;
        margin-bottom: 24px;
      }

      &__text {
        font-size: 1.125rem;
        line-height: 1.75rem;

        @media (--viewport-lg-min) {
          max-width: 360px;
        }
      }

      &__image {
        width: 100%;

        @media (--viewport-lg-min) {
          width: 50%;
          min-width: 50%;
        }
      }
    }
  }

  &-before-afters {
    background: var(--color-midnight-blue);
    color: var(--color-white);
    padding: 36px 0;

    @media (--viewport-lg-min) {
      padding: 72px 0;
    }

    &__title {
      font-size: 1.875rem;

      @media (--viewport-lg-min) {
        font-size: 3rem;
      }

      &:after {
        display: none;
      }
    }

    &__cta.button {
      display: flex;
      width: fit-content;
      margin: auto;
    }

    &-list {
      margin-bottom: 40px;

      @media (--viewport-lg-min) {
        & {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(calc((100% - 80px) / 3), 0fr));
          justify-content: center;
          grid-gap: 40px;
        }
      }

      &-item {
        &__figure {
          position: relative;
          width: 100%;
          user-select: none;
          margin-top: 1rem;
          position: relative;
        }

        &__labels {
          position: relative;
        }

        &__label-before,
        &__label-after {
          position: absolute;
          z-index: 2;
          top: -1rem; /* line-height equivalent */
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;

          @media (--viewport-sm-min) {
            top: 0;
          }

          &:after {
            display: block;
            position: absolute;
            color: var(--color-before-after-label);
            font-weight: 700;
            top: calc(100% - 24px);
            font-size: 0.875rem; /* 14px */
            text-shadow: var(--color-before-after-label-shadow);

            @media (--viewport-ms-min) {
              font-size: 1rem; /* 16px */
            }

            @media (--viewport-sm-min) {
              transform: translate3d(0, -50%, 0);
            }
          }
        }

        &__label-before {
          &:after {
            content: attr(data-label-before);
            left: 24px;
          }
        }

        &__label-after {
          &:after {
            content: attr(data-label-after);
            right: 24px;
          }
        }

        &-wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          overflow: hidden;
          width: 100%;
          z-index: 1;
        }

        &__before,
        &__after {
          display: block;
          pointer-events: none;
          user-select: none;
          margin: 0;
        }

        &__after {
          width: 100%;
        }

        &__before {
          max-width: none;
          height: 100%;
        }

        &-handle {
          cursor: ew-resize;
          z-index: 2;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          position: absolute;
          width: 56px;
          left: 164px;
          height: 100%;
          top: 0;

          &-knob {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 56px;
            height: 56px;

            &__left,
            &__right {
              position: absolute;
              left: 50%;
              top: 50%;
              display: block;
              line-height: 0;
              height: 6px;
              width: 6px;

              svg {
                min-height: 6px;
                min-width: 6px;
                height: 6px;
                width: 6px;
                vertical-align: middle;
                fill: var(--color-before-after-slider-handle);
              }

              @media (--viewport-md-min) {
                height: 12px;
                width: 12px;

                svg {
                  min-height: 12px;
                  min-width: 12px;
                  height: 12px;
                  width: 12px;
                }
              }
            }

            &__left {
              transform: translate3d(calc(-50% - 16px), -50%, 0);
            }
            &__right {
              transform: translate3d(calc(-50% + 16px), -50%, 0);
              svg {
                transform: rotate(180deg);
              }
            }
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            width: 2px;
            height: 100%;
            background: var(--color-before-after-slider-handle);

            @media (--viewport-md-min) {
              width: 6px;
            }
          }
        }

        &__content {
          margin-top: 16px;
          text-align: center;
          text-transform: uppercase;
        }

        &__title {
          font-size: 1.125rem;
          font-weight: bold;
        }

        &__text {
          font-size: 1rem;
        }
      }
    }
  }

  &-testimonial {
    background: var(--color-dark-blue);
    color: var(--color-white);
    padding: 36px 0;

    @media (--viewport-lg-min) {
      padding: 72px 0;
    }

    &__inner {
      @media (--viewport-lg-min) {
        display: flex;
        width: 80%;
        margin: 0 auto;
      }
    }

    &-video {
      position: relative;
      cursor: pointer;
      margin-bottom: 32px;

      @media (--viewport-lg-min) {
        width: 55%;
      }

      &:hover {
        .mtp-results-testimonial-video__icon {
          opacity: 1;
        }
      }

      &__image {
        width: 100%;

        &--hidden {
          display: none;
        }
      }

      &__iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        width: 100%;

        &--hidden {
          display: none;
        }

        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }

      &__icon {
        position: absolute;
        z-index: 1;
        opacity: 0.5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s var(--transition);

        &--hidden {
          display: none;
        }
      }
    }

    &__content {
      .mtp-results-testimonial__title {
        display: none;
      }

      @media (--viewport-lg-min) {
        padding-left: 120px;
        width: 45%;

        .mtp-results-testimonial__title {
          display: block;
        }
      }
    }

    &__title {
      @media (--viewport-lg-min) {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    &__cta.button {
      @media (--viewport-md-max) {
        display: flex;
        width: fit-content;
        margin: auto;
      }
    }
  }

  &-whychoose {
    background: var(--color-midnight-blue);
    color: var(--color-white);
    padding: 36px 0;

    @media (--viewport-lg-min) {
      padding: 72px 0;
    }

    &__title {
      @media (--viewport-lg-min) {
        font-size: 3rem;
        margin-bottom: 32px;
      }

      &:after {
        display: none;
      }
    }

    &-list {
      margin-bottom: 40px;

      @media (--viewport-lg-min) {
        & {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 40px;
        }
      }

      &-item {
        &__image {
          margin-bottom: 48px;
          width: 100%;
        }

        &__title {
          text-transform: uppercase;
          font-size: 1.3125rem;
          font-weight: bold;
          margin-bottom: 16px;
        }
      }
    }
  }

  &-faq {
    background: var(--color-midnight-blue);
    color: var(--color-white);
    padding: 36px 0;

    @media (--viewport-lg-min) {
      padding: 72px 0;
    }

    &__inner {
      @media (--viewport-lg-min) {
        width: 80%;
        margin: 0 auto;
      }
    }

    &__title {
      @media (--viewport-lg-min) {
        font-size: 2.25rem;
        margin-bottom: 32px;
      }
    }

    &-list {
      &-item {
        &__heading {
          font-size: 1.3125rem;
          position: relative;
          padding: 16px 0;
          cursor: pointer;
          border-bottom: 1px solid var(--color-gold);
          user-select: none;

          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: 18px;
            height: 2px;
            border-radius: 2px;
            border-bottom: 2px solid var(--color-gold);
            transition: transform 0.2s var(--transition), opacity 0.2s var(--transition);
          }

          &:before {
            transform: translate3d(0, -50%, 0) rotate(90deg);
            opacity: 1;
          }

          &:after {
            transform: translate3d(0, -50%, 0);
          }
        }

        &__content {
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.2s var(--transition);
        }

        &__text {
          padding: 16px 0;
        }

        &--active {
          .mtp-results-faq-list-item {
            &__heading {
              &:before {
                transform: translate3d(0, -50%, 0) rotate(90deg) scale(0);
                opacity: 0;
              }
            }
            &__content {
              max-height: 1000px;
            }
          }
        }
      }
    }
  }

  &-reset {
    background: var(--color-dark-blue);
    color: var(--color-white);
    padding: 36px 0;
    text-align: center;

    @media (--viewport-lg-min) {
      padding: 72px 0;
    }

    .button--secondary {
      svg {
        margin-right: 8px;
      }
    }
  }

  &-email-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, var(--color-dark-blue) 0%, var(--color-midnight-blue) 100%);
    color: var(--color-white);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s var(--transition), visibility 0.2s var(--transition);

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    .checkbox {
      
      a {
        color: white;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      padding: 32px;

      @media (--viewport-lg-min) {
        padding: 72px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      color: var(--color-dark-blue);
      font-size: 1.5rem;
    }

    &__title {
      margin-bottom: 16px;
    }

    &__text {
      margin-bottom: 32px;
    }

    &-form {
      max-width: 620px;

      &__success {
        display: block;
        font-size: 18px;
        margin-bottom: 16px;
        font-weight: bold;
      }

      &__error {
        display: block;
        margin-bottom: 16px;
        font-weight: bold;
        color: var(--color-danger);
      }

      &__input {
        border-radius: 28px;
        background: var(--color-white);
        padding: 16px;
        margin-bottom: 16px;

        &:hover,
        &:focus {
          border-color: var(--color-white);
        }
      }

      &__submit.button {
        max-width: 100%;
        width: 100%;
        min-height: 58px;
        border-radius: 28px;
        margin-bottom: 32px;
      }

      .button--secondary {
        color: white;
        background: none;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media (--viewport-lg-min) {
          &:hover {
            background: color(var(--color-midnight-blue) a(0.64));
          }
        }

        svg {
          margin-right: 8px;
        }
      }

      &-clinic-finder {
        &-search {
          max-width: 620px;
          margin: 64px auto;
          position: relative;

          &__text {
            display: block;
            margin-bottom: 16px;
            font-size: 1.3125rem;
          }

          &__input {
            border-radius: 28px;
            background: var(--color-white);
            padding: 16px;

            &:hover,
            &:focus {
              border-color: var(--color-white);
            }
          }

          &__submit {
            border: 0;
            padding: 0;
            margin: 0;
            position: absolute;
            bottom: 7px;
            right: 8px;
            color: var(--color-white);
            background-color: var(--color-dark-blue);
            width: 44px;
            height: 44px;
            border-radius: 100%;
            cursor: pointer;

            .icon {
              position: relative;
              top: 2px;
              transform: rotate(0deg);
              width: 24px;
              height: 24px;
              fill: currentColor;
            }
          }
        }
      }
    }
  }
}
