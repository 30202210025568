/*****************************
* main layout location finder
******************************/
@import 'location-finder-dist.css';

:root {
  /** sinclair color overwrites */
  --color-grey: #999999;
}

body.location-finder-page {
  background-color: var(--color-lf-body-bg);
}

/* add overlay to google map when filters are open */
.location-finder--show-filters .map-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #000000;
  opacity: 0.25;
  z-index: 1;
}

.location-finder .location-finder__filters-wrapper {
  z-index: 301;
}

.location-finder .location-search__filters {
  font-size: 16px;
}

.location-finder {
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 83px);
  /* add distance to header, so header is not on top of location finder */
  margin-top: 83px;

  @media (--viewport-md-min) {
    height: calc(100vh - 128px);
    margin-top: 128px;
    flex-direction: row;
  }

  &-page {
    /* hide isi when user is on location-finder page */
    & .isi {
      display: none;
    }

    /* hide footer when user is on location-finder page */
    & .footer {
      display: none;
    }

    & .header {
      /* add default background shadow to header */
      background: rgba(0, 0, 0, 0.5);

      @media (--viewport-sm-max) {
        height: 83px;

        &__inner {
          padding-top: 12px;
          padding-bottom: 8px;
        }
      }
    }
  }

  &__search-wrapper {
    box-shadow: 20px 0 16px -20px rgba(0, 0, 0, 0.2);
    z-index: 1;
    height: auto;
    max-height: 100%;

    @media (--viewport-md-min) {
      width: 50vw;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }

  &__list-wrapper {
    overflow: auto;
  }

  &__map-wrapper {
    flex-shrink: 1;
    flex-grow: 1;

    @media (--viewport-sm-max) {
      width: 100vw;
      height: 100%;
      position: relative;
    }

    img.location-finder-map-marker {
      border: 3px solid white;
      border-radius: 50%;
      &.active {
        border-width: 5px;
      }
    }
  }

  /** details */
  &__details-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 110;
    will-change: transform;
    pointer-events: none;

    .location-details {
      transform: translateX(20%);
      transition: transform 0.3s, opacity 0.3s;
      opacity: 0;
    }

    @media (--viewport-md-min) {
      z-index: 3;
      position: relative;
      overflow: visible;

      .location-details {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        box-shadow: -20px 0 16px -20px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &--show-details {
    .location-finder__details-wrapper .location-details {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }

    .location-finder__cover::after {
      background: rgba(0, 0, 0, 0.2);
      pointer-events: all;
    }
  }

  /** filters */
  &__filters-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 301;
    will-change: transform;
    pointer-events: none;
    order: -1;

    @media (--viewport-sm-max) {
      width: 100vw;
    }

    .locations-filter {
      transform: translateX(-20%);
      transition: transform 0.3s, opacity 0.3s;
      opacity: 0;
      width: 50vw;
      background-color: var(--color-lf-filter-bg);

      @media (--viewport-sm-max) {
        width: 100vw;
      }
    }

    @media (--viewport-md-min) {
      z-index: 3;
      position: relative;
      overflow: visible;

      .locations-filter {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        box-shadow: -20px 0 16px -20px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &--show-filters {
    .location-finder__filters-wrapper .locations-filter {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }
  }

  &__cover {
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      background: rgba(0, 0, 0, 0);
      transition: background 0.2s;
    }
  }

  &:not(&--mobile-show-map) {
    .location-finder__map-wrapper {
      @media (--viewport-sm-max) {
        visibility: hidden;
        height: 0;
        flex-grow: 0;
        overflow: hidden;
      }
    }
  }

  &--mobile-show-map {
    .location-finder__list-wrapper {
      @media (--viewport-sm-max) {
        display: none;
      }
    }
  }

  /*
  * Custom styled scrollbar of location finder
  */
  & ::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  & ::-webkit-scrollbar-track {
    background: var(--site-background-color); /* color of the tracking area */
    margin-top: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--color-gray); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid var(--site-background-color); /* creates padding around scroll thumb */
  }
}

/*****************************
* location map
******************************/
.lf .locations-map {
  height: 100%;

  &__card-popup {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    /* variable height of card + fix distance to marker*/
    transform: translate(-50%, calc(-100% - 70px));
    cursor: pointer;
  }

  &__card-anchor {
    position: absolute;
    width: 100%;
    z-index: 1000;
    bottom: 8px;
    left: 0;
  }

  /** tooltip tip */
  &__card-popup::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
  }

  &__card {
    height: auto;
    width: 414px;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-white);

    @media (--viewport-sm-max) {
      width: 80vw;
    }
  }

  &__card-image {
    width: 40%;
    height: auto;
    object-fit: cover;

    img {
      height: 100%;
      margin: 0;
      width: 100%;
      object-fit: cover;
    }
  }

  &__card-content {
    width: 60%;
    font-size: 12px;
    color: #999999;
    margin: 16px 0 17px 16px;

    &-title {
      font-family: var(--heading-font-family);
      font-size: 14px;
      font-weight: 900;
      color: #333333;
      text-transform: uppercase;
    }

    &-subtitle {
      margin-bottom: 8px;
    }

    &-location-name {
      margin-bottom: 3px;
    }

    & .cta {
      margin-bottom: 0;

      & .button {
        font-size: 10px;
        font-weight: 700;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-top: 8px;
        text-decoration: none;
        color: var(--color-white);
        border-radius: 10.5px;
        min-width: auto;
        min-height: auto;
        text-transform: uppercase;
      }
    }
  }
}

/*****************************
* location search
******************************/

.lf .location-search {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: var(--color-lf-search-bg);
  padding: 20px 30px 20px 30px;
  width: 100vw;

  @media (--viewport-sm-max) {
    padding: 14px 15px;
  }

  .fade-left-enter-active,
  .fade-left-leave-active {
    transition: all 0.5s var(--transition);
    position: absolute;
    width: 100%;
    left: 0;
  }

  .fade-left-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  .fade-left-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }

  .fade-right-enter-active,
  .fade-right-leave-active {
    transition: all 0.5s var(--transition);
    position: absolute;
    width: 100%;
    left: 0;
  }

  .fade-right-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  .fade-right-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  &__options {
    position: relative;
    min-height: 56px;

    @media (--viewport-sm-max) {
      min-height: 40px;
    }
  }

  &-tabs {
    width: 100%;
    max-width: 600px;

    &__selector {
      background: none;
      border: none;
      display: flex;
      width: 100%;
      align-items: stretch;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;

      @media (--viewport-sm-max) {
        margin-bottom: 10px;
      }
    }

    &__tab {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 8px;
      border: 2px solid var(--color-lf-search-button);
      font-size: 14px;
      letter-spacing: 3px;
      color: var(--color-lf-search-button);
      text-transform: uppercase;
      font-weight: 400;
      width: 50%;
      z-index: 1;
      transition: color 0.2s var(--transition);

      @media (--viewport-sm-max) {
        font-size: 12px;
        letter-spacing: 1px;
        min-height: 36px;
      }

      &:before {
        position: absolute;
        content: '';
        height: 100%;
        background-color: var(--color-lf-search-button);
        transition: width 0.2s var(--transition);
        z-index: -1;
      }

      &--left {
        border-right: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;

        &:before {
          right: 0px;
          border-radius: 6px 0 0 6px;
        }
      }

      &--right {
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        &:before {
          left: 0px;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }

  .selected-left .location-search-tabs__tab {
    &--left {
      color: white;
    }

    &--left:before {
      width: calc(100%+1px);
    }

    &--right:before {
      width: 0;
    }
  }

  .selected-right .location-search-tabs__tab {
    &--right {
      color: white;
    }

    &--left:before {
      width: 0;
    }

    &--right:before {
      width: calc(100%+1px);
    }
  }

  /* there is a default padding-top at the body on mobile, we add padding here
  so the location-search is directly below the header on mobile */
  margin-top: 0px;

  @media (--viewport-md-min) {
    width: 100%;
    margin: 0;
    padding: 40px 40px 16px 40px;
  }

  &__mobile-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    @media (--viewport-ms-max) {
      width: 100%;
      height: 40px;
      margin-top: 16px;
    }

    @media (--viewport-sm-max) {
      width: 100%;
      height: 40px;
      margin-top: 5px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @media (--viewport-sm-max) {
      flex-wrap: wrap;
    }
  }

  &__boxes {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__search-box {
    position: relative;
    width: 100%;
    margin-right: 24px;

    @media (--viewport-sm-max) {
      flex-direction: column;
      width: 100%;
      margin-right: 0;
    }

    &-icon-wrapper {
      position: absolute;
      top: 21px;
      right: 30px;
      width: 40px;
      height: 40px;
      transform: translate(50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;

      @media (--viewport-md-min) {
        top: 30px;
      }

      .icon {
        width: 18px;
        height: 18px;
        fill: var(--color-input-placeholder);
      }
    }

    .input {
      display: block;
      height: 40px;
      width: 100%;
      padding: 0 60px 0 16px;
      font-size: var(--base-font-size);
      line-height: 1;
      color: var(--color-lf-search-accent-color);
      background-color: var(--color-white);

      /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
      background-image: none;
      border: 1px solid #d8d8d8;
      border-radius: 28px;
      vertical-align: bottom;
      appearance: none;

      @media (--viewport-md-min) {
        height: 56px;
      }

      &:focus {
        outline: 0;
        border-color: var(--input-focus-border-color);
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--color-input-placeholder) !important;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--color-input-placeholder);
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--color-input-placeholder);
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--color-input-placeholder);
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 24px;

    @media (--viewport-md-min) {
      margin-bottom: 48px;
      font-size: 32px;
    }
  }

  &__filters {
    color: var(--color-link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: var(--color-link-hover);
    }
  }

  &-info {
    display: none;

    @media (--viewport-md-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-top: 0;
      font-family: var(--font-text-location-finder);
    }

    &__filters {
      display: inline-block;
      padding: 6px 24px;
      border: 2px solid var(--color-secondary);
      border-radius: 28px;
      text-align: center;
      font-size: 14px;
      line-height: 1.5;
      color: var(--color-secondary);
      user-select: none;
      cursor: pointer;
      background: transparent;
    }

    &__mobile-view-selector {
      display: flex;

      @media (--viewport-md-min) {
        display: none;
      }
    }

    &__sort-info {
      color: var(--color-grey);

      @media (--viewport-sm-max) {
        display: none;
      }
    }
  }

  &__autocomplete-items:not(:empty) {
    /* position: absolute; */
    border: 1px solid var(--color-grey-lighter);
    border-radius: 8px;
    z-index: 10999;
    background-color: #fff;
    padding: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

    /* position the autocomplete items to be the same width as the container: */
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
  }

  &__autocomplete-items a {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: var(--color-lf-autocomplete-items);
    font-weight: 700;

    & svg {
      margin-right: 8px;
    }

    &:hover,
    &:focus {
      font-weight: bold;
    }
  }

  /* when hovering an item: */
  &__autocomplete-items a:hover {
    background-color: #e9e9e9;
  }

  /* when navigating through the items using the arrow keys: */
  &__autocomplete-active {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--color-primary) !important;
    color: #fff;
  }
}

/*****************************
* location details
******************************/

.location-details {
  height: 100%;
  width: 100%;
  min-width: 300px;
  background-color: var(--color-white);
  display: flex;
  padding-top: 64px;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  overflow-y: auto;

  @media (--viewport-md-min) {
    width: 410px;
  }

  @media (--viewport-lg-min) {
    width: 540px;
  }

  @media (--viewport-xl-min) {
    width: 600px;
  }

  &__name,
  &__address,
  &__directions {
    padding-left: 32px;
    padding-right: 32px;

    @media (--viewport-sm-min) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  &__name {
    font-weight: var(--heading-font-weight);
    text-align: center;
    margin-bottom: 14px;
  }

  &__address {
    margin-bottom: 16px;
    text-align: center;
    line-height: 1.5;
  }

  &__directions {
    text-align: center;

    a {
      min-width: 70%;
    }
  }

  &__contact {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    color: var(--color-primary);
    vertical-align: middle;

    > * {
      vertical-align: middle;

      &:not(:last-child) {
        margin-right: 24px;
      }

      svg {
        vertical-align: middle;
        margin-right: 8px;
        margin-top: -4px;
      }
    }
  }

  &__image {
    margin-bottom: 24px;

    .image {
      height: 30vh;
    }

    .image-slideshow-slide {
      height: 30vh;
    }
  }

  .image-slideshow-slide::before {
    display: none;
  }

  &__opening-hours-title {
    text-align: center;
    font-size: 24px;
    font-weight: var(--heading-font-weight);
  }

  &__opening-hours {
    display: flex;
    justify-content: center;
    padding: 0 16px;

    ul {
      list-style-type: none;
      margin: 0;
      padding-left: 0;

      li {
        padding: 4px 0;
      }
    }

    table {
      min-width: 220px;
      margin-top: 24px;

      td {
        padding-bottom: 8px;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 300;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .icon--close {
      margin-left: 16px;
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
    }
  }
}

/*****************************
* location filter
******************************/

.locations-filter {
  height: 100%;
  width: 100%;
  min-width: 300px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  overflow-y: auto;

  & ::-webkit-scrollbar-track {
    background: var(--color-lf-filter-bg); /* color of the tracking area */
    margin-top: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: var(--color-lf-filter-scrollbar-thumb); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid var(--color-lf-filter-bg); /* creates padding around scroll thumb */
  }

  @media (--viewport-sm-max) {
    background-color: var(--color-lf-filter-bg);
    & ::-webkit-scrollbar-track {
      background: var(--color-lf-filter-bg); /* color of the tracking area */
      margin-top: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: var(--color-lf-filter-scrollbar-thumb); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid var(--color-lf-filter-bg); /* creates padding around scroll thumb */
    }
  }

  padding: 40px 0 0 40px;

  @media (--viewport-md-min) {
    width: 380px;
  }

  @media (--viewport-lg-min) {
    width: 450px;
  }

  @media (--viewport-xl-min) {
    width: 610px;
  }

  &__content-wrapper {
    height: 60vh;
  }

  &__title {
    margin-bottom: 36px;
    font-size: 32px;
    color: var(--color-lf-filter-title);
    font-weight: 900;
    font-family: var(--heading-font-family);
    text-transform: uppercase;

    @media (--viewport-md-min) {
      margin-bottom: 24px;
      font-size: 32px;
    }
  }

  &__filter-group-wrapper {
    height: 60vh;
    overflow-y: scroll;
    padding-right: 28px;

    @media (--viewport-sm-max) {
      height: 70vh;
    }
  }

  &__filter-group {
    margin-top: 40px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--color-primary);

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__filter-group-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__filter-group-option {
    width: fit-content;
    margin-right: 20px;
    margin-bottom: 26px;
    display: flex;
    height: 21px;

    &-label {
      margin-top: 1px;
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      transform: translateX(-10px);
    }

    &-label {
      margin-left: 10px;
      display: flex;
      align-self: center;
    }

    & input[type='checkbox'] {
      /* hide default checkbox */
      cursor: pointer;
      visibility: hidden;

      /* hide custom svg checkmark when not checked */
      + label > svg {
        visibility: hidden;
        height: 15px;
        width: 15px;
        min-height: 15px;
        min-width: 15px;
        margin-top: 3px;
        fill: var(--color-lf-filter-checkbox);
      }

      /* show custom svg checkmark when checked */
      &:checked + label > svg {
        visibility: visible;
      }

      /* custom checkbox border */
      &:after {
        content: ' ';
        background-color: var(--color-white);
        display: inline-block;
        margin-left: 10px;
        padding-bottom: 5px;
        width: 20px;
        height: 20px;
        visibility: visible;
        border: 1px solid var(--color-lf-filter-checkbox);
        padding-left: 3px;
        border-radius: 5px;
      }
    }
  }

  &__results-btn {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 32px;
    padding-left: 40px;
    padding-right: 32px;
    padding-top: 32px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 0;
    background-color: var(--color-lf-list-bg);

    @media (--viewport-md-min) {
      padding-right: 40px;
      padding-left: 40px;
    }

    & a.cta {
      margin-bottom: 0;
    }

    a.button {
      border-radius: 24px;
      padding-left: 48px;
      padding-right: 48px;
    }

    & :nth-child(1) {
      align-self: center;
      color: var(--color-lf-filter-link);
    }
  }

  &__close {
    position: absolute;
    top: 50px;
    right: 40px;
    font-size: 14px;
    font-weight: 300;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .icon--close {
      margin-left: 16px;
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      fill: var(--color-lf-filter-close-icon);
    }
  }

  &__slider {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__slider-numbers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__slider-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & :nth-child(1) {
      font-weight: 700;
    }

    & :nth-child(2) {
      font-size: 14px;
      color: var(--color-lf-filter-distance);
      font-weight: 400;
    }
  }

  /* Input */
  &__slider > input {
    -webkit-appearance: none;
    position: relative;
    top: 24px;
    display: block;
    margin: 0 0 -36px;
    width: 100%;
    height: 36px;
    background-color: transparent;
    cursor: pointer;
  }

  /* Without Span */
  &__slider > input:last-child {
    position: static;
    margin: 0;
  }

  /* Span */
  &__slider > span {
    display: inline-block;
    margin-bottom: 36px;
  }

  /* Focus */
  &__slider > input:focus {
    outline: none;
  }

  /* Disabled */
  &__slider > input:disabled {
    cursor: default;
    opacity: 0.38;
  }

  /* Webkit | Track */
  &__slider > input::-webkit-slider-runnable-track {
    margin: 17px 0;
    border-radius: 1px;
    width: 100%;
    height: 2px;
    background-color: var(--color-lf-filter-slider);
  }

  /* Webkit | Thumb */
  &__slider > input::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    background-color: var(--color-lf-filter-slider);
    transform: scale(6, 6);
    transition: box-shadow 0.2s;
  }
}

/*****************************
* location list
******************************/
.instalift .locations-list {
  height: 100%;

  &::-webkit-scrollbar-track {
    background: var(--color-lf-list-bg); /* color of the tracking area */
    margin-top: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-lf-list-scrollbar-thumb); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid var(--color-lf-list-bg); /* creates padding around scroll thumb */
  }
}

.locations-list {
  background-color: var(--color-lf-list-bg);
  position: relative;

  height: calc(100vh - 314px);
  padding: 40px;
  margin: 0;
  overflow: auto;

  @media (--viewport-sm-max) {
    width: 100vw;
    height: calc(100vh - 75px - 255px);
  }

  &__no-results-found {
    margin-top: 80px;
    color: var(--color-lf-list-no-results-message);

    :first-child {
      font-weight: 900;
      font-size: 32px;
      font-family: var(--heading-font-family);
      color: var(--color-lf-list-no-results-message);
    }

    p:last-of-type {
      margin-bottom: 35px;
    }

    a {
      color: var(--color-link);
    }
  }
}

.lf .clinic-finder-item {
  width: 100%;
  margin-top: 20px;
  display: flex;
  border-bottom: 1px solid var(--color-lf-list-item-border-bottom);
  margin-bottom: 40px;
  padding-bottom: 40px;
  transition: 0.3s all;

  &:hover {
    background: var(--color-lf-list-item-background-hover, rgba(0, 0, 0, 0.2));
    margin-left: -20px;
    padding-left: 20px;
    padding-top: 20px;
    margin-right: -20px;
    padding-right: 20px;
    width: calc(100% + 40px);
  }

  @media (--viewport-sm-max) {
    flex-direction: column;
    max-height: fit-content;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  &__image {
    margin-right: 24px;
    min-height: 195px;
    max-height: 100%;
    width: 260px;
    min-width: 260px;
    background-color: #f0f0f0;

    @media (--viewport-sm-max) {
      width: 100%;
      min-height: 203px;
      height: 203px;
      margin-right: 0;
      margin-bottom: 16px;
    }

    & .image {
      height: 100%;
      /** overwrite sinclair default*/
      margin: 0;
      width: 100%;
      background-size: cover;
      background-position: center center;

      &:before {
        background: none; /** overwrite sinclair default*/
      }
    }

    .image-slideshow-wrapper {
      height: 100%;
    }

    .image-slideshow {
      height: 100%;
    }

    & .image-slideshow-slide {
      height: 100%;
    }
  }

  .image-slideshow-slide::before {
    display: none;
  }

  &__content {
    width: 60%;
    min-height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    text-decoration: none;

    @media (--viewport-sm-max) {
      width: 100%;
      height: auto;
    }

    &-header {
      display: flex;
      justify-content: space-between;

      @media (--viewport-sm-max) {
        justify-content: center;
      }

      &-badges {
        display: flex;

        div {
          background-color: var(--color-lf-list-item-badges);
          color: var(--color-white);
          border-radius: 10.5px;
          font-weight: 700;
          margin-right: 8px;
          padding: 6px 8px 4px 8px;
          font-size: 10px;
        }
      }

      &-featured {
        font-size: 16px;
        color: var(--color-lf-list-item-featured);
        font-weight: 900;
        text-transform: uppercase;
        font-family: var(--heading-font-family);

        @media (--viewport-sm-max) {
          display: none;
        }
      }
    }

    &-main {
      margin-top: 16px;
      line-height: 1;

      &-title {
        font-size: 18px;
        font-family: var(--heading-font-family);
        font-weight: 900;
        text-transform: uppercase;
        padding-bottom: 2px;
        color: var(--color-text);

        @media (--viewport-sm-max) {
          text-align: center;
          padding-bottom: 3px;
        }
      }

      &-subtitle {
        font-size: 14px;
        color: var(--color-lf-list-item-subtitle);
        text-align: left;

        @media (--viewport-sm-max) {
          text-align: center;
        }
      }

      &-treatments {
        font-size: 14px;
        color: var(--color-lf-list-item-threatments);
        margin-top: 16px;

        span + span {
          &:before {
            content: '·';
          }
        }

        @media (--viewport-sm-max) {
          margin-top: 0;
          display: none;
        }
      }
    }

    &-footer {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;

      @media (--viewport-sm-max) {
        flex-direction: column;
        margin-top: 8px;
      }

      &-links {
        align-self: flex-end;
        display: flex;

        &-open-campaign {
          font-size: 10px;
          font-weight: 700;
          width: 75px;
          height: 21px;
          display: inline-flex;
          text-decoration: none;
          color: var(--color-white);
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          border-radius: 10.5px;
          background: var(--color-lf-list-item-cta);
          margin-left: 16px;
          padding-top: 2px;
        }

        &-read-more {
          color: var(--color-lf-list-item-link);
          text-decoration: underline;
        }

        @media (--viewport-sm-max) {
          order: 1;
          margin-top: 16px;
          color: var(--color-eminence);
          display: flex;
          width: 100%;
          height: auto;
          align-items: center;
          justify-content: center;
          border-radius: 24px;
          font-size: 16px;
          color: var(--color-white);
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          text-decoration: none;
        }
      }

      &-clinic-address {
        text-align: right;
        max-width: 50%;

        @media (--viewport-sm-max) {
          max-width: 100%;
          text-align: center;
        }

        & :nth-child(1) {
          font-size: 16px;
          color: var(--color-lf-list-item-address-title);
        }
        & :nth-child(2) {
          font-size: 14px;
          color: var(--color-lf-list-item-address);
        }
      }
    }
  }
}
