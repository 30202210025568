.news-detail {
  @media (--viewport-md-min) {
    position: relative;
    .social-buttons {
      position: absolute;
      top: 0;
      right: 50px;
      flex-direction: column;
      width: 46px;
      height: 195px;

      &__item {
        height: 50px;
      }
    }
  }
}
