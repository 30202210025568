/*
 * Molecule: Grid element - CTA
 */

.cta {
  position: relative;
  margin-bottom: 48px;
  align-self: flex-start;

  @media (--viewport-md-min) {
    margin-bottom: 64px;
  }

  &--center {
    text-align: center;
    align-self: center;
  }

  &--right {
    text-align: right;
    align-self: flex-end;
  }
}
