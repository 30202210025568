.holding-page {
  padding-top: 56px;

  /* overwrite footer changes from perfectha and lanluma so the structure of the footer on the 
  holding page is the same across all themes */
  &__footer {

    &.footer {
      padding: 32px 0;
    }

    & .footer__wrapper {
      flex-direction: row;
    }

    & .footer__inner {
      align-items: flex-end;
      margin-bottom: 0;
      margin-left: 16px;
    }

    & .logo__image {
      height: 63px;
    }
  }
}
