.card-slider {
  padding-top: 50px;
  padding-bottom: 90px;
  background-color: transparent;

  &.js-card-slider-loading {
    /** Position the cards until flickity loads **/
    .card-slider-content {
      display: flex;
      height: 435px;

      @media (--viewport-md-min) {
        height: 660px;
      }

      &__card {
        flex-shrink: 0;
      }
    }

    .card-slider-controls {
      opacity: 0;
    }
  }

  &-content {
    &:focus:not(:focus-visible) {
      outline: none;
    }

    .flickity-viewport {
      overflow: visible;
    }

    &__card {
      width: 246px;
      margin-right: 20px;

      &--events {
        margin-right: 20px;
      }

      &--instagram {
        width: 251px;
        height: 251px;
        margin-right: 20px;

        img {
          width: 251px;
          height: 251px;
        }
      }

      &--meet-the-team {
        height: auto;
        img {
          max-height: 251px;
        }
      }

      &--news {
        .slider-card-content-footer__description {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }

      /** Styles for when populated with event cards **/
      .event-card-content {
        &-header {
          &-date {
            &__day {
              font-size: 88px;
            }

            &__month {
              top: -50px;
            }
          }

          &-info {
            font-size: 0.875rem; /** 14px **/
            margin-bottom: 6px;
          }
        }

        &-bg-media {
          &__image {
            width: 246px;
            height: 312px;
          }
        }

        &-footer {
          margin-bottom: 20px;

          &__title {
            font-size: 1.125rem; /** 18px **/
            font-weight: 600;
          }
        }
      }
    }
  }

  &-controls {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &__input {
      -webkit-appearance: none;
      pointer-events: none;
      appearance: none;
      width: 100%;
      height: 2px;
      background-color: #d6d6d6;
      outline: none;
      border: 0;
      --slider-width: 50%;

      &::-webkit-slider-thumb {
        pointer-events: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        -webkit-appearance: none;
        appearance: none;
        width: var(--slider-width);
        height: 2px;
        background-color: var(--color-midnight-blue);
        border: 0;
        cursor: pointer;
      }

      &::-ms-thumb {
        pointer-events: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        -webkit-appearance: none;
        appearance: none;
        width: var(--slider-width);
        height: 2px;
        background-color: var(--color-midnight-blue);
        border: 0;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        pointer-events: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        -webkit-appearance: none;
        appearance: none;
        width: var(--slider-width);
        height: 2px;
        background-color: var(--color-midnight-blue);
        border: 0;
        cursor: pointer;
      }
    }
  }

  &-header {
    margin-bottom: 55px;
    word-break: break-word;

    &__header {
      margin-top: 20px;
      display: block;
    }

    &__subtitle {
      margin-top: 36px;
      font-family: var(--base-font-family);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.438;
    }
  }

  @media (--viewport-md-min) {
    &-content {
      .flickity-viewport {
        overflow: hidden;
      }

      &__card {
        width: 303px;

        &--news,
        &--portfolio {
          margin-right: 0px;
          width: 343px;
        }

        &--meet-the-team {
          height: auto;
          img {
            max-height: 251px;
          }
        }

        &--instagram {
          margin-right: 20px;
          width: 304px;
          height: 304px;

          img {
            width: 304px;
            height: 304px;
          }
        }

        .event-card-content {
          &-header {
            &-date {
              &__day {
                font-size: 119px;
              }

              &__month {
                top: -70px;
              }
            }
          }

          &-bg-media {
            &__image {
              width: 100%;
              height: 385px;
            }
          }
        }
      }

      .slider-card-content,
      .event-card-content {
        transition: all 0.2s var(--transition);
        padding: 20px;
        box-sizing: content-box;

        &:hover {
          background-color: white;
          box-shadow: 2px 2px 8px -0px rgba(1, 37, 116, 0.05);
        }
      }

      .event-card-content-bg-media {
        overflow: hidden;
      }

      .slider-card-content-media__image,
      .event-card-content-bg-media__image {
        transition: transform 0.2s var(--transition);
      }

      &--hover {
        .slider-card-content,
        .event-card-content {
          opacity: 0.6;
        }
      }

      .slider-card-content:hover,
      .event-card-content:hover {
        opacity: 1;

        .slider-card-content-media__image,
        .event-card-content-bg-media__image {
          transform: scale(1.15);
        }
      }
    }

    &-header {
      margin-top: 50px;

      &__subtitle {
        width: 80%;
      }
    }
  }
}

.slider-card {
  display: block;
}

.instagram-slider-card {
  img {
    object-fit: cover;
  }
}
