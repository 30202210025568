.blog-post-hero {
  margin-top: calc(var(--header-height) + 24px); /* header height + margin from design */

  @media (--viewport-md-min) {
    margin-top: calc(var(--header-height-desktop) + 80px);
  }

  &-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: var(--container-width);
    margin-left: auto;
    margin-right: auto;

    &-title {
      font-weight: 600;
      font-size: 2rem; /* 32px */
      line-height: 2.5rem; /* 40px */
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 48px;
      max-width: 320px;

      @media (--viewport-sm-min) {
        max-width: 1136px;
      }

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3.6875rem; /* 59px */
        margin-bottom: 48px;
      }

      &:after {
        display: none;
      }
    }

    &-image {
      width: 100%;
      height: 352px;
      object-fit: cover;

      @media (--viewport-md-min) {
        height: 600px;
      }
    }

    &-meta {
      font-weight: 600;
      line-height: 1.25rem; /* 20px */
      letter-spacing: 1.4px;
      color: var(--color-blog-post-hero-meta);
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-blog-post-hero-meta-bottom-border);
      padding-bottom: 32px;
      margin-top: 32px;
    }
  }
}
