/*
 * Molecule: Grid element - FAQ
 */

.faq {
  margin: 40px 0;

  &-list {
    position: relative;

    &-item {
      font-size: 1.3125rem;

      &:not(:last-child) {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0.1;
          border-bottom: 3px solid var(--color-faq-bottom-line);
          border-radius: 3px;
          overflow: hidden;
        }
      }

      &--active {
        .faq-list-item {
          &-header {
            &:before {
              transform: translate3d(0, -50%, 0) rotate(90deg) scale(0);
              opacity: 0;
            }
          }

          &-content {
            margin-top: -20px;

            &__inner {
              padding-bottom: 20px;
            }
          }
        }
      }

      &-header {
        position: relative;
        padding: 16px 24px 16px 0;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 44px;
          right: 1px;
          width: 18px;
          height: 2px;
          border-radius: 2px;
          border-bottom: 2px solid var(--color-faq-icon);
          transition: transform 0.2s var(--transition), opacity 0.2s var(--transition);

          @media (--viewport-sm-min) {
            top: 28px;
          }
        }

        &:before {
          transform: translate3d(0, -50%, 0) rotate(90deg);
          opacity: 1;
        }

        &:after {
          transform: translate3d(0, -50%, 0);
        }

        &__label {
          color: var(--color-faq-bottom-line);
          display: block;
          font-size: 1.3125rem;
          user-select: none;
          cursor: pointer;
        }
      }

      &-content {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.2s var(--transition), margin 0.2s var(--transition);

        &__inner {
          padding: 16px 0;
        }
      }
    }
  }
}
