/*
 * Atom: Textarea
 */

.textarea {
  @extend .input;

  padding: 16px;
  height: 240px;
  line-height: 1.5;
}
