/*
 * Molecule: Logo
 */

.logo {
  display: block;

  &--inline {
    display: inline-block;
  }

  &__image {
    display: block;
    margin: 0 auto;

    /* InstaLift logo */
    height: 42px;

    @media (--viewport-md-min) {
      height: 50px;
    }

    /* Sinclair logo */
    &--sinclair {
      height: 50px;

      @media (--viewport-md-min) {
        height: 63px;
      }
    }
  }
}
