/*
 * Molecule: Grid element - Interactive patient preview
 */

.interactive-patient-preview {
  position: relative;

  &--hero {
    .interactive-patient-preview {
      &-content {
        h1.interactive-patient-preview-content__title {
          margin-top: 20vh;
          font-size: 2.75rem; /* 44px */
          line-height: 2.75rem; /* 44px */

          @media (--viewport-sm-min) {
            font-size: 4rem; /* 64px */
            line-height: 4rem; /* 64px */
          }
        }
      }

      &-overlay {
        top: 55%;

        @media (--viewport-sm-min) {
          top: 70%;
        }
      }
    }
  }

  &--active {
    @media (--viewport-sm-max) {
      z-index: 101;
      touch-action: none;
    }
  }

  &-media {
    position: relative;
    height: 80vh;
    max-height: 750px;
    width: calc(100% + (var(--container-spacing--mobile) * 2));
    margin-left: calc(0 - var(--container-spacing--mobile));
    margin-right: calc(0 - var(--container-spacing--mobile));

    @media (--viewport-md-min) {
      width: calc(100% + (var(--container-spacing) * 2));
      margin-left: calc(0 - var(--container-spacing));
      margin-right: calc(0 - var(--container-spacing));
      height: 90vh;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      background-image: radial-gradient(
        54% 153% at 0 0,
        transparent 0,
        rgba(0, 0, 0, 0.53) 100%
      );
    }

    &__image {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    h1.interactive-patient-preview-content__title {
      color: var(--color-interactive-patient-preview-title);
      margin-top: 48px;
      font-size: 2rem; /* 32px */
      line-height: 1;

      @media (--viewport-md-min) {
        font-size: 3.375rem; /* 56px */
      }
    }

    h2.interactive-patient-preview-content__title {
      color: var(--color-interactive-patient-preview-title);
      margin-top: 48px;
      position: relative;
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 48px;
      left: 0;
      padding-left: var(--container-spacing--mobile);
      padding-right: var(--container-spacing--mobile);

      @media (--viewport-md-min) {
        padding-left: var(--container-spacing);
        padding-right: var(--container-spacing);
      }
    }

    &__caption {
      color: var(--color-interactive-patient-preview-title);
      position: absolute;
      bottom: 20px;
      font-style: italic;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      text-align: center;
      display: block;
      width: 100%;
      font-size: 0.75rem; /* 12px */

      @media (--viewport-md-min) {
        left: 0;
        transform: translate3d(0, 0, 0);
        text-align: left;
      }

      &--right {
        @media (--viewport-md-min) {
          text-align: right;
        }
      }
    }
  }

  &-spots {
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(100% + (var(--container-spacing--mobile) * 2));
    left: calc(0 - var(--container-spacing--mobile));

    @media (--viewport-md-min) {
      width: calc(100% + (var(--container-spacing) * 2));
      left: calc(0 - var(--container-spacing));
    }

    &__item {
      cursor: pointer;
      display: block;
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
      transform-origin: center;

      &:hover {
        &:before {
          animation: none;
        }

        &:after {
          background: var(--color-white);
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background: none;
        border: 2px solid var(--color-interactive-patient-preview-dot);
        border-radius: 100%;
        width: 30px;
        height: 30px;
        animation: pulseMobile 2.5s infinite;
        transition: all 0.3s var(--transition);
        transform-origin: center;

        @media (--viewport-sm-min) {
          animation: pulseDesktop 2.5s infinite;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        left: 15px;
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background: var(--color-interactive-patient-preview-dot);
        border-radius: 100%;
        width: 16px;
        height: 16px;
        transition: all 0.3s var(--transition);
      }

      &--active {
        &:before {
          animation: none;
          background: none;
          transform: scale(1.73);
        }

        &:after {
          top: 22px;
          left: 22px;
          background: var(--color-white);
          transform: scale(1.87) translate3d(-50%, -50%, 0);
        }
      }

      span {
        display: none;
      }
    }
  }

  &-overlay {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 100%;
    padding: 24px;
    background: var(--color-interactive-patient-preview-overlay-background);
    border: 6px solid var(--color-interactive-patient-preview-overlay-border);
    color: var(--color-interactive-patient-preview-overlay-text);

    @media (--viewport-md-min) {
      width: 456px;
    }

    .before-after-slider-item {
      margin-top: 0;
      margin-bottom: 20px;

      &__figure {
        margin-top: 0;
      }

      &-handle {
        top: 0;
        height: 100%;

        &:before {
          background: var(--color-white);
          width: 2px;
        }

        &-knob {
          &__left,
          &__right {
            fill: var(--color-white);
          }

          &__left {
            transform: translate3d(calc(-50% - 12px), -50%, 0);

            svg {
              transform: rotate(180deg);
            }
          }

          &__right {
            transform: translate3d(calc(-50% + 12px), -50%, 0);
            svg {
              transform: rotate(0);
            }
          }
        }
      }
    }

    &__title {
      color: currentColor;
    }

    &__text {
      display: block;
      margin-bottom: 16px;
    }

    &__link {
      color: currentColor;
      display: block;
      font-weight: 700;
      text-decoration: none;
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 0;
      margin: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      background: var(--color-white);
      color: var(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate3d(50%, -50%, 0);

      &:focus,
      &:active {
        outline: none;
      }

      .icon {
        margin: 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
        color: currentColor;
      }
    }

    &-fade-enter-active,
    &-fade-leave-active {
      transition: opacity 0.3s var(--transition);
    }
    /* .fade-leave-active below version 2.1.8 */
    &-fade-enter,
    &-fade-leave-to {
      opacity: 0;
    }
  }
}

@-webkit-keyframes pulseDesktop {
  0% {
    -webkit-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(40%));
  }
  70% {
    -webkit-box-shadow: 0 0 0 56px
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
  100% {
    -webkit-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
}
@keyframes pulseDesktop {
  0% {
    -moz-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(40%));
    box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(40%));
  }
  70% {
    -moz-box-shadow: 0 0 0 56px
      color(var(--color-interactive-patient-preview-pulse) a(0%));
    box-shadow: 0 0 0 56px
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
  100% {
    -moz-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(0%));
    box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
}

@-webkit-keyframes pulseMobile {
  0% {
    -webkit-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(40%));
  }
  70% {
    -webkit-box-shadow: 0 0 0 24px
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
  100% {
    -webkit-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
}
@keyframes pulseMobile {
  0% {
    -moz-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(40%));
    box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(40%));
  }
  70% {
    -moz-box-shadow: 0 0 0 24px
      color(var(--color-interactive-patient-preview-pulse) a(0%));
    box-shadow: 0 0 0 24px
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
  100% {
    -moz-box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(0%));
    box-shadow: 0 0 0 0
      color(var(--color-interactive-patient-preview-pulse) a(0%));
  }
}
