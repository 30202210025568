.content-slider {
  padding-bottom: 50px;

  &__btn {
    height: 12px;
    width: 12px;
    padding: 0;
    background-color: var(--color-content-slider-button);
    border-radius: 100%;
    border: none;
    transition: all 0.3s;

    &:focus {
      outline: none;
    }

    &--is-selected {
      transform: scale(2);
    }
  }

  &__button-row {
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
  }

  &__btn-group {
    display: flex;
    height: 24px;
    background-image: linear-gradient(
      transparent 44%,
      var(--color-content-slider-button-group) 50%,
      transparent 56%
    );
    width: 240px;
    justify-content: space-between;
    align-items: center;
  }

  &__btn-row {
    text-align: center;
  }

  &.flickity-enabled:focus .flickity-viewport {
    outline: none;
  }

  /* overwrites of grid element to enforce correct behavior when in slider */
  & .col-xs-12 {
    width: 100%;
  }
}

.slider-mobile {
  @media (--viewport-md-min) {
    display: flex;
  }
}
