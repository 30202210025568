.skin-assessment-slider {
  @media (--viewport-sm-max) {
    position: absolute;
    top: -20px;
    transform: translateY(-100%);
    /* 52px = 2 * 26px (padding from wrapping box) */
    width: calc(100% - 52px);
  }

  @media (--viewport-md-min) {
    margin-bottom: 118px;
  }

  &-content {
    &-slider {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      border: 3px solid var(--color-visualiser-seek-border);
      border-radius: 40px;
      padding: 4px 12px;
      background-color: rgba(255, 255, 255, 0.55);

      /* Input */
      & > input {
        -webkit-appearance: none;
        position: relative;
        display: block;
        margin: 0 0 -24px;
        width: 100%;
        height: 24px;
        background-color: transparent;
        cursor: pointer;

        @media (--viewport-md-min) {
          margin-bottom: -36px;
          height: 36px;
        }
      }

      /* Without Span */
      & > input:last-child {
        position: static;
        margin: 0;
      }

      /* Span */
      & > span {
        display: inline-block;
        margin-bottom: 24px;
        @media (--viewport-md-min) {
          margin-bottom: 36px;
        }
      }

      /* Focus */
      & > input:focus {
        outline: none;
      }

      /* Disabled */
      & > input:disabled {
        cursor: default;
        opacity: 0.38;
      }

      /* Webkit | Track */
      & > input::-webkit-slider-runnable-track {
        margin: 17px 0;
        border-radius: 1px;
        width: 100%;
        background-color: var(--color-visualiser-track-bg);
        height: 8px;
        border-radius: 16px;
      }

      /* Webkit | Thumb */
      & > input::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        border: none;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        background-color: var(--color-visualiser-thumb);
        transform: scale(5, 5);
        transition: box-shadow 0.2s;
        margin: 1px;

        @media (--viewport-md-min) {
          height: 6px;
          width: 6px;
          transform: scale(6, 6);
        }
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      font-weight: 500;
    }
  }
}
