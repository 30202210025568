.end-screen {
  margin-top: 106px;
  padding-top: 32px;
  padding-bottom: 32px;
  background: linear-gradient(
    216.75deg,
    rgba(255, 239, 228, 0.98) 0%,
    #ffe3ea 46.88%,
    #b0afc4 100%
  );

  @media (--viewport-md-min) {
    padding-top: 120px;
    padding-bottom: 120px;
    min-height: 40vh;
  }

  &-content {
    @extend .container;

    &-title {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1px;
      width: 250px;
      margin-bottom: 32px;

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3rem; /* 48px */
        width: auto;
      }
    }

    &-text {
      margin-bottom: 24px;

      @media (--viewport-md-min) {
        margin-bottom: 48px;

        max-width: 500px;
        font-size: 1.25rem; /* 20px */
        line-height: 2.1875rem; /* 35px */
      }

      &__first {
        margin-bottom: 24px;
      }
    }
  }
}
