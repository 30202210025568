.meet-your-trainer {
  margin-top: 48px;
  margin-bottom: 48px;
  width: 100%;

  &-content {
    &__title {
      font-weight: var(--heading-font-weight);
      font-size: 1.5rem; /* 24px */
      line-height: 1.875rem; /* 30px */
      margin-bottom: 24px;
    }

    &-header {
      margin-bottom: 24px;
      text-align: center;

      @media (--viewport-sm-min) {
        margin-bottom: 0;
        margin-right: 64px;
        text-align: left;
      }

      &__image {
        object-fit: cover;
        border-radius: 100%;
        display: block;
        width: 180px;
        height: 180px;

        @media (--viewport-md-min) {
          width: 280px;
          height: 280px;
        }
      }
    }

    &__inner {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;

      @media (--viewport-sm-min) {
        flex-flow: row;
        text-align: left;
      }
    }

    &-trainer {
      &__name {
        font-weight: var(--heading-font-weight);
        font-size: 1.625rem; /* 26px */
        line-height: 2rem; /* 32px */
      }

      &__title {
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        margin-bottom: 16px;
      }

      &__description {
        line-height: 1.25rem; /* 20px */
      }
    }
  }
}
