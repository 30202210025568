/*
 * Molecule: Grid element - Your Journey
 */

.grid > .container > .row:first-child > div > .your-journey {
  @media (--viewport-md-min) {
    margin-top: -96px;
  }
}

.your-journey {
  margin-bottom: 64px;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    &:first-child { 
      margin-top: 0;
    }

    @media (--viewport-md-min) {
      flex-direction: row;
      max-width: 76.67%; /* 8 col */

      &:nth-child(2n) {
        margin-left: 16.67%; /* 2 col */
      }  
    }
    @media (--viewport-lg-min) {
      max-width: 68.33%; /* 7 col */
    }
    
    &-figure {
      flex-shrink: 0;
      width: 196px;
      height: 196px;
      margin: 0 0 24px 0;
      position: relative;

      @media (--viewport-md-min) {
        align-self: flex-start;
        margin: 0 48px 0 0;
      }
      @media (--viewport-lg-min) {
        margin-right: 64px;
        width: 224px;
        height: 224px;
      }

      &__image, &:before {  
        width: 100%;
        height: 100%;
        border-radius: 500px;
      }
      
      &__image {
        object-fit: cover;
        position: relative;
      }

      &:before {
        content: '';
        background: var(--color-eminence);
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }

    &-content {
      text-align: center;

      @media (--viewport-md-min) {
        text-align: left;
        margin-top: 40px;
      }

      &__title {
        color: var(--color-deepblue);
        font-family: var(--heading-font-family);
        font-size: 1.375rem; /* 22px */
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        line-height: 1.05;
        margin-bottom: 4px;

        & + .your-journey-item-content__body {
          color: var(--color-black);
        }
      }

      &__body {
        color: var(--color-eminence);
      }
    }
  }
}
