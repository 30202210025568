.story-science-layered-slide {
  margin-bottom: 150px;
  position: relative;

  &-content {
    display: flex;
    justify-content: space-around;
    flex-flow: column;

    @media (--viewport-md-min) {
      flex-flow: row nowrap;
    }

    &-description {
      max-width: 536px;

      & h2 {
        color: var(--color-gold);

        &:after {
          margin-bottom: 24px;

          @media (--viewport-md-min) {
            margin-bottom: 120px;
          }
        }
      }

      &__text {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        letter-spacing: 0.1em;

        @media (--viewport-md-min) {
          font-size: 1.0625rem; /* 17px */
          line-height: 1.5625rem; /* 25px */
          letter-spacing: 0.05em;
        }
      }

      & .cta {
        display: none;

        @media (--viewport-md-min) {
          display: block;
          margin-top: 64px;
        }
      }

      &-wrapper-2,
      &-wrapper-3,
      &-wrapper-4,
      &-wrapper-5,
      &-wrapper-6,
      &-wrapper-7 {
        position: absolute;
        top: 136px;
        max-width: 350px;

        @media (--viewport-md-min) {
          top: 271px;
          max-width: 536px;
        }
      }

      & .cta {
        margin-top: 150px;
      }
    }

    &-cta-mobile {
      display: block;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;

      @media (--viewport-md-min) {
        display: none;
      }
    }
  }
}
