.visualiser-checkbox {
  padding-bottom: 24px;

  &--no-padding {
    padding-bottom: 0;
  }

  & .checkbox {
    font-size: 1.25rem; /* 20px */
    letter-spacing: 1px;
    line-height: 1.75rem; /* 28px */

    &__tick {
      border: 1px solid var(--color-visualiser-additional-options-checkbox-border);
      border-radius: 2px;
    }
  }
}
