.hero-sinclair {
  width: 100%;
  margin: 0 auto;
  height: 650px;
  overflow: hidden;
  position: relative;
  color: white;

  &-image-caption {
    color: white;
    font-style: italic;
    position: absolute;
    bottom: 10px;
    display: block;
    left: 30px;
    right: 30px;
    text-align: center;
    font-weight: 300;
  }

  &-fullscreen-media--with-gradient {
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #00000061;
    }
  }

  &-fullscreen-media {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    background-color: black;

    &__image {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &--desktop {
        display: none;
      }

      @media (--viewport-md-min) {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: block;
        }
      }
    }

    &__video {
      position: absolute;
      top: 0;
      left: 50%;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
    }

    &__thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      object-fit: cover;
      pointer-events: none;
      opacity: 1;
      transition: opacity 0.6s;

      &--hidden {
        opacity: 0;
      }
    }
  }

  &-video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 24px;
    background: rgba(0, 0, 0, 0.9);

    @media (--viewport-md-min) {
      padding: 100px;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
      height: 100%;

      iframe,
      #tencent-hero-overlay-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      margin: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      background: var(--color-white);
      color: var(--color-text);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewport-md-min) {
        top: 40px;
        right: 40px;
      }

      .icon {
        margin: 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
      }
    }
  }

  &--simple {
    height: auto;
    padding-top: 214px;
  }

  &-content {
    height: 90%;
    position: absolute;
    bottom: 0;

    /** To account for container padding **/
    left: 30px;
    right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    padding-bottom: 50px;

    &--simple {
      position: relative;
      display: block;
      left: 0;
      right: 0;
      padding-bottom: 0;
    }

    &--center-on-mobile {
      justify-content: center;
      align-items: center;
    }

    &__logo {
      max-width: 215px;
      width: 100%;
      display: block;
      margin-top: 22px;
      margin-bottom: 35px;
    }

    &__play {
      display: inline-block;
      padding: 8px 30px 15px 0;
      cursor: pointer;

      &:hover {
        .hero-sinclair-content__play-text {
          color: #dbdbdb;
          .icon {
            /** #dbdbdb **/
            /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5818%)
              hue-rotate(319deg) brightness(102%);
          }
        }
      }

      &-text {
        font-family: var(--base-font-family);
        font-size: 0.875rem; /** 14px **/
        text-transform: uppercase;
        font-weight: 600;
        margin: 20px 0 40px 0;
        vertical-align: middle;
        display: inline-block;
        letter-spacing: 2px;

        .icon {
          transition: none;
          margin-left: 5px;
          vertical-align: middle;
          display: inline-block;
          width: 18px;
          height: 18px;
          min-width: 0;
          min-height: 0;
          position: relative;
          top: -1px;
        }
      }

      @media (--viewport-md-min) {
        margin: 20px 0 20px 0;
      }
    }

    &__title {
      line-height: 2.5rem;
      font-size: 2rem;
      letter-spacing: 0.8px;
      display: block;
      width: 100%;
      font-weight: 400;
      margin-bottom: 10px;

      &--center-on-mobile {
        max-width: 355px;
        text-align: center;
        display: inline-block;
        font-weight: 300;
      }

      &:last-child {
        margin-bottom: 80px;
      }

      &--simple {
        color: var(--color-midnight-blue);
      }
    }

    &__subtitle {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 34px;
      max-width: 341px;

      &--simple {
        color: var(--color-midnight-blue);
        font-size: 0.875rem; /** 14px **/
      }
    }

    &__cta {
      margin-top: 25px;
      margin-bottom: 25px;

      &--center-on-mobile {
        margin-bottom: 0;
        margin-top: 50px;
      }
    }

    &__date {
      font-size: 0.875rem; /** 14px **/
      text-transform: uppercase;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-bottom: 5px;
    }
  }

  @media (--viewport-md-min) {
    height: 747px;

    &--simple {
      padding-top: 307px;
      height: auto;
    }

    &-image-caption {
      bottom: 60px;
      text-align: right;
      right: 60px;
      font-size: 0.75rem;
    }

    &-fullscreen-media--with-gradient {
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5),
          hsla(0, 0%, 100%, 0) 25%,
          hsla(0, 0%, 100%, 0) 30%,
          rgba(0, 0, 0, 0.6)
        );
      }
    }

    &-content {
      padding-bottom: 0;
      display: block;
      position: absolute;
      bottom: 80px;
      left: 90px;
      right: 90px;
      height: auto;
      text-align: left;

      &--simple {
        position: relative;
        left: 0;
        right: 0;
      }

      &__title {
        max-width: initial;
        font-size: 3rem; /** 48px **/
        max-width: 737px;
        line-height: 4rem;

        &--center-on-mobile {
          max-width: initial;
          text-align: left;
          display: block;
          font-weight: 400;
        }

        &:last-child {
          margin-bottom: 10px;
        }
      }

      &__countdown {
        .countdown-content {
          justify-content: flex-start;
        }
        .countdown-content-timer {
          margin: 0 4px;
          &:first-of-type {
            margin-left: 0;
          }
          @media (--viewport-md-min) {
            height: 95px;
            width: 95px;
          }
        }
      }

      &__subtitle {
        max-width: 500px;
        letter-spacing: 0.67px;

        &--simple {
          font-size: 1rem;
        }
      }

      &__logo {
        margin-bottom: 25px;
      }

      &__cta {
        margin-top: 48px;
        margin-bottom: 0;
      }

      &__date {
        font-size: 1.125rem; /** 18px **/
      }
    }
  }
}
