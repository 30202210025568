/*
 * Molecule: Grid element - Social media banner
 */

.social-media-banner {
  position: relative;

  &-content {
    text-align: left;
    margin: 48px 0 40px;

    @media (--viewport-md-min) {
      margin: 120px 32px 120px 0;
    }

    @media (--viewport-lg-min) {
      margin: 160px 32px 160px 0;
    }

    &__title {
      color: var(--color-white);

      @media (max-width: 379px) {
        font-size: 1.85rem;
        line-height: 1.05;
      }

      @media (--viewport-md-min) {
        font-size: 1.9rem;
        line-height: 1.05;
      }
      @media (--viewport-lg-min){
        font-size: 2.5rem;
      }
      @media (--viewport-xl-min){
        font-size: 3rem;
      }
    }

    &-profiles {
      &__link {
        color: var(--color-scooter);
        text-decoration: none;

        &:focus, &:hover {
          color: var(--color-white);
        }

        .icon {
          width: 42px;
          height: 42px;
          margin-right: 10px;

          @media (--viewport-lg-min) {
            width: 52px;
            height: 52px;
            margin-right: 16px;
          }
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    height: 100%;

    transform: translate3d(-50%, -50%, 0);
    background: var(--color-deepblue);
    z-index: -2;
  }

  &-background {
    max-width: 100%;

    @media (--viewport-md-min) {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: calc(var(--site-max-width) / 2);
      z-index: -1;
      max-width: calc(100vw + 700px);
      text-align: right;
    }
    
    &__image {
      display: block;
      width: calc(100% + var(--container-spacing--mobile));

      @media (--viewport-md-min) {
        display: inline-block;
        position: sticky;
        right: 0;
        width: auto;
        height: 100%;
      }
    }
  }
}
