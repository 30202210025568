.event-signup-hero {
  margin-bottom: 64px;
  width: auto;

  @media (--viewport-md-min) {
    min-height: 700px;
  }

  &-inner {
    text-decoration: none;
    color: var(--color-black);

    @media (--viewport-md-min) {
      display: flex;
      height: auto;
      position: relative;
    }

    &-image {
      height: 216px;
      width: 100%;
      object-fit: cover;

      @media (--viewport-sm-min) {
        height: 480px;
      }

      @media (--viewport-md-min) {
        width: 60%;
        min-width: 922px;
        height: 715px;
        position: absolute;
        right: 0;
      }
    }

    &-content {
      background-color: var(--color-pale-blue);
      padding: 24px;
      margin-top: -8px;

      @media (--viewport-md-min) {
        padding-top: 40px;
        padding-left: 56px;
        padding-right: 56px;
        padding-bottom: 0;
        z-index: 1;
        width: 100%;
        max-width: 640px;
        margin-top: 232px;
      }

      &-title {
        font-size: 2rem; /* 32px */
        line-height: 2.5rem; /* 40px */
        font-weight: var(--heading-font-weight);
        margin-bottom: 24px;

        @media (--viewport-md-min) {
          font-size: 2rem; /* 32px */
          line-height: 2.5rem; /* 40px */
          letter-spacing: -0.5px;
        }
      }

      &-date {
        margin-bottom: 24px;

        @media (--viewport-md-min) {
          margin-bottom: 56px;
        }

        &__headline {
          font-weight: var(--heading-font-weight);
          text-transform: uppercase;
          line-height: 1.875rem; /*30px*/

          @media (--viewport-md-min) {
            font-size: 1.5rem; /* 24px */
          }
        }
        &__info {
          @media (--viewport-md-min) {
            font-size: 1.5625rem; /* 25px */
            line-height: 28px; /* 28px */
          }
        }
      }

      &-location {
        margin-bottom: 24px;

        @media (--viewport-md-min) {
          margin-bottom: 56px;
        }

        &__headline {
          font-weight: var(--heading-font-weight);
          text-transform: uppercase;
          line-height: 1.875rem; /*30px*/

          @media (--viewport-md-min) {
            font-size: 1.5rem; /* 24px */
          }
        }
        &__info {
          @media (--viewport-md-min) {
            font-size: 1.5625rem; /* 25px */
            line-height: 28px; /* 28px */
          }
        }
      }

      &-trainer {
        margin-bottom: 24px;

        @media (--viewport-md-min) {
          margin-bottom: 56px;
        }

        &__headline {
          font-weight: var(--heading-font-weight);
          text-transform: uppercase;
          line-height: 1.875rem; /*30px*/

          @media (--viewport-md-min) {
            font-size: 1.5rem; /* 24px */
          }
        }
        &__info {
          @media (--viewport-md-min) {
            font-size: 1.5625rem; /* 25px */
            line-height: 28px; /* 28px */
          }
        }
      }

      &-price {
        margin-bottom: 24px;

        @media (--viewport-md-min) {
          margin-bottom: 56px;
        }

        &__headline {
          font-weight: var(--heading-font-weight);
          text-transform: uppercase;
          line-height: 1.875rem; /*30px*/

          @media (--viewport-md-min) {
            font-size: 1.5rem; /* 24px */
          }
        }
        &__info {
          @media (--viewport-md-min) {
            font-size: 1.5625rem; /* 25px */
            line-height: 28px; /* 28px */
          }
        }
      }

      & .button {
        width: 100%;

        @media (--viewport-sm-min) {
          width: 50%;
        }

        @media (--viewport-sm-min) {
          width: 100%;
        }
      }
    }
  }
}
