/*
 * Molecule: Grid element - RTE
 */

.animations--enabled {
  .rte {
    opacity: 0;
  }
}

.rte {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  b,
  strong {
    color: var(--color-deepblue);
  }
}

.emphasise {
  color: var(--rte-emphasise-text-color);
  font-weight: var(--rte-emphasise-text-weight);
  text-transform: var(--rte-emphasise-text-transform);
}
