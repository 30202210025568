.content-card {
  text-decoration: none;
  color: var(--color-text);
  display: flex;
  flex: 1;
  min-height: 100%;
  width: 90%;
  margin-left: 10px;
  margin-right: 10px;

  &:hover,
  &:focus {
    color: var(--color-text);
  }

  @media (--viewport-md-min) {
    min-height: auto;
    margin-left: 0;
    margin-right: 0;
    padding: var(--half-gutter-width);
    max-width: 50%;
    min-width: 25%;
  }

  &-inner {
    width: 100%;
    background-color: var(--color-white);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__image {
      width: 100%;
      aspect-ratio: calc(600 / 316);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      padding: 20px 20px 65px;
      position: relative;
      flex: 1;
      flex-grow: 1;

      @media (--viewport-md-min) {
        padding: 20px 40px 65px;
      }

      &__title {
        font-size: 1.375rem; /* 22px */
        line-height: 1.6875rem; /* 27px */

        @media (--viewport-md-min) {
          font-size: 1.5625rem; /* 25px */
        }
      }

      &__text {
        display: block;
        @extend p;
      }

      &__button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        height: 45px;
        width: 45px;
        background-color: var(--color-content-card-button-background);
        color: var(--color-content-card-button-color);
        display: flex;
        justify-content: center;
        align-items: center;

        @media (--viewport-md-min) {
          right: 40px;
        }

        &-rotate {
          display: flex;
          justify-content: center;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
