.image-card {
  height: 400px;
  width: 621px;
  margin-right: 20px;

  @media (--viewport-sm-max) {
    height: 451px;
    width: 339px;

    & figure {
      height: 100%;

      & img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__name {
    transform: translateY(calc(-100% - 32px));
    display: flex;
    justify-content: center;
    color: var(--color-image-card-title);
    font-size: 25px;
    font-weight: var(--heading-font-weight);
    line-height: 1.08;
  }

  &__button-wrapper {
    transform: translateY(calc(-100% - 32px));
    display: flex;
    justify-content: flex-end;
    margin-right: 27px;
    padding-bottom: 28px;
  }

  &__button {
    height: 49px;
    width: 49px;
    background-color: var(--color-image-card-button-background);
    color: var(--color-image-card-button-color);
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
    border-radius: var(--image-card-button-radius);

    .icon {
      fill: currentColor;
    }
  }

  &--hidden {
    display: none;
  }
}
