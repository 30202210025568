.faq-cards {
  position: relative;
  padding-bottom: 25px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-faq-cards-listing-background);
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
  }

  .select__wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__list {
    @media (--viewport-md-min) {
      margin-left: var(--half-gutter-compensation);
      margin-right: var(--half-gutter-compensation);
      display: flex;
      flex-wrap: wrap;
    }

    .flickity-viewport {
      overflow: visible;
    }
  }
}
