.clinic-card {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 16px;

  @media (--viewport-sm-max) {
    height: 100%;
  }

  @media (--viewport-md-min) {
    flex-basis: calc(100% / 2);
  }

  @media (--viewport-lg-min) {
    flex-basis: calc(100% / 3);
  }

  &:hover {
    color: inherit;
  }

  &-content {
    background: var(--color-visualiser-clinic-card-bg);
    padding: 16px 16px 24px;
    border-radius: 20px;
    height: 100%;
    display: flex;
    flex-flow: column;
    width: 196px;

    @media (--viewport-md-min) {
      width: 100%;
      flex-flow: row;
      padding: 24px;
    }

    &-image {
      margin-bottom: 16px;
      display: flex;

      @media (--viewport-md-min) {
        margin-bottom: 0;
        margin-right: 40px;
        align-items: center;
      }

      & img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50px;
        background: var(--color-visualiser-clinic-card-image-bg);

        @media (--viewport-md-min) {
          width: 100px;
          height: 100px;
        }
      }
    }

    &-info {
      &__distance {
        font-family: Helvetica;
        font-size: 0.75rem; /* 12px */
        font-weight: bold;
        letter-spacing: 1.71px;
        line-height: 14px;
        margin-bottom: 8px;

        @media (--viewport-md-min) {
          margin-bottom: 4px;
          font-size: 1rem; /* 16px */
        }
      }

      &__physician {
        font-size: 1rem; /* 16px */

        &:after {
          content: '';
          display: block;
          height: 1px;
          width: 32px;
          background: #b9b9b9;
          margin-top: 8px;
          margin-bottom: 16px;
        }

        @media (--viewport-md-min) {
          font-size: 1.25rem; /* 20px */
        }
      }

      &-address {
        color: #999999;
        font-size: 0.875rem; /* 14px */
        line-height: 21px;
      }
    }
  }
}
