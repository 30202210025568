/*
 * Molecule: Navigation
 */

.navigation {
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    &-item {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding: 5px 12px;
      text-align: center;
      color: var(--color-navigation-link);
      transition: opacity 0.2s var(--transition);

      &:last-child {
        margin-right: 0;
      }

      &--active {
        background: var(--color-navigation-link-active-background);

        &:before {
          content: '';
          background: var(--color-navigation-link-active-background);
          display: block;
          position: absolute;
          top: 100%;
          left: 50%;
          height: 6px;
          width: 6px;
          transform: translate3d(-50%, -50%, 0) rotate(45deg);

          @media (--viewport-md-min) {
            width: 12px;
            height: 12px;
          }
        }
      }

      &__link {
        display: block;
        font-size: 0.75rem; /* 12px */
        font-weight: 700;
        text-decoration: none;
        position: relative;
        color: currentColor;
        transition: color 0.2s var(--transition);
        margin: 0 auto;

        &:hover {
          color: var(--color-navigation-link-hover);
        }
      }
    }
  }

  /* Modifiers */
  &--service {
    display: none;

    @media (--navigation-breakpoint-desktop) {
      display: block;
    }
  }

  &--footer {
    margin-bottom: 12px;
    transform: translateY(0);

    .navigation {
      &-list {
        text-align: center;

        &-item {
          opacity: 1;
          color: var(--color-navigation-footer-link);
          padding: 7px 0;
          margin: 0 12px;

          @media (--viewport-lg-min) {
            padding: 0;
            margin: 0 12px 0 0;
          }

          &__link {
            font-size: 0.875rem; /* 14px */

            &:hover {
              color: var(--color-navigation-footer-link-hover);
            }
          }
        }
      }
    }
  }
}
