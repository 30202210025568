/*
 * Molecule: Grid element - Clinic finder banner
 */

.clinic-finder-banner-perfectha {
  position: relative;

  &-media {
    position: relative;
    height: 80vh;
    max-height: 750px;
    width: calc(100% + (var(--container-spacing--mobile) * 2));
    margin-left: calc(0 - var(--container-spacing--mobile));
    margin-right: calc(0 - var(--container-spacing--mobile));

    @media (--viewport-md-min) {
      width: calc(100% + (var(--container-spacing) * 2));
      margin-left: calc(0 - var(--container-spacing));
      margin-right: calc(0 - var(--container-spacing));
      height: 90vh;
    }

    &__image {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    &__title {
      font-style: italic;
      margin-top: 48px;
      padding-left: 32px;
      max-width: 200px;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-text);
        height: 100%;
        width: 8px;
      }

      @media (--viewport-md-min) {
        max-width: 100%;
      }
    }

    &-form {
      display: flex;
      align-items: center;
      background: var(--color-primary);
      color: var(--color-white);
      position: absolute;
      top: 50%;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
      z-index: 2;
      transform: translate3d(0, -50%, 0);
      left: 0;
      width: calc(100% - var(--container-spacing--mobile));
      padding: 64px 32px;

      @media (--viewport-sm-min) {
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        width: auto;
        padding: 64px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: 6px solid var(--color-primary);
        top: 24px;
        left: 24px;

        @media (--viewport-md-min) {
          top: 32px;
          left: 32px;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-primary);
        z-index: -1;
      }

      &__input {
        margin: 0 24px 0 0;
        padding: 6px 0;
        background: none;
        border-radius: 0;
        color: currentColor;
        min-height: 40px;
        border: 0;
        font-weight: 500;
        border-bottom: 3px solid currentColor;

        @media (--viewport-md-min) {
          width: 400px;
        }

        &::placeholder {
          color: color(var(--color-white) a(60%));
        }
      }

      &__submit {
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        position: relative;
        top: 4px;
        color: currentColor;

        .icon {
          transform: rotate(0deg);
          width: 40px;
          height: 40px;
          fill: currentColor;
        }
      }
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 48px;
      left: 0;
      padding-left: var(--container-spacing--mobile);
      padding-right: var(--container-spacing--mobile);

      @media (--viewport-md-min) {
        padding-left: var(--container-spacing);
        padding-right: var(--container-spacing);
      }
    }
  }
}
