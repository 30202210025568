.event-card {
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
  text-decoration: none;
  /* subtract the amount of pixels that should be the distance between the card items */
  width: calc(100% - 24px);

  @media (--viewport-md-min) {
    width: 100%;
  }

  &-bg-media {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.29) 3.18%,
        rgba(0, 0, 0, 0) 101.93%
      );
      z-index: 1;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.29) 3.18%,
      rgba(0, 0, 0, 0) 101.93%
    );

    &-date {
      height: 56px;
      width: 56px;
      display: initial;
      text-align: center;
      background-color: var(--color-white);
      color: var(--color-blue);
      font-weight: var(--heading-font-weight);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      letter-spacing: 1.07px;
      margin-top: -1px;
      margin-left: -1px;

      @media (--viewport-lg-min) {
        height: 88px;
        width: 88px;
      }

      &__day {
        font-size: 1.5rem; /* 24px */
        line-height: 1.5rem;
        width: fit-content;

        @media (--viewport-lg-min) {
          font-size: 2rem; /* 32px */
          line-height: 2.5rem; /* 40px */
        }
      }

      &__month {
        font-size: 0.875rem; /* 14px */
        line-height: 1rem;
        text-transform: uppercase;
        width: fit-content;

        @media (--viewport-lg-min) {
          font-size: 1rem; /* 16px */
          line-height: 1.25rem; /* 20px */
        }
      }
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 16px;
      padding-top: 16px;

      @media (--viewport-lg-min) {
        padding-right: 24px;
        padding-top: 24px;
      }

      & .icon {
        @media (--viewport-lg-min) {
          min-width: 32px;
          min-height: 32px;
          width: 32px;
          height: 32px;
        }
      }
    }

    &-footer {
      position: absolute;
      bottom: 0;
      font-weight: 600;
      line-height: 24px;
      padding-left: 16px;
      padding-bottom: 32px;
      padding-right: 24px;

      @media (--viewport-lg-min) {
        padding-left: 24px;
        padding-bottom: 56px;
        padding-right: 56px;
      }

      &-time {
        color: var(--color-event-card-time);
        letter-spacing: 1.07px;
        padding-bottom: 8px;
      }

      &__title {
        font-family: var(--heading-font-family);
        color: var(--color-white);
        font-size: 1rem; /* 16px */
        line-height: 1.25rem; /* 20px */

        @media (--viewport-lg-min) {
          font-size: 1.5rem; /* 24px */
          line-height: 1.875rem; /* 30px */
        }
      }
    }
  }
}

/* adjustments if the event-card is in a smaller grid */
.event-listing--filter-grid {
  & .event-card {
    &-content {
      &-date {
        @media (--viewport-lg-min) {
          height: 56px;
          width: 56px;
        }

        &__day {
          @media (--viewport-md-min) {
            font-size: 1.5rem; /* 24px */
            line-height: 1.5rem;
          }
        }

        &__month {
          @media (--viewport-md-min) {
            font-size: 0.875rem; /* 14px */
            line-height: 1rem;
          }
        }
      }

      &-icon {
        @media (--viewport-lg-min) {
          padding-right: 16px;
          padding-top: 16px;
        }

        & .icon {
          @media (--viewport-lg-min) {
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
          }
        }
      }

      &-footer {
        @media (--viewport-lg-min) {
          padding-left: 16px;
          padding-bottom: 32px;
          padding-right: 24px;
        }

        &-title {
          @media (--viewport-lg-min) {
            font-size: 1rem; /* 16px */
            line-height: 1.25rem; /* 20px */
          }
        }
      }
    }
  }
}
