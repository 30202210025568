.social-buttons {
  margin-top: 40px;
  display: flex;
  width: 202px;
  height: 48px;

  &__item {
    flex: 1;

    .icon {
      width: 48px;
      height: 48px;
    }
  }
}
