.ask-a-question {
  &__title {
    margin-top: 48px;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
  }

  &__subtitle {
    max-width: 720px;
    font-weight: 400;
    font-size: 1.125rem; /* 18px */
    line-height: 1.25rem; /* 20px */
    margin-bottom: 20px;

    @media (--viewport-md-min) {
      font-size: 1.25rem; /* 20px */
      line-height: 1.75rem; /* 28px */
      margin-bottom: 48px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    .form {
      width: 100%;

      @media (--viewport-sm-min) {
        width: 560px;
      }

      button {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
}
