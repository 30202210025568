.story-before-after-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 96px;
  padding-bottom: 96px;
  margin-bottom: 150px;
  margin-top: 106px;
  position: relative;

  @media (--viewport-md-min) {
    margin-top: 0;
  }

  &-content {
    position: relative;
    width: 100%;
    height: 500px;
    z-index: 1;

    @media (--viewport-md-min) {
      width: 584px;
      height: 840px;
    }

    &-image-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-image-foreground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }
  }

  &-image-caption {
    &-before {
      position: absolute;

      &__solid {
        margin-left: 0px;
        margin-top: 0px;
        opacity: 0;
        width: 300px;
        height: 300px;

        @media (--viewport-md-min) {
          width: auto;
          height: auto;
        }
      }

      &__outlined {
        position: absolute;
        z-index: 1;
        opacity: 0;
        width: 300px;
        height: 300px;

        @media (--viewport-md-min) {
          width: auto;
          height: auto;
        }
      }
    }

    &-after {
      position: absolute;

      &__solid {
        margin-left: 0px;
        margin-top: 0px;
        opacity: 0;
        width: 300px;
        height: 300px;

        @media (--viewport-md-min) {
          width: auto;
          height: auto;
        }
      }

      &__outlined {
        position: absolute;
        z-index: 1;
        opacity: 0;
        width: 300px;
        height: 300px;

        @media (--viewport-md-min) {
          width: auto;
          height: auto;
        }
      }
    }
  }

  & .story-transition-arrow {
    position: absolute;
    justify-content: center;
    bottom: 0;
  }
}
