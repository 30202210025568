.multi-select {
  margin-bottom: 24px;

  @media (--viewport-md-min) {
    margin-bottom: 48px;
  }

  &-content {
    @media (--viewport-md-min) {
      display: flex;
      flex-flow: row wrap;
    }

    /* show gradient border of option elements in flickity slider */
    & .flickity-viewport {
      overflow: visible;
    }

    /* remove black line around scroll items */
    &.flickity-enabled:focus .flickity-viewport {
      outline: none;
    }
  }

  &-warning {
    padding-top: 40px;
    padding-bottom: 0;
    color: var(--color-visualiser-error-color);

    @media (--viewport-md-min) {
      padding-top: 24px;
    }
  }
}
