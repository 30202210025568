.visualiser-navigation {
  /* default button styling (sidebar) */
  &-buttons {
    display: flex;
    flex-flow: column;

    @media (--viewport-md-min) {
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    &__button {
      &.button {
        text-transform: uppercase;
        font-size: 0.875rem; /* 14px */
        letter-spacing: 2px;
        line-height: 17px;
        width: 100%;
        margin-bottom: 32px;
        background-color: var(--color-visualiser-button);
        border: none;

        @media (--viewport-md-min) {
          width: calc(50% - 20px);
          max-width: 384px;
          margin-bottom: 0;
        }

        &--inverted {
          background-color: var(--color-visualiser-button-secondary);
          color: var(--color-visualiser-button-secondary-text);
        }

        &--disabled {
          color: var(--color-disabled);
          border-color: var(--color-disabled);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-skip-link {
    display: block;
    color: var(--color-visualiser-navigation-skip-link);
    margin-top: 16px;
    text-align: center;

    @media (--viewport-md-min) {
      text-align: left;
      margin-top: 72px;
    }
  }

  /* adjustments for buttons on results page */
  &--results-page {
    text-align: center;

    & .visualiser-navigation-buttons {
      @media (--viewport-md-min) {
        justify-content: center;
      }

      &__button.button {
        @media (--viewport-md-min) {
          margin-left: 40px;
          margin-right: 40px;
        }

        &:first-child {
          order: 1;

          @media (--viewport-sm-max) {
            margin-top: 32px;
            margin-bottom: 0;
          }
        }
      }
    }
    & .visualiser-navigation-skip-link {
      margin-top: 16px;
      display: inline-block;
    }
  }

  /* adjustments for buttons on contact details page */
  &--contact-details {
    & .visualiser-navigation-buttons {
      @media (--viewport-md-min) {
        justify-content: flex-start;
      }

      &__button.button {
        @media (--viewport-md-min) {
          margin-left: 0;
          margin-right: 80px;
        }
      }
    }
  }
}
