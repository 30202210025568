/*
 * Molecule: Region selector
 */

.region-selector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s opacity var(--transition);

  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    z-index: -1;
  }

  &__inner {
    background: var(--color-background-01);
    padding-top: 16px;
    height: 100%;
    color: var(--color-text);

    > .container {
      position: relative;
    }
    
    @media (--viewport-md-min) {
      height: auto;
      padding-top: 32px;
      padding-bottom: 48px;

      background: var(--color-background-01);
      color: var(--color-dark-blue);
    }
  }

  &__close {
    position: absolute;
    left: var(--container-spacing--mobile);
    top: 0;
    padding: 16px;
    cursor: pointer;
    z-index: 1;
    
    @media (--viewport-md-min) {
      left: auto;
      right: var(--container-spacing);
    }

    &:after, &:before {
      position: absolute;
      width: 24px;
      height: 2px;
      content: '';
      display: block;
      border-radius: 2px;
      background: currentColor;
      transform: translateX(-50%) rotate(45deg);
    }

    &:after {
      transform: translateX(-50%) rotate(-45deg);
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity .2s var(--transition);

    .icon {
      width: 40px;
      height: 40px;
      /** #012169 **/
      /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
      filter: brightness(0) saturate(100%) invert(12%) sepia(45%) saturate(4154%) hue-rotate(214deg) brightness(93%) contrast(110%);
    }

    @media (--viewport-md-min) {
      display: none;
    }
  }

  &__header {
    border-bottom: 2px solid var(--color-text);
    margin-bottom: 8px;
    padding-top: 80px;
    transform: translateX(0);
    transition: transform 0.3s var(--transition);
    min-height: 80px;

    @media (--viewport-md-min) {
      border-bottom: none;
      max-width: 350px;
      width: 35%;
      padding-top: 32px;
      /* margin-bottom: -120px; */
    }
  }

  &__heading {
    font-size: 0.75rem;
    margin-bottom: 12px;
    letter-spacing: 0.1em;
    
    @media (--viewport-md-min) {
      font-size: 1rem;
      margin-bottom: 8px;
    }
  }

  &__current-region {
    margin-bottom: 20px;
    font-size: 1.125rem;
    letter-spacing: 0.1em;
    
    @media (--viewport-md-min) {
      font-size: 1.375rem;
      margin-bottom: 24px;
      font-weight: 500;
    }
  }

  &__columns {
    display: flex;
    transform: translateX(0);
    transition: transform 0.3s var(--transition);

    > * {
      flex-shrink: 0;
      flex-grow: 0;
      
      @media (--viewport-sm-max) {
        width: 100%;
      }
    }
  }

  &__groups {
    @media (--viewport-md-min) {
      max-width: 320px;
      width: 35%;
      padding-top: 20px;
      padding-bottom: 32px;
    }
  }

  &-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
    position: relative;

    @media (--viewport-md-min) {
      padding: 16px 0;
    }

    &__hover-handler {
      display: none;

      @media (--viewport-md-min) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &__name {
      letter-spacing: 0.1em;

      @media (--viewport-md-min) {
        font-size: 1.125rem;
      }
    }

    .icon {
      fill: currentColor;
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
      transform: rotate(-90deg);
    }

    &--active {
      @media (--viewport-md-min) {
        color: var(--color-pale-blue)
      }
    }
  }

  &__pages {
    margin-left: calc(2 * var(--container-spacing--mobile));
    margin-top: -112px;
    
    @media (--viewport-md-min) {
      max-width: 65%;
      display: flex;
      align-items: center;
      margin-left: 48px;
      padding-left: 32px;
      border-left: 2px solid var(--region-selector-color-border-dark);
    }
  }

  &-page {
    padding-left: 32px;
    
    @media (--viewport-md-min) {
      padding-left: 0;
    }
  
    &__name {
      font-weight: bolder;
      letter-spacing: 0.1em;
      padding-bottom: 16px;
      border-bottom: 2px solid var(--color-text);
      margin-bottom: 16px;

      @media (--viewport-md-min) {
        display: none;
      }
    }

    &__items {
      @media (--viewport-md-min) {
        columns: 2 auto;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 12px 0;
      
      @media (--viewport-md-min) {
        display: inline-flex;
        padding: 16px 0;
        width: 100%;
        margin-right: 32px;
        font-size: 1.125rem;
      }

      &__name {
        letter-spacing: 0.1em;
        color: var(--color-text);

        @media (--viewport-md-min) {
          color: var(--color-dark-blue);
        }
      }

      
      &:hover, &:focus {
        .region-selector-page-item__name {
          @media (--viewport-md-min) {
            color: var(--color-pale-blue);
          }
        }
      }

      .icon {
        display: none;

        @media (--viewport-md-min) {
          fill: var(--color-highlight);
          margin-right: 16px;
          display: inline-block;
        }
      }
    }
  }

  &__back-button {
    cursor: pointer;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    font-weight: 300;
    padding: 12px 32px;
    position: relative;

    @media (--viewport-md-min) {
      display: none;
    }

    .icon {
      fill: currentColor;
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      transform: translate(-50%, -50%) rotate(90deg);
      position: absolute;
      left: 8px;
      top: 50%;
    }
  }

  &--active {
    opacity: 1;
    pointer-events: initial;
  }

  @media (--viewport-sm-max) {
    &--group-selected {
      .region-selector__columns, 
      .region-selector__header {
        transform: translateX(-100%); /* fallback */
        transform: translateX(calc(-100% - 2 * var(--container-spacing--mobile)));
      }
      
      .region-selector__icon {
        opacity: 0;
      }
    }
  }
}