/*
 * Organism: Physician popup
 */
.physician-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);

  &--hide {
    display: none;
  }

  &__inner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    z-index: 1001;
    transform: translate(-50%, -50%);
    background: var(--color-physician-popup-background);
    border-radius: 8px;
    color: var(--color-physician-popup-text);
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 720px;
    margin: 20px;

    @media (--viewport-md-min) {
      padding: 72px;
    }
  }

  &-content {
    .logo {
      margin-bottom: 32px;
    }

    &__title {
      @extend h3;
    }

    &__text {
      margin-bottom: 32px;
    }

    .checkbox {
      &__tick {
        &:before {
          background: white;
          border-color: transparent;
        }
      }

      [type='checkbox']:checked + .checkbox__tick:before {
        border-color: transparent;
      }
    }
  }

  &-controls {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (--viewport-md-min) {
      flex-direction: row;
    }

    &__button {
      margin-bottom: 16px;

      @media (--viewport-md-min) {
        margin-bottom: 0;
      }
    }
  }
}
