/*
 * Molecule: Grid element - Image and text slider
 */

.image-and-text-slider {
  width: 100%;
  margin-bottom: 32px;

  .js-image-and-text-slider-carousel {
    @media (--viewport-md-min) {
      min-height: 530px;
    }
  }

  .js-hide-until-loaded {
    visibility: hidden;
    opacity: 0;

    &__loaded {
      visibility: inherit;
      transition: opacity 0.2s;
      opacity: 1;
    }
  }

  &__title {
    margin-top: 48px;
    margin-bottom: 48px;
    position: relative;
    z-index: 2;
  }

  &__cta {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: var(--container-spacing--mobile);
    padding-right: var(--container-spacing--mobile);

    @media (--viewport-md-min) {
      margin-bottom: 48px;
      padding-left: var(--container-spacing);
      padding-right: var(--container-spacing);
    }
  }

  &-item {
    position: relative;
    width: 100%;

    @media (--viewport-md-min) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 0;
      min-height: 530px;
    }

    /* if only the media is present and no content, center the media */
    > div:first-child:nth-last-child(1) {
      margin-left: auto;
      margin-right: auto;
    }

    &-media-svg-graph {
      position: relative;
      margin-bottom: 70px;
      border: 1px solid red;

      @media (--viewport-md-min) {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
        margin-bottom: 32px;
      }

      & .image-and-text-slider-item-overlay {
        position: relative;
        z-index: 1;
        transition: opacity 0.2s var(--transition), z-index 0.6s;
        height: 0;
        padding-bottom: 56%; /* 16:9 aspect ratio for the player container */

        &--hide {
          opacity: 0;
          pointer-events: none;
          z-index: 0;
        }

        &__thumbnail {
          position: absolute;
          top: 24px;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;

          @media (--viewport-md-min) {
            top: 0;
          }
        }
      }
    }

    &-media-image {
      position: relative;
      margin-bottom: 40px;

      @media (--viewport-md-min) {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
        margin-bottom: 32px;
      }

      & .image-and-text-slider-item-overlay {
        position: relative;
        z-index: 1;
        transition: opacity 0.2s var(--transition), z-index 0.6s;
        height: 0;
        padding-bottom: 56%; /* 16:9 aspect ratio for the player container */

        &--hide {
          opacity: 0;
          pointer-events: none;
          z-index: 0;
        }

        &__thumbnail {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (--viewport-md-min) {
        margin-left: 8.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
      }

      &__title-mobile {
        margin-bottom: 20px;
        max-width: 85%;

        @media (--viewport-md-min) {
          display: none;
        }
      }

      &__title-desktop {
        display: none;

        @media (--viewport-md-min) {
          display: block;
          margin-bottom: 20px;
          max-width: 75%;
        }
      }

      &__caption {
        display: block;
        font-weight: 300;

        @media (--viewport-md-min) {
          font-size: 1.125rem; /* 18px */
          line-height: 1.5625rem; /* 25px */
          max-width: 75%;
        }
      }
    }
  }

  &-selector {
    display: flex;
    height: 24px;
    width: 240px;
    justify-content: space-between;
    align-items: center;
    margin: 24px auto 0;
    background-image: none !important;

    @media (--viewport-md-min) {
      margin-top: 32px;
    }

    &-progress {
      position: absolute;
      width: inherit;
      height: 2px;
      background: var(--color-image-and-text-slider-controls);
    }

    &-item {
      display: block;
      position: relative;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: var(--color-image-and-text-slider-controls);
      transition: all 0.3s var(--transition);

      &:focus {
        outline: none;
      }

      &--selected {
        position: relative;
        transform: scale(2);
      }
    }
  }
}
