/*
 * Atom: Input 
 */

.input {
  display: block;
  width: 100%;
  padding: 14px 16px 13px;
  font-size: var(--input-font-size);
  line-height: 1;
  font-family: var(--base-font-family);
  color: var(--color-text);
  background-color: var(--input-bg-color);
  background-image: none;
  transition: all 0.2s var(--transition);

  /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
  border: var(--input-border-width) solid var(--color-border);
  border-radius: var(--input-border-radius);
  vertical-align: bottom;
  appearance: none;

  &:active,
  &:focus {
    border-color: var(--color-border-focus);
    outline: none;
  }

  @media (--viewport-md-min) {
    padding: 19px 16px 17px;

    &:hover {
      border-color: var(--color-border-hover);
    }
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-input-placeholder);
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-input-placeholder);
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--color-input-placeholder);
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--color-input-placeholder);
  }

  &__description {
    font-size: 0.875rem; /* 14px */
    color: var(--color-input-placeholder);
    font-style: italic;
    margin-top: 4px;

    &[type='text'],
    &[type='search'],
    &[type='email'],
    &[type='password'],
    &[type='url'],
    &[type='tel'],
    &[type='number'] {
      -webkit-border-radius: 0;
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

/* clears the 'X' from Internet Explorer */
input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
