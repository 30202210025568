.text-and-cta {
  background: rgb(229,211,212);
  background: linear-gradient(12deg, rgba(229,211,212,1) 0%, rgba(219,192,240,1) 75%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  min-height: 350px;

  @media (--viewport-md-min) {
    min-height: 450px;
    gap: 80px;
  }

  &__header {
    font-size: 1.875rem; /** 30px **/

    @media (--viewport-md-min) {
       font-size: 2.75rem; /** 44px **/
    }
  }
}