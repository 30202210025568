.event-listing {
    margin-bottom: 24px;

    @media (--viewport-md-min) {
        margin-bottom: 40px;
        position: relative;
    }

    &--filter-grid {
        margin-top: 0;

        @media (--viewport-md-min) {
            margin-top: 0;
            margin-bottom: 0;
        }


        & .event-listing-content {
            margin-top: 0;
            margin-bottom: 64px;
            display: flex;
            flex-wrap: wrap;
            margin: -20px;

            @media (--viewport-md-min) {
                margin-top: 0;
                margin-bottom: 72px;
            }

            &-item {
                padding: 20px;
                width: 100%;
                height: auto;

                @media (--viewport-ms-min) {
                    width: 50%;
                }

                @media (--viewport-lg-min) {
                    width: 33.33333333%;
                }

                & .event-card {
                    width: 100%;
                }
            }
        }
    }

    &-content {
        margin-top: 48px;
        margin-bottom: 48px;

        @media (--viewport-md-min) {
            margin-top: 80px;
            margin-bottom: 56px;
            margin-right: -12px;
            margin-left: -12px;
            display: flex;
            flex-wrap: wrap;
        }

        & .flickity-viewport {
            overflow: visible;
        }

        &-item {
            height: 273px;
            width: calc(267px + 24px);

            @media (--viewport-md-min) {
                width: 33.33333333%;
                height: auto;
                padding: 12px;
            }
        }
    }
}
