/*
 * Molecule: Grid element - How it works
 */

.how-it-works {
  position: relative;
  height: 80vh;
  min-height: 640px;
  max-height: 750px;

  @media (--viewport-md-min) {
    height: 90vh;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-how-it-works-background);
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
  }

  &-content {
    &__title {
      color: var(--color-how-it-works-content-title-mobile);
      margin-top: 48px;
      position: relative;
      z-index: 2;

      @media (--viewport-md-min) {
        color: var(--color-how-it-works-content-title-desktop);
      }
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 40px;
      left: 0;
      padding-left: var(--container-spacing--mobile);
      padding-right: var(--container-spacing--mobile);

      @media (--viewport-md-min) {
        bottom: 48px;
        padding-left: var(--container-spacing);
        padding-right: var(--container-spacing);
      }
    }

    &-slides {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% + (var(--container-spacing--mobile) * 2));
      margin-left: calc(0 - var(--container-spacing--mobile));
      margin-right: calc(0 - var(--container-spacing--mobile));

      &.flickity-enabled:focus .flickity-viewport {
        outline: none;
      }

      @media (--viewport-md-min) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &-item {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: flex-end;
        opacity: 0;

        @media (--viewport-md-min) {
          align-items: center;
        }

        &-inner {
          border: var(--how-it-works-content-slides-item-inner-border);
          padding: var(--how-it-works-content-slides-item-inner-padding-mobile);
          background: linear-gradient(rgba(0,0,0,.35) 190px, var(--color-black));
          mask-image: gradient(linear,left 72%,left bottom,from(var(--color-black)),to(rgba(0,0,0,0)));
          -webkit-mask-image: -webkit-gradient(linear,left 72%,left bottom,from(var(--color-black)),to(rgba(0,0,0,0)));
          position: relative;
          z-index: 2;
          color: var(--color-how-it-works-content-inner-mobile);
          bottom: 144px;
          margin-left: calc(var(--container-spacing--mobile));
          margin-right: calc(var(--container-spacing--mobile));
          max-height: 260px;
          overflow: auto;

          @media (--viewport-md-min) {
            padding: var(
              --how-it-works-content-slides-item-inner-padding-desktop
            );
            width: 424px;
            background: none;
            color: var(--color-how-it-works-content-inner-desktop);
            bottom: 0;
            margin-left: 0;
            margin-right: 0;
            max-height: none;
            mask-image: none;
            overflow: visible;
          }

          &__title {
            color: currentColor;
          }

          &__text {
            display: block;
            font-weight: 300;

            @media (--viewport-md-min) {
              line-height: 2.1875rem; /* 35px */
              font-size: 1.25rem; /* 20px */
            }
          }
        }

        &-media {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          height: 100%;
          width: 100%;

          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 25%;
            top: 0;
            left: 0;

            background-image: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0) 8%,
              rgba(0, 0, 0, 0.33) 75%
            );

            @media (--viewport-md-min) {
              display: none;
            }
          }

          &__image {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;

            @media (--viewport-md-min) {
              object-fit: contain;
              margin-left: auto;
              width: auto;
            }
          }

          &__animation {
            display: block;
            height: 100%;
            width: 90vh;
            position: absolute;
            top: 0;
            right: 50%;
            z-index: 1;
            transform: translate3d(50%, 0, 0);

            @media (--viewport-md-min) {
              width: 100%;
              right: 0;
              transform: translate3d(0, 0, 0);
            }
          }
        }

        &__caption {
          position: absolute;
          bottom: 15px;
          font-style: italic;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          text-align: center;
          display: block;
          width: 100%;
          font-size: 0.75rem; /* 12px */

          @media (--viewport-md-min) {
            left: 0;
            transform: translate3d(0, 0, 0);
            text-align: left;
          }
        }
      }

      &-controls {
        @extend .content-slider__button-row;
        width: 100%;
        padding-bottom: 0;
        position: absolute;
        bottom: 96px;
        left: 0;

        @media (--viewport-md-min) {
          bottom: 136px;
        }

        &-progress {
          position: absolute;
          height: 2px;
          width: 240px;
          background: var(--color-how-it-works-controls);
          top: 50%;
          transform: translateY(-50%);
        }

        &-buttons {
          @extend .content-slider__btn-group;
          background-image: none;

          &__button {
            @extend .content-slider__btn;
            background-color: var(--color-how-it-works-controls);
            z-index: 5;

            &--is-selected {
              @extend .content-slider__btn--is-selected;
            }
          }
        }
      }
    }
  }
}

.is-selected {
  opacity: 1;
}
