:root {
  --font-size-title-desktop: 24px;
  --font-size-title-mobile: 18px;
  --border-radius: 24px;
  --title-font-weight: 900;
}

/**
*   Overwrite default overflow setting, otherwise position
*   sticky for contact-form box will not work.
*/
body.location-finder-page {
  overflow-x: visible;

  & .site {
    overflow: visible;
  }

  & main {
    overflow: visible;
  }
}

.clinic-finder-landing-page {
  /* add distance to header, so header is not on top of location finder landing page */
  margin-top: 106px;
  background: var(--color-cf-landing-page-bg, unset);

  @media (--viewport-md-min) {
    margin-top: 128px;
  }

  /* 
   * General styles
   */
  & a:not(.button) {
    color: var(--color-link);
  }

  &__back-btn {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--color-eminence);
    font-family: var(--navigation-font-family);
    margin-bottom: 16px;
    margin-top: 16px;

    & .icon {
      min-width: 6px;
      min-height: 6.75px;
      width: 6px;
      height: 6.75px;
      transform: rotate(90deg);
      margin-right: 8px;
    }
  }

  /**
    * campaign banner
    */
  &__campaign-banner {
    display: flex;
    background-color: var(--color-white);
    min-height: 476px;
    width: 100%;
    margin-bottom: 40px;
    border-radius: var(--border-radius);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);

    @media (--viewport-sm-max) {
      flex-flow: column;
    }

    &-image {
      display: flex;
      width: 60%;
      border-radius: var(--border-radius) 0 0 var(--border-radius);
      overflow: hidden;

      @media (--viewport-sm-max) {
        order: 2;
        width: 100%;
        height: 250px;
        border-radius: 0 0 24px 24px;
      }

      & figure {
        width: 100%;
        background-size: cover;
      }
    }

    &-content {
      width: 40%;
      margin: 80px 40px 80px 32.5px;

      @media (--viewport-sm-max) {
        width: 100%;
        margin: 0;
        padding: 28px 16px 28px 16px;
        text-align: center;
      }

      &-title {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 900;
        font-family: var(--heading-font-family);
        padding-bottom: 24px;
        text-transform: uppercase;

        @media (--viewport-sm-max) {
          font-size: 24px;
          line-height: 1.167;
        }
      }

      &-description {
        font-size: 18px;
        line-height: 1.5;
        padding-bottom: 40px;

        @media (--viewport-sm-max) {
          font-size: 16px;
          padding-bottom: 24px;
        }
      }

      &-connect {
        display: flex;

        @media (--viewport-sm-max) {
          flex-flow: column;
        }

        & .cta {
          margin-bottom: 0;
          margin-right: 24px;

          @media (--viewport-sm-max) {
            margin-bottom: 24px;
            margin-right: 0;
          }
        }

        &-fb {
          display: flex;
          align-items: center;
          cursor: pointer;

          @media (--viewport-sm-max) {
            justify-content: center;
          }
        }
      }
    }
  }

  /**
    * Hero, introducing the clinic finder landing page
    */
  &-hero {
    position: relative;

    --hero-gallery-height-mobile: 160px;
    --hero-gallery-height-desktop: 576px;

    &-gallery {
      position: relative;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      max-width: var(--site-max-width);
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-auto-flow: column;
      cursor: pointer;

      &__image {
        height: var(--hero-gallery-height-mobile);
        position: relative;

        .play-icon {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          cursor: pointer;
          transition: opacity 0.3s;

          & .icon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media (--viewport-md-min) {
        &__image {
          height: var(--hero-gallery-height-desktop);

          /* If there are 5 images, First image takes up 50% width/height, rest takes up equal amount */
          &:first-child:nth-last-child(n + 5),
          &:first-child:nth-last-child(n + 5) ~ * {
            /* Each item takes up equal amount of width and height */
            grid-column: span 1;
            grid-row: span 1;
            height: calc(var(--hero-gallery-height-desktop) / 2);

            &:nth-child(1) {
              height: var(--hero-gallery-height-desktop);
              grid-column: span 2;
              grid-row: span 2;
            }
          }
        }
      }

      @media (--viewport-ms-max) {
        grid-gap: 4px;

        &__image:nth-child(n + 3) {
          display: none;
        }

        &__image:nth-child(1) {
          grid-column: span 4;
        }

        &__image:nth-child(2) {
          grid-column: span 1;
        }
      }

      @media (--viewport-sm-max) {
        grid-gap: 4px;

        &__image:nth-child(n + 4) {
          display: none;
        }
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      margin: -40px auto 70px;
      max-width: 100vw;
      position: relative;

      &:first-child {
        margin-top: 0;
        margin-bottom: 0;
      }

      @media (--viewport-md-min) {
        margin-top: 0;
        padding: 40px 0;
        flex-direction: row;
        padding-bottom: 40px;
        align-items: center;
        justify-content: space-between;

        &:first-child {
          margin-top: 70px;
          margin-bottom: 40px;

          &.with-image {
            margin-top: 190px;
          }
        }

        &:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: var(--site-max-width);
          max-width: 100vw;
          height: 100%;
          border-bottom: 1px solid var(--color-section-divider-lines);
        }
      }

      @media (--viewport-lg-min) {
        width: 1080px;
        max-width: 100vw;
      }

      &-profile {
        display: flex;
        flex-direction: column;

        @media (--viewport-md-min) {
          position: relative;
          align-items: center;
          flex-direction: row;
        }

        &-left {
          @media (--viewport-md-min) {
            position: absolute;
            left: 0;
            bottom: 0;
          }

          &__image {
            border-radius: 50%;
            width: 80px;
            height: 80px;
            border: 4px solid var(--color-cf-landing-page-hero-details-profile-image-border);
            overflow: hidden;
            margin-bottom: 16px;

            @media (--viewport-md-min) {
              margin-bottom: 0;
              width: 190px;
              height: 190px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-open-closed {
            cursor: pointer;
            color: var(--color-cf-landing-page-hero-details-profile-content);
            font-family: var(--base-font-family);
            margin-bottom: 16px;
            font-size: 13px;

            @media (--viewport-md-min) {
              margin-bottom: 0;
              text-align: center;
              max-width: 90%;
              padding-left: 10%;
              font-size: 16px;
            }

            &__status {
              font-weight: bold;
              font-size: 13px;

              @media (--viewport-md-min) {
                font-size: 21px;
              }

              &:after {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-left: 6px;
                background: var(--color-success);
              }

              &--closed {
                &:after {
                  background: var(--color-danger);
                }
              }
            }
          }
        }

        &-content {
          color: var(--color-cf-landing-page-hero-details-profile-content);
          position: relative;

          @media (--viewport-md-min) {
            padding-left: 202px;
            max-width: 600px;
          }

          &__review-box {
            margin-top: 20px;

            @media (--viewport-md-min) {
              margin-top: 0;
              position: absolute;
              top: -100%;
              transform: translateY(-50%);
              * {
                z-index: 1;
                position: relative;
              }

              &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.32);
                filter: blur(20px);
                transform: translate(-50%, -50%);
                z-index: 0;
              }
            }
          }

          &__name {
            font-size: 18px;
            line-height: 1;
            font-family: var(--heading-font-family);
            font-weight: var(--heading-font-weight);
            margin: 0;

            @media (--viewport-md-min) {
              font-size: 27px;
            }
          }

          &__byline {
            display: block;
            font-size: 14px;
            font-family: var(--base-font-family);
            font-weight: var(--base-font-weight);

            @media (--viewport-md-min) {
              font-size: 18px;
            }
          }

          &-badges {
            display: flex;
            margin-top: 8px;

            &__item {
              background-color: var(--color-lf-list-item-badges);
              color: var(--color-white);
              border-radius: 10.5px;
              font-weight: 700;
              margin-right: 8px;
              padding: 6px 8px 4px 8px;
              font-size: 10px;
            }
          }

          &-facebook-reviews {
            display: flex;
            margin-top: 16px;

            @media (--viewport-sm-max) {
              justify-content: center;
            }

            strong {
              margin-left: 0.5rem;
            }

            .button {
              @media (--viewport-sm-max) {
                font-size: 0.5rem;
                min-height: 30px;
              }
            }
          }
        }
      }

      &-controls {
        @media (--viewport-md-max) {
          display: flex;
          justify-content: space-between;
        }

        @media (--viewport-sm-max) {
          margin-top: 32px;
        }

        &__button.button {
          min-width: unset;

          @media (--viewport-md-max) {
            width: 50%;
            max-width: calc(50% - 8px);
          }

          @media (--viewport-sm-max) {
            font-size: 0.5rem;
            max-width: 150px;
            min-height: 30px;
          }

          & + & {
            margin-left: 16px;
          }
        }
      }
    }
  }

  /**
    * Floating contact button on mobile
    */
  &__contact-btn {
    position: sticky;
    bottom: 78px;
    z-index: 2;

    .cta {
      display: none;

      @media (--viewport-sm-max) {
        display: flex;
        margin: -70px auto 70px auto;
        z-index: 3;
        justify-content: center;
      }
    }
  }

  /**
    * Main content area
    */
  &__content-wrapper {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--base-font-family);
    line-height: 1.5;
    margin-bottom: 168px;
    border-bottom: 1px solid var(--color-section-divider-lines);

    @media (--viewport-sm-max) {
      width: 100%;
      margin-bottom: 53px;
    }

    @media (--viewport-lg-min) {
      width: 1080px;
      max-width: 100vw;
    }

    &-left {
      width: 680px;
      padding-right: 80px;
      display: flex;
      flex-flow: column;

      @media (--viewport-sm-max) {
        width: 100%;
        padding-right: 0;
      }
    }

    &-right {
      display: flex;
      width: 33%;
      max-width: 400px;

      @media (--viewport-sm-max) {
        display: none;
      }

      @media (--viewport-lg-min) {
        width: 400px;
      }
    }
  }

  &__about {
    border-bottom: 1px solid var(--color-section-divider-lines);
    padding-bottom: 41px;

    @media (--viewport-ms-max) {
      padding-bottom: 31px;
    }
  }

  &__description {
    font-size: 18px;
    padding-bottom: 40px;

    &-short {
      display: none;

      @media (--viewport-ms-max) {
        display: block;
      }

      :first-child {
        position: relative;
      }

      /** fade out animation at end of description*/
      &:after {
        content: '';
        position: relative;
        display: block;
        background: linear-gradient(0deg, var(--site-background-color) 10%, rgba(26, 26, 26, 0));
        height: 100px;
        margin-top: -147px;
        z-index: 1;
        width: 100%;
      }

      p {
        text-align: center;
        font-size: 16px;
        color: var(--color-cf-landing-page-about-section-read-more);
      }
    }

    &-long {
      display: block;
      @media (--viewport-ms-max) {
        display: none;
      }
    }
  }

  &-accreditions {
    display: flex;
    padding-bottom: 24px;

    &-icon {
      font-weight: 600;
      font-size: 32px;
      color: var(--color-cf-landing-page-about-section-icons);
      padding-right: 30px;
      line-height: 1;
      width: 66px;

      & .icon {
        width: 48px;
        height: 48px;
      }

      @media (--viewport-ms-max) {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 5px;

        & .icon {
          width: 36px;
          height: 36px;
        }
      }
    }

    &-content {
      &__title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: var(--heading-font-weight);
        font-family: var(--heading-font-family);
        line-height: 1; /* instalift adjustment */
        padding-bottom: 5px; /* instalift adjustment */
      }

      &__list {
        position: relative;

        transition: all 3s;
        max-height: 75px;
        overflow: hidden;

        &.uncompressed {
          max-height: 2500px;
        }

        .read-more-accreditations {
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
          text-decoration: underline;
          font-size: 10px;
          z-index: 3;
          &.hide {
            display: none;
          }
        }

        span {
          @media (--viewport-ms-max) {
            display: inline-block;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  &-languages {
    display: flex;

    &-count {
      font-weight: 600;
      font-size: 32px;
      color: var(--color-cf-landing-page-about-section-icons);
      line-height: 1;
      width: 66px;
      display: flex;
      justify-content: center;

      @media (--viewport-ms-max) {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 5px;
      }
    }

    &-content {
      &__title {
        font-size: 18px;
        font-weight: var(--heading-font-weight);
        font-family: var(--heading-font-family);
        line-height: 1; /* instalift adjustment */
        padding-bottom: 5px; /* instalift adjustment */
        text-transform: uppercase;
      }
    }
  }

  &__treatments {
    border-bottom: 1px solid var(--color-section-divider-lines);
    padding-bottom: calc(41px - 16px);

    @media (--viewport-ms-max) {
      padding-bottom: calc(33px - 16px);
    }

    & > div:nth-child(1) {
      padding-top: 35px;
      padding-bottom: 23px;
      font-size: var(--font-size-title-desktop);
      font-family: var(--heading-font-family);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        text-align: center;
        padding-top: 32px;
        padding-bottom: 16px;
        font-size: var(--font-size-title-mobile);
      }
    }

    & > div:nth-child(2) {
      display: flex;
      font-size: 16px;
      flex-wrap: wrap;

      @media (--viewport-ms-max) {
        flex-direction: column;
        align-items: center;
      }

      > div {
        width: 50%;
        padding-bottom: 16px;

        @media (--viewport-ms-max) {
          width: max-content;
        }
      }
    }
  }

  &__image-gallery {
    position: relative;

    &-title {
      font-family: var(--heading-font-family);
      padding-bottom: 24px;
      padding-top: 40px;
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        text-align: center;
        padding-bottom: 16px;
        padding-top: 31px;
        font-size: var(--font-size-title-mobile);
      }
    }

    &-image {
      width: 100%;
      height: 453px;
      background-position: 0;
      background-size: cover;

      & .play-icon {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .icon {
          width: 51px;
          height: 51px;
          min-width: 51px;
          min-height: 51px;
        }
      }

      @media (--viewport-ms-max) {
        height: 210px;
      }
    }

    &-btn {
      position: absolute;
      bottom: 16px;
      right: 16px;
      margin-bottom: 0;
    }
  }

  &-before-after {
    padding-bottom: 41px;
    border-bottom: 1px solid var(--color-section-divider-lines);

    &-title {
      font-family: var(--heading-font-family);
      padding-bottom: 24px;
      padding-top: 40px;
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        text-align: center;
        padding-bottom: 8px;
        padding-top: 32px;
        font-size: var(--font-size-title-mobile);
      }
    }

    &-slider {
      &-item__label {
        &-before:after {
          left: 16px;
        }
        &-after:after {
          right: 16px;
        }
      }

      &-selector-item__image {
        height: 100%;
      }
    }
  }

  &-location {
    padding-bottom: 41px;
    border-bottom: 1px solid var(--color-section-divider-lines);

    @media (--viewport-ms-max) {
      padding-bottom: 33px;
    }

    &-title {
      font-family: var(--heading-font-family);
      padding-bottom: 24px;
      padding-top: 40px;
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        text-align: center;
        padding-bottom: 8px;
        padding-top: 32px;
        font-size: var(--font-size-title-mobile);
      }
    }

    &-info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 48px;
      @media (--viewport-ms-max) {
        text-align: center;
        flex-direction: column;
        align-items: center;
      }

      &-address {
        @media (--viewport-ms-max) {
          padding-bottom: 48px;
        }

        &__title {
          font-size: 1rem; /* 16px */
        }

        &__content {
          font-size: 0.875rem; /* 14px */
        }
      }
    }

    & .cta {
      margin-bottom: 32px;

      @media (--viewport-sm-max) {
        margin-bottom: 16px;
      }
    }

    &-map {
      height: 453px;
      width: 100%;
      margin-top: 12px;

      @media (--viewport-ms-max) {
        height: 210px;
      }
    }
  }

  &__connect {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    border-bottom: 1px solid var(--color-section-divider-lines);

    @media (--viewport-ms-max) {
      flex-direction: column;
      text-align: center;
    }

    & .icon {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      fill: var(--color-link);
      margin-right: 20px;
    }

    &-contact-wrapper {
      width: 321px;
      padding-right: 39px;

      @media (--viewport-ms-max) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 40px;
      }
    }

    &-contact-title {
      font-family: var(--heading-font-family);
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        font-size: var(--font-size-title-mobile);
      }
    }

    &-contact-option {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;

      @media (--viewport-ms-max) {
        width: 250px;
        margin: 0 auto 0 auto;
      }

      &-link {
        display: flex;
        align-items: center;
      }

      &-button {
        & .cta {
          margin-bottom: 0;
        }
      }
    }

    &-links-wrapper {
      @media (--viewport-ms-max) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-links-title {
      font-family: var(--heading-font-family);
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        font-size: var(--font-size-title-mobile);
      }
    }

    &-links-link {
      padding-top: 24px;
      display: flex;
      align-items: center;

      @media (--viewport-ms-max) {
        width: 178px;
      }
    }
  }

  &__opening-times {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--color-section-divider-lines);

    &-title {
      font-family: var(--heading-font-family);
      padding-bottom: 24px;
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        text-align: center;
        font-size: var(--font-size-title-mobile);
      }
    }

    &-table {
      height: 257px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-between;

      @media (--viewport-ms-max) {
        flex-direction: row;
        height: max-content;
        justify-content: center;
      }

      &-entry {
        display: flex;
        justify-content: space-between;
        width: 257px;
        padding-bottom: 20px;

        &-day {
          align-items: flex-start;
          display: flex;
        }

        &-times {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          & > div {
            width: max-content;
          }
        }
      }
    }
  }

  &__review-embed {
    padding-top: 41px;
    padding-bottom: calc(40px - 16px);

    @media (--viewport-ms-max) {
      padding-bottom: 20px;
    }

    &-title {
      font-family: var(--heading-font-family);
      padding-bottom: 24px;
      font-size: var(--font-size-title-desktop);
      font-weight: var(--title-font-weight);
      text-transform: uppercase;

      @media (--viewport-ms-max) {
        text-align: center;
        font-size: var(--font-size-title-mobile);
      }
    }
  }

  /**
    * Contact form
    */
  &__contact-form {
    background-color: var(--color-white);
    height: fit-content;
    width: 400px;
    padding: 40px;
    position: sticky;
    top: 40px;
    border-radius: var(--border-radius);
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 40px;

    &-alternative {
      padding-top: 32px;
      padding-bottom: 16px;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      color: var(--color-cf-landing-page-input-placeholder);

      &:before,
      &:after {
        content: '';
        flex: 1;
        border-bottom: groove 2px;
        margin-top: auto;
        margin-bottom: auto;
      }

      &:before {
        margin-right: 16px;
      }

      &:after {
        margin-left: 16px;
      }
    }

    &-phone {
      text-align: center;
      display: block;
    }

    &-more-info {
      padding-top: 24px;
    }

    /** hide close icon on desktop and show it on mobile*/
    &-close {
      display: none;

      @media (--viewport-sm-max) {
        display: block;
      }
    }

    &-title {
      font-family: var(--heading-font-family);
      font-size: 24px;
      padding-bottom: 16px;
      font-weight: var(--title-font-weight);
      text-transform: uppercase;
    }

    &-subtitle {
      padding-bottom: 19px;
    }

    &-input {
      margin-bottom: 16px;

      & .input {
        border: 2px solid var(--color-cf-landing-page-input);

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--color-cf-landing-page-input-placeholder);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--color-cf-landing-page-input-placeholder);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--color-cf-landing-page-input-placeholder);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--color-cf-landing-page-input-placeholder);
        }
      }
    }

    &-accept-terms {
      padding-bottom: 32px;
      line-height: 1.125;

      & .checkbox {
        margin-bottom: 0;

        &__tick:before {
          border-color: var(--color-cf-landing-page-checkbox);
        }

        & [type='checkbox']:checked + .checkbox__tick:before {
          border-color: var(--color-cf-landing-page-checkbox);
        }
      }
    }

    & .button {
      width: 100%;
    }

    &-sent-confirmation {
      font-size: 16px;

      &-title {
        font-size: 24px;
        font-family: var(--heading-font-family);
        padding-bottom: 16px;
        font-weight: 900;
        font-weight: var(--title-font-weight);
        text-transform: uppercase;
      }
    }
  }

  /**
    * campaign banner overlay
    */
  &__campaign-banner-overlay {
    /** overlay styles */
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background-color: var(--color-cf-landing-page-overlay-bg);
    transform: translateX(-100%);
    transition: transform 0.3s;
    overflow: hidden;

    &--expanded {
      transform: translateX(0);
    }

    &-close {
      width: 100%;

      @media (--viewport-sm-max) {
        top: 30px;
        right: 30px;
      }

      & .icon {
        position: absolute;
        top: 40px;
        right: 38px;
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        cursor: pointer;

        @media (--viewport-sm-max) {
          top: 30px;
          right: 30px;
        }
      }
    }

    &-wrapper {
      margin-top: 157px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      @media (--viewport-sm-max) {
        margin-top: 80px;
      }

      /** contact form in overlay */
      & .clinic-finder-landing-page__contact-form {
        width: 560px;
        margin-right: 160px;
        background-color: var(--color-cf-landing-page-overlay-bg);
        padding-top: 0;
        border-radius: var(--border-radius);
        box-shadow: none;

        @media (--viewport-sm-max) {
          margin-right: 0;
          padding-right: 30px;
          padding-left: 30px;
        }

        & p {
          margin-bottom: 8px;
        }

        &-title {
          padding-bottom: 44px;
          font-weight: var(--title-font-weight);
          font-size: 32px;
          line-height: 1.25;
          text-transform: uppercase;

          @media (--viewport-sm-max) {
            padding-bottom: 21px;
          }
        }

        &-input {
          margin-bottom: 24px;

          & .input {
            border: 2px solid var(--color-cf-landing-page-input);

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: var(--color-cf-landing-page-input-placeholder);
            }
          }

          & .input {
            border: 2px solid var(--color-cf-landing-page-input);

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: var(--color-cf-landing-page-input-placeholder);
            }
          }
        }

        &-accept-terms {
          padding-bottom: 16px;

          & .checkbox {
            margin-bottom: 0;

            &__tick:before {
              border-color: var(--color-cf-landing-page-checkbox);
            }

            & [type='checkbox']:checked + .checkbox__tick:before {
              border-color: var(--color-cf-landing-page-checkbox);
            }
          }

          @media (--viewport-sm-max) {
            padding-bottom: 31px;
          }
        }

        &-sent-confirmation-title {
          padding-bottom: 24px;
          font-weight: var(--title-font-weight);
          font-size: 32px;
          line-height: 1.25;
          text-transform: uppercase;
        }
      }

      /** campaign banner in overlay */
      & .clinic-finder-landing-page__campaign-banner {
        display: flex;
        background-color: var(--color-white);
        width: 440px;
        height: 650px;
        margin-bottom: 40px;
        flex-flow: column;
        box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.16);

        @media (--viewport-sm-max) {
          display: none;
        }

        &-image {
          display: flex;
          width: 60%;
          order: 2;
          width: 100%;
          height: 421px;
          border-radius: 0 0 24px 24px;

          & figure {
            width: 100%;
            background-size: cover;
          }
        }

        &-content {
          width: 40%;
          width: 100%;
          margin: 0;
          padding: 40px;
          text-align: left;

          &-title {
            font-size: 40px;
            line-height: 1.1;
            font-weight: 900;
            font-family: var(--heading-font-family);
            padding-bottom: 24px;
            font-size: 24px;
            line-height: 1.167;
            font-weight: var(--title-font-weight);
            text-transform: uppercase;
          }

          &-description {
            font-size: 18px;
            line-height: 1.5;
            padding-bottom: 40px;
            font-size: 16px;
            padding-bottom: 24px;
          }

          &-connect {
            display: flex;
            flex-flow: column;

            &-fb {
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  /**
    * image gallery overlay
  */
  &__image-gallery-overlay {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    overflow-x: hidden;
    background-color: var(--color-cf-landing-page-overlay-bg);
    overflow-y: hidden;
    transform: translateX(-100%);
    transition: transform 0.3s;

    &--expanded {
      transform: translateX(0);
    }

    &-close {
      width: 100%;

      & .icon {
        position: absolute;
        top: 40px;
        right: 38px;
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        cursor: pointer;

        @media (--viewport-sm-max) {
          top: 30px;
          right: 30px;
        }
      }
    }

    &-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 116px;
      font-size: 30px;

      @media (--viewport-sm-max) {
        height: 80px;
      }

      & .pagination-control-current {
        margin: 23.5px 0 23.5px 0;

        @media (--viewport-sm-max) {
          width: 41px;
          height: 37px;
          font-size: 16px;
          font-weight: 900;

          &:before {
            height: 39px;
            width: 2px;
          }
        }
      }
    }

    &-media {
      height: 100%;
      width: 100%;
      margin-bottom: 115px;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewport-sm-max) {
        align-items: none;
      }

      &-content {
        flex-basis: 100%;
        max-width: 100%;

        @media (--viewport-md-min) {
          flex-basis: 58.33333333%;
          max-width: 58.33333333%;
        }

        & .image-slideshow-wrapper {
          position: relative;
          height: 0;
          padding-bottom: 56%;

          & .flickity-viewport {
            overflow: visible;
          }

          & .image-slideshow {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            & .image-slideshow-slide {
              height: 100%;
              pointer-events: none;

              &.is-selected {
                pointer-events: auto;
              }

              & .video {
                height: 100%;
                padding-top: 56.25%;
                margin: 0;

                & .play-icon {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 2;
                  cursor: pointer;
                  transition: opacity 0.3s;

                  & .icon {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                  }
                }

                & iframe {
                  z-index: 3;
                }
              }
            }
          }
        }
      }
    }

    &-back {
      align-items: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      width: 21%;

      @media (--viewport-sm-max) {
        display: none;
      }
    }

    &-forward {
      align-items: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      width: 21%;
      z-index: 3;

      @media (--viewport-sm-max) {
        display: none;
      }
    }
  }

  /**
    * contact form overlay
    */
  &__contact-form-overlay {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background-color: var(--color-cf-landing-page-overlay-bg);
    transform: translateX(-100%);
    transition: transform 0.3s;
    overflow: hidden;

    &--expanded {
      transform: translateX(0);
    }

    &-close {
      width: 100%;

      @media (--viewport-sm-max) {
        top: 30px;
        right: 30px;
      }

      & .icon {
        position: absolute;
        top: 40px;
        right: 38px;
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        cursor: pointer;

        @media (--viewport-sm-max) {
          top: 30px;
          right: 30px;
        }
      }
    }

    &-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 80px;

      /** contact form in overlay */
      & .clinic-finder-landing-page__contact-form {
        padding-top: 0;
        background-color: var(--color-cf-landing-page-overlay-bg);
        margin-right: 0;
        padding-right: 30px;
        padding-left: 30px;
        width: 560px;
        border-radius: var(--border-radius);
        box-shadow: none;

        & p {
          margin-bottom: 8px;
        }

        &-title {
          padding-bottom: 44px;
          font-weight: var(--title-font-weight);
          text-transform: uppercase;

          @media (--viewport-sm-max) {
            padding-bottom: 21px;
          }
        }

        &-input {
          margin-bottom: 24px;

          & .input {
            border: 2px solid var(--color-cf-landing-page-input);

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: var(--color-cf-landing-page-input-placeholder);
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: var(--color-cf-landing-page-input-placeholder);
            }
          }
        }

        &-accept-terms {
          padding-bottom: 16px;

          & .checkbox {
            margin-bottom: 0;

            &__tick:before {
              border-color: var(--color-cf-landing-page-checkbox);
            }

            & [type='checkbox']:checked + .checkbox__tick:before {
              border-color: var(--color-cf-landing-page-checkbox);
            }
          }

          @media (--viewport-sm-max) {
            padding-bottom: 31px;
          }
        }

        &-sent-confirmation-title {
          padding-bottom: 24px;
          font-weight: var(--title-font-weight);
          text-transform: uppercase;
        }
      }
    }
  }
}
