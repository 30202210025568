.hero-clinic-welcome {
  width: 100vw;
  height: 100vh;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}

.hero-clinic-welcome-title {
  font-size: 3rem;
  font-weight: 700;
  max-width: 500px;
  text-align: center;
  margin-bottom: 2rem;

  @media (--viewport-xs-max) {
    max-width: 80%;
    font-size: 2rem;
  }
}

.hero-clinic-learn-more {
  position: absolute;
  bottom: 2rem;
  text-decoration: none;
  color: #fff;

  @media (--viewport-xs-max) {
    bottom: 6rem;
  }
}

.hero-clinic-learn-more:hover {
  text-decoration: underline;
  color: #fff;
}
.clinic-welcome-search {
  width: 90%;
  display: flex;
  flex-direction: column;

  @media (--viewport-sm-min) {
    width: 600px;
  }
}
.clinic-welcome-search__wrapper {
  width: 100%;
  position: relative;
  border-radius: 0 10px 10px 10px;
}

.clinic-welcome-search__autocomplete-items {
  border: 1px solid var(--color-grey-lighter);
  border-radius: 0 0 10px 10px;
  z-index: 10999;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0 8px 16px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
}

.clinic-welcome-search__autocomplete-items a {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #582e91;
  font-weight: 700;
}

.clinic-welcome-search__autocomplete-items a:hover {
  background-color: #e9e9e9;
}

.clinic-welcome-search__wrapper.not-specified {
  border-radius: 10px;
}

.clinic-welcome-search__wrapper.not-specified .clinic-welcome-search__input {
  border-radius: 10px;
}

.clinic-welcome-search__input {
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1rem;
  letter-spacing: 0.7px;
  border-radius: 0 10px 10px 10px;
  border: none;
}

.clinic-welcome-search__action {
  border: none;
  color: #fff;
  position: absolute;
  top: 0.2rem;
  display: flex;
  right: 0.3rem;
  padding: 0.6rem;
  background: var(--color-primary);
  border-radius: 10px;
  cursor: pointer;
}

.clinic-welcome-search__toggler {
  display: flex;
  align-self: flex-start;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.clinic-welcome-search__toggler-option {
  padding: 0.4rem 0;
  font-size: 1rem;
  font-weight: 400;
  min-width: 140px;
  text-align: center;
  background-color: var(--color-primary);
  color: #fff;
  cursor: pointer;
}

.clinic-welcome-search__toggler-option.active {
  background-color: white;
  color: black;
}

.clinic-welcome-search__cta {
  margin: 0.5rem 0;
  border-radius: 26px;
  border: 2px solid transparent;
  padding: 1rem;
  background: var(--color-primary);
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.clinic-welcome-search__cta:hover {
  border: 2px solid var(--color-primary);
  background: var(--color-secondary);
  color: #fff;
}

.clinic-welcome-search__cta:active {
  transform: scale(0.99);
}
