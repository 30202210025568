/* Overwrites of Instalift for EnerJet theme */

:root {
  --header-height: 106px;
  --header-height-desktop: 128px;
}

/* interactive patient preview overlay */
body.interactive-patient-preview--open {
  .header {
    @media (--viewport-sm-max) {
      pointer-events: none;
    }
  }
}

/* Logo overwrite */
.logo {
  &__image {
    height: 30px;

    @media (--viewport-md-min) {
      height: 37px;
    }

    &--sinclair {
      height: 80px;

      @media (--viewport-md-min) {
        height: 110px;
      }
    }
  }
}

.footer-other-brands .logo__image {
  height: 30px;
  max-width: 120px;
}

/* Header overwrite */
.header {
  &:not(.header--landing-page, .header--shadow) {
    background: none;
  }

  &__inner {
    padding-top: 28px;
  }

  &--shadow {
    background: color(var(--color-black) a(50%));
  }

  &:not(.header--landing-page) + .header-spacer {
    display: none !important;
  }
}

.site-selector-list-item--active,
.site-selector-list-item:focus,
.site-selector-list-item:hover {
  color: var(--color-primary);
}

.site-selector-toggle:hover .site-selector-toggle__label,
.site-selector-toggle:hover .site-selector-toggle__expand-arrow,
.site-selector--active .site-selector-toggle__expand-arrow,
.site-selector--active .site-selector-toggle__label {
  color: var(--color-white);
}

.site-selector-list-item__link {
  border-bottom-color: var(--color-blue-light);
}

/* Service navigation overwrite */
.navigation {
  &--service {
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
      overflow: hidden;
      background: color(var(--color-black) a(80%));
    }
  }
}

/* Global navigation overwrite */
.main-navigation {
  @media (--viewport-sm-max) {
    background: var(--color-black);

    &-list {
      &-item {
        &--active {
          color: var(--color-pink);
        }
      }
    }
  }

  @media (--viewport-md-min) {
    &-list {
      &-item {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          background: var(--color-secondary);
          width: 100%;
          height: 3px;
          opacity: 0;
          transition: opacity 0.2s var(--transition);
        }

        &:hover {
          color: var(--color-white);
          &::after {
            opacity: 0.5;
          }
        }

        &--active {
          &::after {
            opacity: 1;
          }
        }

        &__link {
          font-weight: 700;
          color: var(--color-white);
          text-shadow: 0 2px 10px color(var(--color-black) a(50%));
        }

        &-sublist {
          background: color(var(--color-white) a(90%));
          box-shadow: 0 0 10px 10px color(var(--color-white) a(15%));
          z-index: 2;

          &-item {
            color: var(--color-text);

            &:hover {
              color: var(--color-primary);
            }

            &__link {
              font-weight: 500;
              border-bottom: 2px solid color(var(--color-primary) a(25%));
            }
          }
        }
      }
    }
  }
}

/* Mobile menu overwrite */
.mobile-menu-toggle {
  bottom: 0;

  &:before,
  &:after {
    background-color: var(--color-white);
  }

  &__label {
    color: var(--color-white);
    font-size: 0.6875rem; /* 11px */
  }
}

/* Hero overwrite */
.hero {
  color: var(--color-white);

  &-head {
    &-title {
      color: currentColor;
      font-size: 2.75rem; /* 44px */
      line-height: 2.75rem; /* 44px */

      @media (--viewport-sm-min) {
        font-size: 4rem; /* 64px */
        line-height: 4rem; /* 64px */
      }

      .quirk {
        display: table;
        font-family: var(--base-font-family);
        font-style: italic;
        font-weight: 300;
        border: 8px solid var(--color-pink);
        margin-top: 8px;
        padding: 0px 16px 8px 10px;
        text-transform: lowercase;
      }
    }
  }

  &-content {
    &__line {
      background: var(--color-white);
    }

    &__text {
      font-size: 1.5rem; /* 24px */
      line-height: 2.25rem; /* 36px */
      font-weight: 500;

      p {
        font-size: 1.5rem; /* 24px */
        line-height: 2.25rem; /* 36px */
        font-weight: 500;
      }

      ~ .hero-content__buttons {
        margin-top: 40px;
      }
    }
  }
}

/* Button overwrite */
a.button,
.button {
  background: var(--color-primary);
  border-radius: 0;
  font-size: 0.875rem; /* 14px */
  min-width: 240px;
  transition: all 0.2s var(--transition);
  text-transform: uppercase;
  letter-spacing: 0.1666666667rem; /* 1 */

  &:hover {
    background: color(var(--color-primary) shade(5%));
  }

  &:before {
    background: none;
  }

  &--secondary {
    background: var(--color-primary);

    &:hover {
      background: color(var(--color-primary) shade(5%));
    }
  }

  &--ghost {
    background: transparent;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);

    &:hover {
      background: var(--color-primary);
      color: var(--color-white);
      border-color: var(--color-white);
    }
  }

  &--chip {
    font-size: 0.75rem; /* 12px */
    text-transform: capitalize;
    min-width: auto;
  }
}

/* Content slider */
.content-slider__btn {
  background-color: var(--color-primary);
}

.content-slider__btn-group {
  background-image: linear-gradient(transparent 44%, #dff1f4 50%, transparent 56%);
}

/* Footer toggle */
.isi-toggle__trigger {
  color: var(--color-primary);
}

.isi-toggle__trigger svg {
  stroke: var(--color-primary);
}

.isi a,
.isi a:hover {
  color: var(--color-primary);
}

/* Face dots */
.interactive-patient-preview-spots__item:before {
  border: 2px solid var(--color-pink);
}

.interactive-patient-preview-spots__item::after {
  background: var(--color-pink);
}

.interactive-patient-preview-overlay {
  background: transparent;
}

/* Video slider */
.video-slider-selector-item {
  background-color: var(--color-primary);
}

.video-slider-selector-progress {
  background: #dff1f4;
}

.video-slider-selector-progress {
  height: 3px;
}

/* h2 */
.appetiser-content h2,
.profile-cards__header h2,
.content-cards__title,
.clinic-finder-banner-content__title,
.social-media-feed-content__title,
.video-slider__title,
.ask-a-question__title,
.how-it-works-content__title,
.interactive-patient-preview-content__title,
h2.interactive-patient-preview-content__title .ask-a-question__title {
  font-style: unset !important;
  font-weight: bold;
  text-transform: uppercase;
}

/* Location finder */
.lf .location-search-tabs__tab::before {
  background-color: var(--color-primary);
}

.lf .location-search-tabs__tab {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

/* Header */
.header {
  top: -1px;
}

.navigation--service::before {
  background: var(--color-primary);
}

.navigation-list-item__link:hover {
  color: var(--color-white);
}

/* how-it-works */
.how-it-works-content-slides-item-inner,
.how-it-works-content__title {
  color: var(--color-white);
}

/* form input */
.input {
  border: var(--input-border-width) solid var(--color-blue-dark);
}

.checkbox__tick:before {
  border-color: inherit;
}

.row.theme-enerjet-accent .checkbox a,
.row.theme-enerjet-blast .checkbox a {
  color: var(--color-white);
}

/* clinic finder input */
.clinic-finder-banner-content-form__input {
  border: none;
}

.clinic-finder-sticky-cta-popout-form__input {
  border-color: var(--color-white);
}

/* progress */
.image-and-text-slider-selector-item {
  background-color: var(--color-primary);
}

.image-and-text-slider-selector-progress {
  background: #dff1f4;
}

.quote-slider-content-slides-controls-buttons {
  background-image: linear-gradient(transparent 44%, #dff1f4 50%, transparent 56%);
}

/* countdown */
.countdown-content-timer {
  color: var(--color-white);
}

/* appetiser */
.appetiser__image img {
  @media (--viewport-sm-min) {
    z-index: 2 !important;
  }
}

/* FAQ */
.theme-enerjet-blast,
.theme-enerjet-hero,
.theme-enerjet-accent,
.theme-enerjet-gradient-regular {
  .faq-list-item-header__label {
    color: var(--color-white);
  }

  .faq-list-item-header:after,
  .faq-list-item-header:before {
    border-color: var(--color-white);
  }
}

/* how-it-works */
.how-it-works .how-it-works-content__cta a {
  background: var(--color-white);
  color: var(--color-primary);
  border: 4px solid var(--color-white);
}

.how-it-works .how-it-works-content__cta a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/* appetiser */
.appetiser a {
  background: var(--color-white);
  color: var(--color-primary);
  border: 4px solid var(--color-white);
}

.appetiser a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/* explore-our-specialists */
.explore-our-specialists a {
  background: var(--color-white);
  color: var(--color-primary);
  border: 4px solid var(--color-white);
}

.explore-our-specialists a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/* image-and-text-slider__cta */
.image-and-text-slider__cta a {
  background: var(--color-white);
  color: var(--color-primary);
  border: 4px solid var(--color-white);
}

.image-and-text-slider__cta a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/* profile-cards__cta */
.profile-cards__cta a {
  background: var(--color-white);
  color: var(--color-primary);
  border: 4px solid var(--color-white);
}

.profile-cards__cta a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/* video-slider__cta */
.profile-cards__cta a {
  background: var(--color-white);
  color: var(--color-primary);
  border: 4px solid var(--color-white);
}

.profile-cards__cta a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}
