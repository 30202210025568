/**
 * Atom: Forms - Select
 */

:root {
  --select-color: var(--color-white);
  --select-background-color: var(--color-primary);
  --select-focus-border-color: var(--color-primary);
  --select-icon-fill: var(--color-white);
}

.select {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 60px 0 16px;
  font-size: 15px;
  font-family: var(--base-font-family);
  line-height: 1;
  color: var(--select-color);
  background-color: var(--select-background-color);

  /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
  background-image: none;
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  vertical-align: bottom;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: 0;
    border-color: var(--select-focus-border-color);
  }

  &__wrapper {
    position: relative;
    width: 233px;
    align-self: center;
  }
}

.icon.select__icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 9px;
  min-width: 15px;
  min-height: 9px;
  z-index: 1;
  pointer-events: none;
  fill: var(--select-icon-fill);
}
