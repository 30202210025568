.featured-event {
  margin-bottom: 88px;
  width: auto;

  &-inner {
    position: relative;
    text-decoration: none;
    color: var(--color-black);

    @media (--viewport-md-min) {
      display: flex;
      height: 480px;
    }

    &-image {
      height: 216px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 16px;

      @media (--viewport-sm-min) {
        height: 480px;
      }

      @media (--viewport-md-min) {
        position: absolute;
        right: 0;
        width: 60%;
      }
    }

    &-content {
      margin-left: 16px;

      @media (--viewport-md-min) {
        align-self: center;
        background-color: var(--color-pale-blue);
        padding: 40px;
        width: 624px;
        z-index: 1;
      }

      @media (--viewport-lg-min) {
        position: absolute;
        right: 50%;
      }

      &__title {
        font-size: 2rem; /* 32px */
        line-height: 2.5rem; /* 40px */
        font-weight: var(--heading-font-weight);

        @media (--viewport-md-min) {
          font-size: 2.375rem; /* 38px */
          line-height: 4.25rem; /* 68px */
          letter-spacing: -0.5px;
          padding-bottom: 16px;
        }
      }

      &__date {
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        font-weight: 400;

        @media (--viewport-md-min) {
          font-size: 1.5625rem; /* 25px */
          line-height: 1.75rem; /* 28px */
          padding-bottom: 24px;
        }
      }

      &__description {
        display: none;

        @media (--viewport-md-min) {
          display: block;
          line-height: 1.25rem; /* 20px */
          padding-bottom: 32px;
        }
      }

      &__link {
        display: none;

        @media (--viewport-md-min) {
          display: block;
          font-size: 0.875rem; /* 14px */
          line-height: 1.0625rem; /* 17px */
          font-weight: 700;
          color: var(--color-dark-blue);
          text-transform: uppercase;

          .icon {
            min-width: 8px;
            min-height: 12px;
            width: 8px;
            height: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
