.event-detail {
  background-color: var(--color-background-01);

  &-body {
    padding-bottom: 20px;
  }

  &-info {
    margin-top: 50px;
    border-bottom: 1px solid #979797;
    padding-bottom: 80px;
    margin-bottom: 20px;

    @media (--viewport-sm-max) {
      &__cta {
        width: 100%;
        max-width: unset;
        height: 65px;
      }
    }
    &__section {
      display: flex;
      margin-bottom: 50px;

      h3 {
        font-size: 1.25rem; /** 20px **/
        font-weight: 600;
        margin-bottom: 7px;
      }
    }

    section:last-of-type {
      margin-bottom: 20px;
    }

    &__main {
      p {
        font-size: 1rem;
        line-height: 1.5;
      }

      margin-left: 20px;
    }

    &__icon {
      display: block;
      flex-basis: 20px;

      .icon {
        margin-top: -3px;
        width: 38px;
        height: 38px;
      }
    }

    &-datetime {
      &__addcalendar {
        display: flex;
        align-items: center;
        color: var(--color-highlight);
        text-transform: uppercase;
        font-size: 0.875rem; /** 14px **/
        letter-spacing: 2px;
        font-weight: 600;
        padding-bottom: 6px;

        &:hover {
          border-bottom: 1px solid var(--color-highlight);
          color: red;
        }

        .icon {
          height: 0.875rem;
          width: 0.875rem;
          min-width: unset;
          min-height: unset;
          margin-right: 12px;
        }
      }
    }
  }

  @media (--viewport-md-min) {
    &-body {
      padding-right: 80px;
      padding-bottom: 100px;
    }

    &-info {
      padding-bottom: 5px;
      margin-bottom: 20px;
      border-bottom: none;
      border-left: 1px solid #979797;
      padding-left: 40px;
    }
  }
}
