/*
 * Molecule: Main navigation
 */

body.mobile-menu-open {
  .header {
    @media (--navigation-breakpoint-mobile) {
      z-index: 1000;
    }
  }
}

.main-navigation {
  @media (--navigation-breakpoint-mobile) {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 40px var(--container-spacing--mobile);
    background: var(--color-deepblue);
    color: var(--color-white);
    transition: transform 0.2s var(--transition);
    transform: translate3d(100%, 0, 0);
    overflow-y: auto;

    &--active {
      transform: translate3d(0, 0, 0);
    }

    &__close {
      position: relative;
      background: none;
      border: 0;
      color: var(--color-scooter);
      padding-left: 32px;
      margin-bottom: 56px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:focus,
      &:active,
      &:hover {
        color: var(--color-froly);
        outline: none;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 4px;
        background: currentColor;
        border-radius: 25%;
        position: absolute;
        top: 49%;
        left: 0;
        transform-origin: top;
      }

      &:before {
        transform: rotate(135deg) translate3d(0, -50%, 0);
      }

      &:after {
        transform: rotate(225deg) translate3d(0, -50%, 0);
      }
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;

      &-item {
        color: var(--color-white);
        border-bottom: 2px solid color(var(--color-eminence) a(50%));

        &__link {
          display: block;
          text-decoration: none;
          color: currentColor;
          font-weight: 600;
          font-size: 1.125rem; /* 18px */
          line-height: 1;
          padding: 20px 0;

          &:focus,
          &:active,
          &:hover {
            color: var(--color-froly);
          }
        }

        &-sublist {
          list-style: none;
          margin: 0;
          padding: 0;

          &-item {
            color: var(--color-white);

            &__link {
              display: block;
              text-decoration: none;
              color: currentColor;
              font-size: 1.125rem; /* 18px */
              line-height: 1;
              margin-bottom: 20px;

              &:focus,
              &:active,
              &:hover {
                color: var(--color-froly);
              }
            }
          }
        }
      }
    }

    &-find-physician {
      margin-top: 16px;
      margin-bottom: 56px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: currentColor;
      font-weight: 600;
      font-size: 1.125rem; /* 18px */
      line-height: 1;

      &__icon {
        margin-right: 16px;
      }

      &:focus,
      &:active,
      &:hover {
        color: var(--color-froly);
      }
    }

    &-service-list {
      list-style: none;
      margin: 0;
      padding: 0;

      &-item {
        opacity: 0.5;

        &--active {
          opacity: 1;
        }

        &__link {
          display: block;
          font-size: 0.875rem; /* 14px */
          text-decoration: none;
          position: relative;
          color: currentColor;
          transition: color 0.2s var(--transition);

          &:focus,
          &:active,
          &:hover {
            color: var(--color-froly);
          }
        }
      }
    }
  }

  @media (--navigation-breakpoint-desktop) {
    flex-grow: 1;
    transform: translate3d(0, 12px, 0);
    transition: none;

    &__close {
      display: none;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: right;
      height: 100%;

      &-item {
        display: inline-flex;
        align-items: flex-end;
        line-height: 1;
        cursor: pointer;
        position: relative;
        height: 100%;
        margin: 0 16px;
        padding: 8px 0 12px;
        color: var(--color-text);

        &:last-child {
          margin-right: 0;
        }

        &__link {
          display: block;
          text-decoration: none;
          position: relative;
          color: currentColor;
          transition: color 0.2s var(--transition);

          &:hover {
            color: currentColor;
          }
        }

        &:hover {
          color: var(--color-froly);

          .main-navigation-list-item-sublist {
            opacity: 1;
            pointer-events: auto;
          }
        }

        &-sublist {
          margin: 0;
          padding: 0;
          list-style: none;
          background: color(var(--color-deepblue) a(90%));
          border-radius: 8px;
          box-shadow: 0px 8px 0px color(var(--color-deepblue) a(15%));
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          transition: opacity 0.2s var(--transition);
          opacity: 0;
          pointer-events: none;
          text-align: center;

          &-item {
            cursor: pointer;
            position: relative;
            color: var(--color-white);

            &:last-child {
              .main-navigation-list-item-sublist-item__link {
                border-bottom: 0;
              }
            }

            &:before {
              display: none;
            }

            &--active,
            &:hover,
            &:focus {
              color: var(--color-froly);
            }

            &__link {
              display: block;
              white-space: nowrap;
              text-decoration: none;
              position: relative;
              color: currentcolor;
              transition: color 0.2s var(--transition);
              padding: 16px 0;
              margin: 0 20px;
              border-bottom: 2px solid color(var(--color-eminence) a(50%));

              &:hover {
                color: currentColor;
              }
            }
          }
        }
      }
    }

    &-find-physician {
      display: none;
    }

    &-service-list {
      display: none;
    }
  }
}
