/*
 * Organism: Geo popup
 */
.geo-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  width: auto;
  background-color: #fff;
  padding: 2rem;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 0 20px -2px rgba(66, 68, 90, 1);
  transition: transform 0.3s;

  @media (--viewport-md-min) {
    width: 700px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }

  &-content-cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem 0 1rem;

    @media (--viewport-md-min) {
      flex-direction: row;
    }
    .button {
      border-radius: 4px;
    }
    .cta {
      margin: 0.5rem 1rem;
      text-transform: uppercase;
      letter-spacing: 0.1666666667rem;
      color: black;

      @media (--viewport-md-min) {
        margin: 0 1rem;
      }

      .button--ghost {
        color: black;

        &:hover {
          color: white;
        }
      }
    }
  }

  &-content-icon .icon {
    height: 44px;
    width: 44px;
    fill: var(--color-primary);
  }

  &-content-title {
    margin: 2rem 0 1rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.1666666667rem;
    text-align: center;
  }
}
