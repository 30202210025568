.load-more {
    display: flex;
    justify-content: center;
    margin-bottom: 120px;

    @media (--viewport-md-min) {
        margin-bottom: 80px;
    }

    .button {
        width: 100%;

        @media (--viewport-md-min) {
            width: auto;
            padding-left: 150px;
            padding-right: 150px;
        }
    }
}