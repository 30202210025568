.blog-post-listing {
    margin-bottom: 32px;

    @media (--viewport-md-min) {
        margin-bottom: 32px;
        position: relative;
    }

    &--grid {
        margin-top: 28px;

        @media (--viewport-md-min) {
            margin-top: 0;
            margin-bottom: 0;
        }


        & .blog-post-listing-content {
            margin-top: 0;
            margin-bottom: 64px;
            display: flex;
            flex-wrap: wrap;
            margin: -20px;

            @media (--viewport-md-min) {
                margin-top: 0;
                margin-bottom: 72px;
            }

            &-item {
                padding: 20px;
                width: 100%;
                height: auto;

                @media (--viewport-ms-min) {
                    width: 50%;
                }

                @media (--viewport-lg-min) {
                    width: 33.33333333%;
                }

                & .blog-post-card {
                    width: 100%;
                }
            }
        }

        & .cta {
            margin-top: 48px;
            
            & .button {
                width: 100%;

                @media (--viewport-ms-min) {
                    width: 50%;
                }

                @media (--viewport-lg-min) {
                    width: calc(33.33333333% - 40px);
                }
            }
        }
    }

    &-content {
        margin-top: 48px;
        margin-bottom: 48px;

        @media (--viewport-md-min) {
            margin-top: 80px;
            margin-bottom: 56px;
            margin-right: -12px;
            margin-left: -12px;
            display: flex;
            flex-wrap: wrap;
        }

        & .flickity-viewport {
            overflow: visible;
        }

        &-item {
            min-height: 312px;
            height: 312px;
            width: calc(267px + 24px);

            @media (--viewport-md-min) {
                min-height: 432px;
                height: auto;
                width: 33.33333333%;
                padding: 12px;
            }
        }
    }
}


.blog-post-listing--grid .blog-post-listing {
    @media (--viewport-md-min) {
      &__cta {
        text-align: center;
      }
    }
    &-content {
      padding-top: 100px;
      flex-direction: column;
      justify-content: space-between;
  
      @media (--viewport-md-min) {
        flex-direction: row;
      }
  
      &-item {
        height: auto;
        width: auto;
  
        @media (--viewport-md-min) {
          width: 50%;
        }
      }
    }
  }