.statement {
    position: relative;
  
    &-content {
      padding-top: 100px;
      padding-bottom: 60px;
  
      &__label {
        display: block;
        margin-bottom: 35px;
      }
  
      &__body {
        margin-bottom: 30px;
      }
    }
  
    @media (--viewport-md-min) {
      &-content {
        padding-top: 250px;
        padding-bottom: 180px;
  
        &__body {
          margin-bottom: 40px;
          padding-right: 20%;
        }
      }
    }
  }