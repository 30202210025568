.result-screen {
  &-treatment-plan {
    padding-top: var(--header-height);
    background: linear-gradient(216.75deg, rgba(255, 239, 228, 0.98) 0%, #b0afc4 100%);

    &-container {
      @extend .container;

      @media (--viewport-md-min) {
        display: flex;
      }
    }

    &-content {
      display: flex;
      flex-flow: column;
      padding-top: 24px;

      @media (--viewport-md-min) {
        padding-top: 104px;
        width: 50%;
      }

      &__title {
        color: var(--color-visualiser-result-screen-title);
        font-size: 1.5rem; /* 24px */
        font-weight: 500;
        letter-spacing: 0.53px;
        line-height: 2rem; /* 32px */
        margin-bottom: 24px;
        text-align: center;

        @media (--viewport-md-min) {
          text-align: left;
          font-size: 2.5rem; /* 40px */
          letter-spacing: 1px;
          line-height: 3rem; /* 48px */
        }
      }

      &-selection-summary {
        font-weight: 600;
        letter-spacing: 0.19px;
        line-height: 2rem; /* 32px */
        margin-bottom: 48px;
        text-align: center;

        @media (--viewport-md-min) {
          text-align: left;
          font-size: 1.5rem; /* 24px */
          letter-spacing: 0.34px;
          line-height: 2.25rem; /* 36px */
        }
      }

      &-selected-areas-of-concern {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (--viewport-md-min) {
          justify-content: flex-start;
          margin-bottom: 40px;
        }
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      padding: 48px;
      display: flex;
      justify-content: center;
      align-self: center;

      @media (--viewport-md-min) {
        width: 50%;
        height: 50%;
      }

      & img {
        background-color: rgba(176, 175, 196, 0.27);
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-top-clinics {
    background-color: rgba(139, 136, 164, 0.13);

    &-content {
      @extend .container;
      padding-top: 48px;
      padding-bottom: 48px;

      &__title {
        color: var(--color-visualiser-result-screen-title);
        font-size: 1.5rem; /* 24px */
        font-weight: 500;
        letter-spacing: 0.53px;
        line-height: 2rem; /* 32px */
        margin-bottom: 24px;

        @media (--viewport-md-min) {
          font-size: 2.5rem; /* 40px */
          letter-spacing: 1px;
          line-height: 3rem; /* 48px */
        }
      }

      &-listing {
        padding-bottom: 40px;

        & .flickity-viewport {
          overflow: visible;
          height: 315px;
        }

        /* remove black line around scroll items */
        &.flickity-enabled:focus .flickity-viewport {
          outline: none;
        }

        @media (--viewport-md-min) {
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
        }
      }
    }
  }
}
