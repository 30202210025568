.location-map {
    width: 100%;
    margin-bottom: 72px;
    margin-top: 48px;

    &-content {
        &-header {
            display: flex;
            flex-flow: column;
            margin-bottom: 32px;
            font-weight: var(--heading-font-weight);

            @media (--viewport-md-min) {
                flex-flow: row;
                justify-content: space-between;
            }

            &-title {
                font-size: 1.5rem; /* 24px */
                line-height: 1.875rem; /* 30px */
            }

            &-address {
                line-height: 1.25rem; /* 20px */
                letter-spacing: 1.4px;
                color: var(--color-location-map-address);
            }
        }

        &-map {
            width: 100%;
            height: 312px;
        }

        .cta {
            margin-top: 56px;
        }
    }
}

