/*
 * Molecule: Grid element - Clinic finder banner for all branded sites and new CF
 */

.animations--enabled {
  .clinic-finder-banner {
    &-content {
      &__title {
        opacity: 0;
      }

      &-form {
        opacity: 0;
      }
    }
  }
}

.clinic-finder-banner {
  position: relative;

  &-media {
    position: relative;
    height: 450px;

    width: calc(100% + (var(--container-spacing--mobile) * 2));
    margin-left: calc(0 - var(--container-spacing--mobile));
    margin-right: calc(0 - var(--container-spacing--mobile));

    @media (--viewport-md-min) {
      width: calc(100% + (var(--container-spacing) * 2));
      margin-left: calc(0 - var(--container-spacing));
      margin-right: calc(0 - var(--container-spacing));
    }

    &__image {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      margin-top: 0;
      margin-bottom: 64px;
    }

    &-form {
      display: flex;
      align-items: center;
      position: relative;
      width: calc(100% - var(--container-spacing--mobile));
      margin-bottom: 56px;

      @media (--viewport-sm-min) {
        width: 400px;
      }

      &__input {
        margin: 0;
        padding: 16px 0;
        background: none;
        border-radius: 0;
        color: currentColor;
        min-height: 40px;
        border: 0;
        border-bottom: 1px solid currentColor;

        &::placeholder {
          color: color(var(--color-text) a(60%));
        }
      }

      &__submit {
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 4px;
        right: 0;
        color: var(--color-secondary);

        .icon {
          filter: brightness(0) invert(1);
          transform: rotate(0deg);
          width: 40px;
          height: 40px;
          fill: currentColor;
        }
      }
    }

    &__cta {
      @media (--viewport-sm-min) {
        width: 400px;

        .button {
          width: 100%;
        }
      }
    }
  }
}
