.brand-banner {
  --footer-height: 80px;

  @media (--viewport-md-min) {
    --footer-height: 147px;
  }

  width: 100%;
  margin: 0 auto;
  min-height: 600px; /** minimum height before content gets cut off **/
  height: 80vh;
  overflow: hidden;
  position: relative;
  color: var(--color-white);
  background: black;

  &-media {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #00000061;
    }

    &__video {
      position: absolute;
      top: 0;
      left: 50%;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
    }

    &__thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      object-fit: cover;
      pointer-events: none;
      opacity: 1;
      transition: opacity 0.6s;

      &--hidden {
        opacity: 0;
      }
    }

    &__image {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &--desktop {
        display: none;
      }

      @media (--viewport-md-min) {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: block;
        }
      }
    }
  }

  &-content {
    height: calc(100% - var(--footer-height));
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    will-change: transform, opacity;
    transform: translate3d(0, 0, 0);
    animation: enter 0.4s cubic-bezier(0.45, 0.02, 0.18, 1) 1 forwards;

    &--switching {
      animation: exit 0.4s cubic-bezier(0.45, 0.02, 0.18, 1) 1 forwards;
    }

    &__logo {
      max-width: 215px;
      width: 100%;
      display: block;
      margin-top: 22px;
      margin-bottom: 35px;
    }

    &__title {
      line-height: 2.5rem;
      font-size: 2rem;
      letter-spacing: 0.8px;
      display: block;
      width: 100%;
      font-weight: 400;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 80px;
      }

      &--simple {
        color: var(--color-midnight-blue);
      }
    }

    &__label {
      color: var(--color-white);

      &:before {
        margin-right: 20px;
        content: '';
        width: 71px;
        height: 1px;
        display: inline-block;
        border-bottom: solid 1px var(--color-white);
        position: relative;
        top: -5px;
      }
    }

    &__subtitle {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 34px;
      max-width: 341px;

      &--simple {
        color: var(--color-midnight-blue);
        font-size: 0.875rem; /** 14px **/
      }
    }

    &__cta {
      margin-top: 25px;
      margin-bottom: 50px;

      &--center-on-mobile {
        margin-bottom: 0;
        margin-top: 50px;
      }
    }

    &__date {
      font-size: 0.875rem; /** 14px **/
      text-transform: uppercase;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-bottom: 5px;
    }
  }

  &-footer {
    height: var(--footer-height);
    background: hsla(0, 0%, 100%, 0.1);
    backdrop-filter: blur(12px) saturate(150%);
    border-top: 0.07rem solid hsla(0, 0%, 100%, 0.3);

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    &-controls {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &__arrow {
        z-index: 2;
        appearance: none;
        margin: 12px;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        border: 2px solid var(--color-white);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        cursor: pointer;
        transition: 0.1s;

        &:hover:not(:disabled) {
          transform: scale(1.1);
        }

        &:active:not(:disabled) {
          transform: scale(0.95);
        }

        &:disabled {
          opacity: 0.52;
          cursor: unset;
        }

        .icon {
          position: relative;
          fill: currentColor;
          width: 12px;
          min-width: 12px;
          min-height: 12px;

          &:disabled {
            background-color: none;
          }
        }
      }

      &__arrow--left {
        .icon {
          transform: rotate(90deg);
          left: 0px;
          margin-right: 2px;
        }
      }

      &__arrow--right {
        .icon {
          transform: rotate(-90deg);
          right: 0px;
          margin-left: 2px;
        }
      }

      &-items {
        flex-grow: 1;
        opacity: 0;
        transition: 0.3s opacity 1s;
        height: var(--footer-height);
        margin-top: 17px;

        &-item {
          padding: 0;
          appearance: none;
          border: none;
          cursor: pointer;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.68);

          transition: 0.1s;
          transition: opacity 0.2s;

          &-wrapper {
            width: 33%;
            text-align: center;

            &:hover .brand-banner-footer-controls-items-item {
              background-color: rgba(255, 255, 255, 0.9);
            }

            &:active .brand-banner-footer-controls-items-item {
              transform: scale(0.95);
            }

            &.is-selected .brand-banner-footer-controls-items-item {
              background-color: white;
            }
          }

          &__logo {
            width: 80%;
            pointer-events: none;
          }
        }
      }
    }
  }

  @media (--viewport-sm-min) {
    &-footer-controls-items-item-wrapper {
      width: 20%;
    }
  }

  @media (--viewport-md-min) {
    min-height: 636px;

    &-media {
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5),
          hsla(0, 0%, 100%, 0) 25%,
          hsla(0, 0%, 100%, 0) 30%,
          rgba(0, 0, 0, 0.6)
        );
      }
    }

    &-content {
      &__title {
        max-width: initial;
        font-size: 3rem; /** 48px **/
        max-width: 737px;
        line-height: 4rem;

        &--center-on-mobile {
          max-width: initial;
          text-align: left;
          display: block;
          font-weight: 400;
        }

        &:last-child {
          margin-bottom: 10px;
        }
      }

      &__subtitle {
        max-width: 500px;
        letter-spacing: 0.67px;

        &--simple {
          font-size: 1rem;
        }
      }

      &__logo {
        margin-bottom: 25px;
      }

      &__cta {
        margin-top: 25px;
        margin-bottom: 0;
      }

      &__date {
        font-size: 1.125rem; /** 18px **/
      }
    }

    &-footer {
      &-controls {
        max-width: 1000px;
        margin: 0 auto;

        &__arrow {
          width: 50px;
          height: 50px;

          .icon {
            width: 20px;
          }
        }

        &-items {
          margin-top: 34px;

          &-item {
            width: 113px;
            height: 113px;

            &-wrapper {
              width: 113px;
              margin-left: 15px;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@keyframes exit {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  99% {
    transform: translate3d(0, -5%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 5%, 0);
    opacity: 0;
  }
}

@keyframes enter {
  0% {
    transform: translate3d(0, 5%, 0);
    opacity: 0;
  }
  1% {
    transform: translate3d(0, 5%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
