/**
 * Fonts
 */

/* kumbh-sans-regular - latin */
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/dist/instalift/fonts/kumbh-sans-v20-latin-regular.woff2') format('woff2'),
    url('/dist/instalift/fonts/kumbh-sans-v20-latin-regular.woff') format('woff');
}

/* kumbh-sans-300 - latin */
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('/dist/instalift/fonts/kumbh-sans-v20-latin-300.woff2') format('woff2'),
    url('/dist/instalift/fonts/kumbh-sans-v20-latin-300.woff') format('woff');
}

/* kumbh-sans-500 - latin */
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('/dist/instalift/fonts/kumbh-sans-v20-latin-500.woff2') format('woff2'),
    url('/dist/instalift/fonts/kumbh-sans-v20-latin-500.woff') format('woff');
}

/* kumbh-sans-700 - latin */
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/dist/instalift/fonts/kumbh-sans-v20-latin-700.woff2') format('woff2'),
    url('/dist/instalift/fonts/kumbh-sans-v20-latin-700.woff') format('woff');
}
