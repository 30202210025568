.faq-card {
  display: flex;
  flex: 1;
  min-height: 100%;
  width: 90%;
  margin-left: 10px;
  margin-right: 10px;
  transform-origin: top;

  @media (--viewport-md-min) {
    flex: 0 0 auto;
    min-height: auto;
    margin-left: 0;
    margin-right: 0;
    padding: var(--half-gutter-width);
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  &-inner {
    background-color: var(--color-white);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 80px 40px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--color-faq-card-text);

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      border: 6px solid var(--color-blue-light);
      z-index: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      background: var(--color-white);
      height: 160px;
      width: 65%;
      z-index: 1;
    }
  }

  &__question {
    font-size: 1.375rem; /* 22px */
    line-height: 1.6875rem; /* 27px */
    position: relative;
    z-index: 2;

    @media (--viewport-md-min) {
      font-size: 1.5625rem; /* 25px */
    }
  }

  &__answer {
    position: relative;
    z-index: 2;
    font-weight: 300;
    flex: 1;
    flex-grow: 1;
  }

  &__button {
    position: relative;
    z-index: 2;
    .icon {
      fill: var(--color-white);
    }
  }

  &--hidden {
    display: none;
  }
}
