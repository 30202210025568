/*
 * Molecule: Mobile menu toggle
 */

.mobile-menu-toggle {
  display: flex;
  cursor: pointer;
  align-items: flex-end;
  position: relative;
  bottom: 5px;
  color: var(--color-eminence);

  &:before,
  &:after {
    content: '';
    display: block;
    background-color: var(--color-eminence);
    border-radius: 3px;
    width: 100%;
    height: 3px;
    position: absolute;
  }

  &:before {
    top: -4px;
  }

  &:after {
    bottom: -3px;
  }

  &__label {
    display: block;
    text-transform: uppercase;
    font-size: 0.5625rem; /* 9px */
    font-weight: 500;

    /* if using icon instead of before/after lines with text */
    & .icon {
      fill: var(--color-mobile-menu-icon-fill);
    }
  }

  @media (--navigation-breakpoint-desktop) {
    display: none;
  }
}
