.screen-sidebar {
  background: linear-gradient(90deg, #cdd1da 0%, rgba(255, 255, 255, 0) 50%);

  &-content {
    display: flex;
    flex-flow: column;
    @extend .container;
    padding-right: 0;
    padding-left: 0;

    @media (--viewport-md-min) {
      flex-flow: row nowrap;
      padding-left: var(--container-spacing);
    }

    &-box {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 32px 24px;

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        display: block;
        width: 100vw;
        height: 100%;
        background: var(--color-visualiser-box-bg);
        border-radius: 0 100px 0 0;
        box-shadow: 0 -13px 14px 9px rgba(0, 8, 0, 0.06);

        @media (--viewport-md-min) {
          width: calc(50vw + 90px);
        }
      }

      @media (--viewport-md-min) {
        padding-left: 0;
        padding-right: 112px;
        min-height: 720px;
        margin-top: 64px;
        width: 50%;
      }

      &__title {
        font-size: 1.5rem; /* 24px */
        line-height: 2rem; /* 32px */
        letter-spacing: 0.7px;
        color: var(--color-visualiser-title);
        font-weight: 500;
        padding-bottom: 24px;

        @media (--viewport-md-min) {
          font-size: 2.5rem; /* 40px */
          line-height: 3rem; /* 48px */
          letter-spacing: 1px;
          padding-bottom: 48px;
        }
      }

      &__subtitle {
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        padding-bottom: 24px;
        font-weight: 300;
        letter-spacing: 0;

        @media (--viewport-md-min) {
          padding-bottom: 48px;
        }
      }

      & .select__wrapper {
        margin-bottom: 40px;
        width: 100%;

        @media (--viewport-md-min) {
          margin-bottom: 64px;
        }
      }

      & .stepper {
        &-content {
          &__label {
            margin-bottom: 32px;
            font-size: 1.125rem; /* 18px */
            line-height: 1.5rem; /* 24px */
            letter-spacing: 0.84px;
            color: var(--color-primary);

            @media (--viewport-md-min) {
              font-size: 1.25rem; /* 20px */
              letter-spacing: 1.2px;
            }
          }

          &-progress {
            height: 2px;
            width: 100%;
            background-color: rgba(0, 19, 54, 0.43);
            margin-bottom: 32px;
            opacity: 0.5;
            display: flex;
            justify-content: space-between;

            &__dot {
              height: 8px;
              width: 8px;
              background-color: rgba(0, 19, 54, 1);
              border-radius: 100%;
              transform: translateY(calc(-50% + 1px));

              @media (--viewport-md-min) {
                height: 12px;
                width: 12px;
                transform: translateY(-50%);
              }

              &--active {
                height: 16px;
                width: 16px;

                @media (--viewport-md-min) {
                  height: 24px;
                  width: 24px;
                }
              }
            }
          }
        }
      }

      & .select__wrapper {
        & .select {
          height: 70px;
          color: var(--color-visualiser-title);
          background-color: var(--color-white);
          font-size: 1.3125rem; /* 21px */
          line-height: 2.25rem; /* 36px */
          letter-spacing: 1px;
          border: 2px solid var(--color-visualiser-select-border);
        }

        & .icon.select__icon {
          fill: var(--color-visualiser-title);
        }
      }
    }

    &-image {
      order: -1;
      height: 572px;
      margin-bottom: -72px;

      @media (--viewport-md-min) {
        order: 0;
        margin-bottom: 0;
        height: auto;
        width: 50%;
      }

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
