/*
 * Molecule: Grid element - CTA Banner
 */

.cta-banner {
  width: 100%;
  position: relative;

  &-content {
    position: relative;
    text-align: left;
    padding-top: 48px;
    padding-bottom: 48px;
    
    @media (--viewport-sm-min) {
      padding-top: 200px;
      padding-bottom: 120px;
    }

    &__body {

      @media (--viewport-sm-min) {
        max-width: 80%;
      }
      @media (--viewport-md-min) {
        max-width: 45%;
      }

      &:after {
        content: "";
        display: block;
        position: relative;
        bottom: 0;
        left: 0;
        background: var(--color-secondary);
        height: 2px;
        width: 120px;
        border-radius: 24px;
        margin-top: 16px;

        @media (--viewport-sm-min) {
          margin-top: 32px;
        }
      }
    }

    .button {
      margin-top: 40px;

      @media (--viewport-sm-min) {
        margin-top: 56px;
      }
    }
  }

  &-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-deepblue);
    z-index: -1;
    display: flex;
    justify-content: center;

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
