/*
 * Molecule: Social profiles
 */

.social-profiles {
  background: var(--color-social-profiles-background);
  margin: 0 0 32px;
  padding: 32px 0 24px;
  text-align: center;

  &__link {
    display: inline-flex;
    margin: 0 12px 8px;
    background: var(--color-social-profiles-icon-background);
    color: var(--color-social-profiles-icon-color);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;

    &:hover {
      color: var(--color-social-profiles-icon-color-hover);
    }

    > .icon {
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &:last-child {
      margin-right: 12px;
    }
  }

  &-hover-wrapper {
    position: relative;
    display: inline-block;

    &:hover > div {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      height: 200px;
      width: 200px;
      z-index: 1;
      background-size: 200px;
      transform: translate(-70px, 55px);
    }
  }
}
