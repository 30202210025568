/*
 * Molecule: Pagination
 */

.pagination {
  margin: 0 auto 24px;

  &-control {
    display: flex;
    justify-content: center;
    align-items: center;

    &__arrow {
      transition: transform 0.3s var(--transition);

      > svg {
        transition: fill 0.3s var(--transition);
        fill: var(--color-text);
        cursor: pointer;
      }

      &--previous {
        &:hover {
          transform: translate3d(-8px, 0, 0);

          > svg {
            fill: var(--color-link);
          }
        }
      }

      &--next {
        &:hover {
          transform: translate3d(8px, 0, 0);

          > svg {
            fill: var(--color-link);
          }
        }
      }
    }

    &-current {
      margin: 0 24px 8px;
      position: relative;
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-style: italic;
      font-size: 1.5rem; /* 24px */
      line-height: 1;
      width: 72px;
      height: 64px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        height: 80px;
        width: 2px;
        background: #000;
      }

      &__page {
        display: block;
        position: absolute;
        top: 0;
        left: 4px;
      }

      &__total {
        display: block;
        position: absolute;
        bottom: 0;
        right: 4px;
        text-align: right;
      }
    }
  }

  &-list {
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      display: inline-flex;
      margin: 0 2px;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-style: italic;
      line-height: 1;
      text-decoration: none;
      color: currentColor;
      transition: color 0.3s var(--transition);

      &:hover {
        color: var(--color-link);
      }
    }
  }
}
