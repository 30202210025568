.toggle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* remove grey shadow when click button on ios devices */
  -webkit-tap-highlight-color: transparent; 

  &-content {
    position: relative;
    width: 80px;
    height: 40px;
    border-radius: 100px;
    background-color: var(--color-disabled);
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0,0,0,.05);

    &-check {
      position: absolute;
      display: block;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 6;

      &:checked ~ .toggle-content-track {
        background-color: var(--color-toggle-track-background);
      }

      &:checked ~ .toggle-content-switch {
        right: 2px;
        left: 42px;
        transition: 0.35s var(--transition);
        transition-property: left, right;
        transition-delay: .05s, 0s;
      }
    }

    &-switch {
      position: absolute;
      width: 45%;
      left: 2px;
      top: 2px;
      bottom: 2px;
      right: 22px;
      background-color: var(--color-white);
      border-radius: 36px;
      z-index: 1;
      transition: 0.35s var(--transition);
      transition-property: left, right;
      transition-delay: 0s, .05s;
      box-shadow: 0 1px 2px rgba(0,0,0,.2);
    }

    &-track {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: 0.35s var(--transition);
      box-shadow: inset 0 0 0 2px rgba(0,0,0,.05);
      border-radius: 40px;
    }
  }

  &-label {
    margin-left: 8px;
    color: var(--color-text);
  }
}
