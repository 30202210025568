.related-news {
  background-color: var(--color-background-01);
  display: block;
  padding-top: 67px;

  &__card {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
  }

  .blog-post-card {
    width: 100%;

    &-content__image {
      max-width: 100%;
      max-height: 335px;
      height: 335px;

      width: 100%;
    }
  }

  &__header {
    padding-bottom: 28px;
  }

  .related-news__cta {
    display: none;
  }

  @media (--viewport-md-min) {
    padding-bottom: 60px;

    .related-news__cta {
      display: inline-flex;
      align-items: center;
      margin: 64px auto 32px;
    }

    &__column {
      display: flex;
    }

    &__column:first-child {
      justify-content: flex-start;
      padding-left: 0;
    }

    &__column:last-child {
      justify-content: flex-end;
      padding-right: 0;
    }

    &__card {
      max-width: 502px;
    }

    .blog-post-card {
      max-width: 100%;

      &-content__image {
        width: 100%;
        max-height: 331px;
        height: 331px;
      }
    }
  }

  @media (--viewport-xl-min) {
    &__card {
      max-width: 803px;
    }

    .blog-post-card-content__image {
      max-height: 445px;
      height: 445px;
    }
  }
}
